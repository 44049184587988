import axios from 'axios'
import {
    DELETE_BANK_AGENCY_URL,
    EDIT_BANK_AGENCY_URL,
    GET_BANK_AGENCY_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgencyBankServices = {
    getBank: async (agencyId: string | number, token: string | null) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_BANK_AGENCY_URL}?agencyId=${agencyId}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Could not get bank details for ID ' + agencyId,
                    data: undefined,
                }
                if (debug) console.error(err)
                return err
            }

            return response.data
        } catch (error) {
            const err = {
                status: false,
                data: undefined,
                statusMsg: 'Could not get bank details. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    editBank: async (
        agencyId: string | number,
        swiftCode: string,
        accountNumber: string,
        accountTitle: string,
        iban: string,
        bankAddress: string,
        bankName: string,
        token: string | null
    ) => {
        try {
            const editObj = {
                swiftCode: swiftCode.length ? swiftCode : undefined,
                accountNumber: accountNumber.length ? accountNumber : undefined,
                accountTitle: accountTitle.length ? accountTitle : undefined,
                iban: iban.length ? iban : undefined,
                bankAddress: bankAddress.length ? bankAddress : undefined,
                bankName: bankName.length ? bankName : undefined,
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.put(
                `${EDIT_BANK_AGENCY_URL}?agencyId=${agencyId}`,
                editObj,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Could not edit bank details for ID ' + agencyId,
                    data: undefined,
                }
                if (debug) console.error(err)
                return err
            }

            return response.data
        } catch (error) {
            const err = {
                status: false,
                data: undefined,
                statusMsg: 'Could not edit bank details. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    deleteBank: async (bankAccountId: number, token: string | null) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.delete(
                `${DELETE_BANK_AGENCY_URL}?bankAccountId=${bankAccountId}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Could not delete bank details for ID ' + bankAccountId,
                    data: undefined,
                }
                if (debug) console.error(err)
                return err
            }

            return response.data
        } catch (error) {
            const err = {
                status: false,
                data: undefined,
                statusMsg: 'Could not delete bank. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgencyBankServices
