// packages block
import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
// common block
import { AddUserForm } from './addUserForm'
import { FormButton } from '../../common/button'
import { PreDialogHeader } from '../../common/preDialogHeader'
// constants, theme, utils and api block
import '../style.css'
import { preaddStyles } from '../../../styles/preAddStyles'
import { BLACK_EIGHT, WHITE } from '../../../theme'
import UserManagementServices from '../../../apis/agency/user-management'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import AgencyAuth from '../../../apis/agency/auth'
import AgentProfileServices from '../../../apis/agent/settings/profile'
import Alert from '../../common/Alert'
import AgencyProfileServices from '../../../apis/agency/settings/profile'

const debug = Boolean(process.env.REACT_APP_DEBUG)

export const AddUserDialog = ({
    open,
    handleClose,
    title,
    subHeading,
    showCircularProgressBar,
    successCallback,
    isEditUser,
    agentId,
    agentData,
    userRole,
}: any) => {
    const { auth } = useSelector((state: RootState) => state)
    const { user } = auth || {}
    const classes = preaddStyles()
    const [step] = useState(1)
    const [loading] = useState(false)

    const { t } = useTranslation()

    const [
        {
            reraNo,
            firstName,
            lastName,
            password,
            role,
            email,
            nationality,
            phoneNumber,
            countryCode,
        },
        setData,
    ] = useState({
        reraNo: isEditUser ? agentData?.reraNo : auth?.user?.reraNo || '',
        firstName: isEditUser ? agentData?.firstName : '',
        lastName: isEditUser ? agentData?.lastName : '',
        password: '',
        role: isEditUser ? agentData?.type : 'agent',
        email: isEditUser ? agentData?.email : '',
        nationality: isEditUser ? agentData?.nationality : '',
        phoneNumber: isEditUser ? agentData?.phoneNumber : '',
        countryCode: isEditUser ? agentData?.countryCode : '',
    })

    const [errors] = useState([])

    const handlePersonalData = (e: Event) => {
        const { name, value } = e.target as HTMLInputElement
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handlePhoneNumber = (value: string, country: any) => {
        setData((prevState) => ({
            ...prevState,
            phoneNumber: value.replace(/\D/g, ''),
            countryCode: country.dialCode,
        }))
    }
    let customMobileNumber: any

    const handleSubmission = async () => {
        if (!auth.user) {
            const err = {
                status: false,
                statusMsg: 'No user exists',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!isEditUser) {
            if (!reraNo.length) {
                const err = {
                    status: false,
                    statusMsg: 'RERA No. cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!firstName.length) {
                const err = {
                    status: false,
                    statusMsg: 'First Name cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!lastName.length) {
                const err = {
                    status: false,
                    statusMsg: 'Last Name cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!password.length) {
                const err = {
                    status: false,
                    statusMsg: 'Password cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!email.length) {
                const err = {
                    status: false,
                    statusMsg: 'Email cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!nationality.length) {
                const err = {
                    status: false,
                    statusMsg: 'Nationality cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!role.length) {
                const err = {
                    status: false,
                    statusMsg: 'Role cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!email.length) {
                const err = {
                    status: false,
                    statusMsg: 'Email cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!phoneNumber.length) {
                const err = {
                    status: false,
                    statusMsg: 'Phone number cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }

            customMobileNumber = phoneNumber?.substring(
                countryCode?.length,
                phoneNumber?.length
            )

            const agent = {
                agencyId: auth.user.agencyId,
                reraNo: reraNo,
                firstName: firstName,
                lastName: lastName,
                password: password,
                email: email,
                phoneNumber: customMobileNumber,
                countryCode: countryCode,
                nationality: nationality,
            }
            if (role === 'agent') {
                const addAgentResponse = await UserManagementServices.addAgent(
                    agent
                )
                if (!addAgentResponse.status) {
                    Alert.error(
                        addAgentResponse?.statusMsg || 'Something went wrong'
                    )
                    if (debug) console.log(addAgentResponse)
                    return addAgentResponse
                }
            } else if (role === 'admin') {
                const signUpObject = {
                    agencyId: auth.user.agencyId,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    phoneNumber: customMobileNumber,
                    countryCode: countryCode,
                    reraNo: reraNo,
                    nationality: nationality,
                }
                const addAgencyUserResponse = await AgencyAuth.signUp(
                    signUpObject
                )
                if (!addAgencyUserResponse.status) {
                    if (debug) console.log(addAgencyUserResponse)
                    return addAgencyUserResponse
                }
            }
        } else {
            if (!reraNo.length) {
                const err = {
                    status: false,
                    statusMsg: 'RERA No. cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!firstName.length) {
                const err = {
                    status: false,
                    statusMsg: 'First Name cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (!lastName.length) {
                const err = {
                    status: false,
                    statusMsg: 'Last Name cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }

            if (!nationality) {
                const err = {
                    status: false,
                    statusMsg: 'Nationality cannot be empty',
                }
                Alert.error(err?.statusMsg)
                if (debug) console.log(err)
                return err
            }
            if (role === 'agent') {
                const editAgentResponse =
                    await AgentProfileServices.updateProfile(
                        agentId,
                        firstName,
                        lastName,
                        email,
                        customMobileNumber,
                        countryCode,
                        reraNo,
                        nationality,
                        auth?.token
                    )
                if (!editAgentResponse.status) {
                    if (debug) console.log(editAgentResponse)
                    return editAgentResponse
                }
            } else if (role === 'admin') {
                const editAgencyResponse =
                    await AgencyProfileServices.updateProfile(
                        agentId,
                        firstName,
                        lastName,
                        email,
                        customMobileNumber,
                        countryCode,
                        auth?.token,
                        reraNo,
                        nationality,
                        role
                    )
                if (!editAgencyResponse.status) {
                    if (debug) console.log(editAgencyResponse)
                    return editAgencyResponse
                }
            }
        }

        setData({
            reraNo: '',
            firstName: '',
            lastName: '',
            password: '',
            role: '',
            email: '',
            nationality: '',
            phoneNumber: '',
            countryCode: '',
        })
        successCallback()
    }
    console.log(auth)

    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className={classes.mainContainer}>
                <PreDialogHeader
                    subHeading={subHeading}
                    title={title}
                    handleClose={handleClose}
                    showCircularProgressBar={showCircularProgressBar}
                    backbut={true}
                />

                <div className={classes.dropContainer}>
                    <AddUserForm
                        reraNo={reraNo}
                        password={password}
                        firstName={firstName}
                        lastName={lastName}
                        role={role}
                        email={email}
                        phoneNumber={phoneNumber}
                        countryCode={countryCode}
                        nationality={nationality}
                        errors={errors}
                        handlePersonalData={handlePersonalData}
                        handlePhoneNumber={handlePhoneNumber}
                        isEditUser={isEditUser}
                        user={user}
                    />
                </div>

                <div className={classes.addSchooldialogFooter}>
                    <div className={classes.footerButton}>
                        <FormButton
                            noMargin={true}
                            title={'Submit'}
                            onClickHandler={handleSubmission}
                            height={45}
                            width={'15vw'}
                            borderTopRightRadius={15}
                            borderColor={BLACK_EIGHT}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={BLACK_EIGHT}
                            titleColor={WHITE}
                            hoverTitleColor={BLACK_EIGHT}
                            hoverBackgroundColor={WHITE}
                            hoverBorderColor={BLACK_EIGHT}
                            step={step}
                            loading={loading}
                            style={{ textTransform: 'capitalize' }}
                        />
                    </div>

                    <div className={classes.footerButton}>
                        <FormButton
                            title={t('text_cancel')}
                            height={45}
                            width={'15vw'}
                            borderTopRightRadius={15}
                            borderTopLeftRadius={15}
                            borderBottomLeftRadius={15}
                            borderBottomRightRadius={15}
                            backgroundColor={WHITE}
                            borderColor={BLACK_EIGHT}
                            titleColor={BLACK_EIGHT}
                            hoverBackgroundColor={BLACK_EIGHT}
                            hoverBorderColor={BLACK_EIGHT}
                            hoverTitleColor={WHITE}
                            onClickHandler={handleClose}
                            style={{ textTransform: 'capitalize' }}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
