// packages block
import { makeStyles } from '@mui/styles'
import { MoreHoriz } from '@mui/icons-material'
import { dateFormat } from '../../../constants'
import { TableRow, TableCell, Typography, Avatar } from '@mui/material'
import { BLACK_EIGHT, LIGHT_GREEN } from '../../../theme'

const useStyles = makeStyles({
    customTable: {
        '& .MuiTableCell-alignLeft': {
            padding: '0px 0px 0px 0px',
        },
    },
})

export const PaymentLinkRow = ({ isItemSelected, row }: any) => {
    const classes = useStyles()

    return (
        <TableRow
            hover
            style={{ padding: '3px 0px' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.name}
            selected={isItemSelected}
            classes={{ root: classes.customTable }}
        >
            <TableCell
                align="center"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Avatar className="table-avatar">
                    {row?.url[0] + ' ' + row?.url[1]?.toUpperCase() || ''}
                </Avatar>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {row?.url ?? 'N/A'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    padding: 10,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row.amount !== 0 ? `AED ${row?.amount}` : 'N/A'}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500, padding: 10 }}>
                <Typography
                    variant="h5"
                    style={{
                        color: BLACK_EIGHT,
                        fontSize: '0.8vw',
                    }}
                >
                    {row.amount !== 0 ? `AED ${row?.amount}` : 'N/A'}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography
                    align="center"
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {row.status ?? 'Completed'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
