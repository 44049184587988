// packages block
import { Close } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
// common block
// constants, theme, utils and api block
import backButtonIcon from '../../assets/images/back-button-icon.svg'

export const PreDialogHeader = ({
    title,
    subHeading,
    handleClose,
    showCircularProgressBar,
    backbut = false,
    crossBtn = true,
}: any) => {
    return (
        <>
            <div
                style={{
                    paddingLeft: 45,
                    paddingTop: 30,
                    alignItems: 'center',
                }}
            >
                {crossBtn && (
                    <Button
                        variant={'contained'}
                        onClick={handleClose}
                        style={{
                            backgroundColor: backbut
                                ? 'transparent'
                                : 'rgb(30,173,219)',
                            maxWidth: '30px',
                            maxHeight: '30px',
                            minWidth: '30px',
                            minHeight: '30px',
                            borderRadius: '3px',
                            boxShadow: backbut && 'none',
                        }}
                    >
                        {backbut ? (
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        ) : (
                            <Close
                                style={{ color: 'white' }}
                                fontSize={'small'}
                            />
                        )}
                    </Button>
                )}

                <Typography
                    display="flex"
                    variant="h2"
                    style={{
                        paddingLeft: 10,
                        color: 'black',
                        width: '100%',
                        textAlign: 'center',
                        marginRight: '5rem',
                        marginBottom: '10px',
                        justifyContent: 'center',
                    }}
                >
                    {title ?? ''}
                </Typography>

                {subHeading && (
                    <Typography
                        display="flex"
                        variant="h3"
                        style={{
                            paddingLeft: 10,
                            color: 'gray',
                            width: '100%',
                            textAlign: 'center',
                            marginRight: '5rem',
                            justifyContent: 'center',
                        }}
                    >
                        {subHeading ?? ''}
                    </Typography>
                )}

                {showCircularProgressBar && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                    ></div>
                )}
            </div>
        </>
    )
}
