// packages block
import { TableRow, TableCell, Avatar, Typography } from '@mui/material'
// common block
// constants, theme, utils and api block
import { BLACK_EIGHT, LIGHT_GREEN } from '../../../theme'
import { useHistory } from 'react-router'

export const UserRow = ({ isItemSelected, row }: any) => {
    const history = useHistory()

    const handleOnRowClick = () => {
        history.push(`/agency/users/${row?.id}`)
    }

    const firstName = row?.firstName || ''
    const lastName = row?.lastName || ''
    return (
        <>
            <TableRow
                hover
                style={{
                    padding: '3px 0px',
                    cursor: 'pointer',
                }}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row?.name}
                selected={isItemSelected}
                onClick={() => handleOnRowClick()}
            >
                <TableCell
                    align="center"
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <Avatar className="table-avatar">
                        {firstName || lastName
                            ? `${firstName[0]?.toUpperCase() || ''}` +
                              ' ' +
                              `${lastName[0]?.toUpperCase() || ''}`
                            : 'N/A'}
                    </Avatar>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {firstName || lastName
                                ? `${firstName}` + ' ' + `${lastName}`
                                : 'N/A'}
                        </Typography>
                    </div>
                </TableCell>

                <TableCell align="center" style={{ width: 300 }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        AED {row?.totalAmountForAgent}
                    </Typography>
                </TableCell>
                <TableCell align="center" style={{ width: 300 }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {row?.totalTransactionsForAgent ?? 'N/A'}
                    </Typography>
                </TableCell>
                <TableCell align="center" hidden={false}>
                    <Typography
                        variant="h5"
                        style={{
                            color: LIGHT_GREEN,
                            fontSize: '0.8vw',
                            paddingRight: 20,
                        }}
                    >
                        Active
                    </Typography>
                </TableCell>
            </TableRow>
        </>
    )
}
