// packages block
import { createTheme } from '@mui/material/styles'
// constants block
import { INTER } from '.'
import { createBreakpoints } from '@mui/system'
const breakpoints = createBreakpoints({})

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#5ec9e9',
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            contrastText: '#ffcc00',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    shape: {
        borderRadius: 0,
    },

    typography: {
        fontFamily: INTER,

        h1: {
            fontSize: '1.56vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
        },

        h2: {
            fontSize: '1.35vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '15px',
            },
        },

        h3: {
            fontSize: '1.04vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '14px',
            },
        },

        h4: {
            fontSize: '1.1vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '13px',
            },
        },

        h5: {
            fontSize: '0.84vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '12px',
            },
        },

        h6: {
            fontSize: '0.92vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '11px',
            },
        },

        body1: {
            fontSize: '0.85vw',
            fontWeight: 700,

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '10px',
            },
        },

        body2: {
            fontSize: '0.7vw',

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '9px',
            },
        },
        button: {
            fontSize: '0.8vw',
            fontWeight: '700',
            textTransform: 'none',

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '9px',
            },
        },
        subtitle1: {
            fontSize: '0.78vw',

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '9px',
            },
        },
        subtitle2: {
            fontSize: '2.5vw',
            fontWeight: '900',

            [breakpoints.between('xs', 'sm')]: {
                fontSize: '18px',
            },
        },
    },

    // overrides: {
    //   MuiCheckbox: {
    //     colorSecondary: {

    //       '&$checked': {
    //         color: '#04acdd',
    //       },
    //     },
    //   },

    //   MuiTableRow: {
    //     root: {
    //       padding: '3px 0px',
    //       borderBottom: "1px solid lightgray"
    //     }
    //   },

    //   MuiTableCell: {
    //     root: {
    //       padding: 10,
    //       fontSize: "0.85vw",
    //       fontWeight: 700
    //     }
    //   },

    //   MuiAlert: {

    //     action: {
    //       '&>button': {
    //         height: 'auto'
    //       }
    //     }

    //   }

    // },
})

export default customTheme
