// packages block
import { makeStyles } from '@mui/styles'
import { BLUE_THREE, GRAY_EIGHT, INTER, WHITE } from '../theme'

export const useNetworkStyles = makeStyles(() => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
    },

    textContainer: {
        width: '100%',
        padding: 40,
        position: 'relative',

        '& .MuiButton-contained': {
            backgroundColor: BLUE_THREE,
            color: WHITE,
        },

        '& .MuiTypography-body2': {
            color: GRAY_EIGHT,
            fontSize: 22,
            fontWeight: 500,
        },
    },

    pageNotFoundText: {
        fontSize: 600,
        opacity: 0.03,
        fontFamily: INTER,
        fontWeight: 100,
        lineHeight: '186px',
    },

    link: {
        textDecoration: 'none',
    },
}))
