import axios from 'axios'
import {
    ADD_AGENT_URL,
    ADD_MULTIPLE_USERS_URL,
    DELETE_AGENCY_URL,
    DELETE_AGENT_URL,
    GET_ALL_ACTIVE_AGENCY_USERS_URL,
    GET_ALL_ACTIVE_AGENT_USERS_URL,
    GET_ALL_AGENTS_BY_AGENCY_ID_URL,
    GET_ALL_INACTIVE_AGENCY_USERS_URL,
    GET_ALL_INACTIVE_AGENT_USERS_URL,
    GET_ALL_USERS_BY_AGENCY_ID_URL,
    GET_FETCH_AGENCY_USERS_BY_AGENCY_ID_URL,
    GET_FETCH_AGENTS_BY_AGENCY_ID_URL,
    GET_FETCH_USERS_BY_AGENCY_ID_URL,
} from '../../constants'
import { AddAgent } from '../../interfacesTypes/agent'

const debug = process.env.REACT_APP_DEBUG
const UserManagementServices = {
    addAgent: async (agent: AddAgent) => {
        try {
            const {
                agencyId,
                firstName,
                lastName,
                phoneNumber,
                email,
                password,
                reraNo,
                nationality,
                countryCode,
            } = agent
            const agentObject = {
                agencyId: agencyId,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                email: email,
                password: password,
                reraNo: reraNo,
                nationality: nationality,
                countryCode: `+${countryCode}`,
            }
            const response = await axios.post(ADD_AGENT_URL, agentObject)
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not add agent',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not add agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    deleteAgent: async (agentId: number, token: string | null) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.delete(
                `${DELETE_AGENT_URL}?agentId=${agentId}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not add agent',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not delete agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllAgentsForAgency: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_AGENTS_BY_AGENCY_ID_URL}?agencyId=${agencyId}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not get agents',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get agents. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllUsersForAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_USERS_BY_AGENCY_ID_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not get users',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get users. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    fetchUsersForAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        searchString: string,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_FETCH_USERS_BY_AGENCY_ID_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}&searchString=${searchString}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not get users',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get users. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    addMultipleUsers: async (
        file: any,
        agencyId: number | undefined,
        token: any
    ) => {
        try {
            const Authorization = `Bearer ${token}`
            const formData = new FormData()
            formData.append('file', file)
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization,
                },
                data: formData,
                url: `${ADD_MULTIPLE_USERS_URL}?agencyId=${agencyId}`,
            }
            const response = await axios(options)
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not add agent',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not add agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllActiveAgentUsers: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_ACTIVE_AGENT_USERS_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Something went wrong. Could not get active agents',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get active agents. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllActiveAgencyUsers: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_ACTIVE_AGENCY_USERS_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Something went wrong. Could not get active agency users',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get active agency users. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllInActiveAgentUsers: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_INACTIVE_AGENT_USERS_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Something went wrong. Could not get Inactive agents',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get Inactive agents. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getAllInActiveAgencyUsers: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_ALL_INACTIVE_AGENCY_USERS_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Something went wrong. Could not get Inactive agency users.',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get Inactive agency users. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    fetchActiveAgentsForAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        searchString: string,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_FETCH_AGENTS_BY_AGENCY_ID_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}&searchString=${searchString}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not get agents',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get agents. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    fetchActiveAgencyUsersForAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        searchString: string,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_FETCH_AGENCY_USERS_BY_AGENCY_ID_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}&searchString=${searchString}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg:
                        'Something went wrong. Could not get agency users',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get agency users. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    deleteAgency: async (agencyId: number, token: string | null) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.delete(
                `${DELETE_AGENCY_URL}?agencyId=${agencyId}`,
                config
            )
            if (!response) {
                const err = {
                    status: false,
                    statusMsg: 'Something went wrong. Could not add agency',
                    data: response,
                }
                if (debug) console.error(err)
                return err
            }
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not delete agency. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default UserManagementServices
