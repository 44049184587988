import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { AGENCY_DASHBOARD_ROUTE, AGENT_DASHBOARD_ROUTE } from '../constants'

const PublicRoute = ({ component: Component, ...rest }: any): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const { user } = auth
    const pathname = document.location.pathname
    const isAccess =
        (!pathname.startsWith('/agent/login') ||
            (!pathname.startsWith('/agency/login') && !user?.user)) ??
        true

    return (
        <>
            {isAccess ? (
                <Route {...rest} render={(props) => <Component {...props} />} />
            ) : (
                <Route>
                    <Redirect
                        to={
                            user?.userType === 'agency'
                                ? AGENCY_DASHBOARD_ROUTE
                                : AGENT_DASHBOARD_ROUTE
                        }
                    />
                </Route>
            )}
        </>
    )
}

export default PublicRoute
