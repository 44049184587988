import { createSlice } from '@reduxjs/toolkit'

const state: any = {
    isDeletedAgent: null,
}

const isDeletedAgentSlice = createSlice({
    name: 'userStatus',
    extraReducers: undefined,
    initialState: state,
    reducers: {
        updateIsDeletedAgent(state, action) {
            const { isDeletedAgent } = action.payload
            state.isDeletedAgent = isDeletedAgent
        },
    },
})

export const isDeletedAgentActions = isDeletedAgentSlice.actions

export default isDeletedAgentSlice
