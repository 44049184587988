import { makeStyles } from '@mui/styles'
import { FormControl, TextField } from '@mui/material'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiTextField-root': {
            margin: 1,

            '& .MuiOutlinedInput-root': {
                borderRadius: 10,

                '& .MuiInputBase-input': {
                    fontFamily: 'Montserrat',
                },
            },

            '& .MuiInputLabel-root': {
                color: 'black',
            },
        },
    },
}))

export const TextFieldComponent = ({
    error = false,
    label,
    onChange,
    required = false,
    disabled = false,
    value,
    type = 'text',
    name,
    errorMessage = false,
}: any) => {
    const classes = useStyles()

    return (
        <FormControl
            className={classes.root}
            variant="outlined"
            fullWidth
            error={error}
        >
            <TextField
                required={required}
                color="primary"
                label={label}
                type={type}
                value={value}
                disabled={disabled}
                name={name}
                id="text-input"
                size="small"
                variant="outlined"
                onChange={(e) => onChange(e)}
                InputLabelProps={{ shrink: true }}
            />
            <span style={{ fontSize: '0.65vw', paddingLeft: 10, color: 'red' }}>
                {errorMessage}
            </span>
        </FormControl>
    )
}
