import axios from 'axios'
import {
    FORGOT_PASSWORD_AGENT_URL,
    RESET_PASSWORD_AGENT_URL,
    SIGNIN_AGENT_URL,
    VERIFY_OTP_AGENT_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG
const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}
const AgentAuth = {
    signIn: async (data: any) => {
        try {
            const isEmail = validateEmail(data.email)
            const signInObject = {
                [isEmail ? 'email' : 'phoneNumber']: data.email,
                password: data.password,
            }
            const response = await axios.post(SIGNIN_AGENT_URL, signInObject)

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not sign in agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    forgotPassword: async (data: any) => {
        try {
            const signInObject = {
                email: data.email,
            }
            const response = await axios.post(
                FORGOT_PASSWORD_AGENT_URL,
                signInObject
            )

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could call forgetPassword. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    verifyOtp: async (data: any, otpEmail: string) => {
        try {
            const verifyOtp = {
                email: otpEmail,
                otp: data.verifyOtp,
            }
            const response = await axios.post(VERIFY_OTP_AGENT_URL, verifyOtp)

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not verify OTP for agency user. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    resetPassword: async (data: any, agentId: number) => {
        try {
            const resetPasswordObject = {
                agentId: agentId,
                newPassword: data.password,
            }
            const response = await axios.put(
                RESET_PASSWORD_AGENT_URL,
                resetPasswordObject
            )

            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not reset password for agent. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgentAuth
