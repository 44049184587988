import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import './barChart.css'
import { BarChartsProps } from '../../interfacesTypes'
import moment from 'moment'
import { getGMT4Date } from './barChartsComponentForPayments'
import AgencyTransactions from '../../apis/agency/transactions'
import AgentTransactions from '../../apis/agent/transactions'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { BLACK_EIGHT } from '../../theme'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    responsive: true,
    barPercentage: 0.7,
    plugins: {
        legend: {
            display: false,
            position: 'bottom' as const,
            labels: {
                usePointStyle: true,
                font: {
                    family: 'Montserrat',
                },
            },
        },
        tooltip: {
            callbacks: {
                label: function (context: any) {
                    const label = context.dataset.label || ''
                    return label + context.formattedValue
                },
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    family: 'Montserrat',
                    color: BLACK_EIGHT,
                    weight: 'bold',
                },
            },
        },
        y: {
            grid: {
                display: false,
            },
            position: 'right' as const,
            ticks: {
                font: {
                    family: 'Montserrat',
                    color: BLACK_EIGHT,
                    weight: 'bold',
                },
            },
        },
    },
}

const BarChartsComponentForTransactions: React.FC<BarChartsProps> = ({
    id,
    loggedIn,
    type,
}) => {
    const [loading, setLoading] = useState(false)
    const [, setName] = useState('')
    const { t } = useTranslation()
    const [months, setMonths] = useState([])
    const [firstRun, setFirstRun] = useState(true)
    const [transactionLength, setTransactionLength] = useState()
    // eslint-disable-next-line
    let startDate = moment()
        .subtract(2, 'month')
        .startOf('month')
        .format('YYYY-MM-DD')
    // eslint-disable-next-line
    let endDate = getGMT4Date(new Date().toISOString())
    const { auth } = useSelector((state: RootState) => state)

    const barData = {
        labels: months,
        datasets: [
            {
                stack: 'stack2',
                label: '',
                data: transactionLength,
                backgroundColor: '#334050',
                borderRadius: 5,
            },
        ],
    }

    const onChangeBarSelect = async (e: any) => {
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        const d = new Date()
        setName(month[d.getMonth()])

        try {
            setLoading(true)
            const value = e.target.value

            if (value === 'lastMonth') {
                setName(month[d.getMonth() - 1])
                startDate = moment()
                    .subtract(0, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }
            if (value === 'lastThree') {
                setName(month[d.getMonth() - 1])
                startDate = moment()
                    .subtract(2, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }
            if (value === 'lastSix') {
                setName('')
                startDate = moment()
                    .subtract(5, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }
            if (value === 'lastTwelve') {
                setName('')
                startDate = moment()
                    .subtract(11, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }

            if (id && loggedIn && type === 'agent') {
                AgentTransactions.getTransactionsGraphForAgent(
                    startDate,
                    endDate,
                    id,
                    auth?.token
                ).then((response: any) => {
                    if (response.status) {
                        if (value === 'lastMonth') {
                            setMonths(response?.monthsOfTransactions)
                            console.log('month', months)

                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastThree') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastSix') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastTwelve') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        }
                    }
                })
            } else if (id && loggedIn && type === 'agency') {
                AgencyTransactions.getTransactionsGraphForAgency(
                    startDate,
                    endDate,
                    id,
                    auth?.token
                ).then((response: any) => {
                    if (response.status) {
                        console.log(response.transactionsData)
                        if (value === 'lastMonth') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastThree') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastSix') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        } else if (value === 'lastTwelve') {
                            setMonths(response?.monthsOfTransactions)
                            setTransactionLength(response?.transactionsOfLength)
                        }
                    }
                })
            }

            setLoading(false)
        } catch (error) {
            console.error('erorr in onChangeBarSelect', error)
        }
    }

    const onGetTodayData = () => {
        if (firstRun) {
            // eslint-disable-next-line
            let event = {
                target: {
                    value: 'lastThree',
                },
            }
            setFirstRun(false)
            onChangeBarSelect(event)
        }
    }

    useEffect(() => {
        onGetTodayData()
    }, [])

    return (
        <div className="card-style bar-chart-card">
            <div className="card-head">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ color: '#83c17b' }}>
                        Recent
                    </Typography>
                    <Typography variant="body1">Transactions</Typography>
                </Box>
                <select
                    className="form-select shadow-sm my-select"
                    onChange={onChangeBarSelect}
                >
                    <option value="" disabled>
                        {t('text_select_period')}
                    </option>
                    <option value="lastMonth">Current Month</option>
                    <option value="lastThree" selected>
                        3 Months
                    </option>
                    <option value="lastSix">6 Months</option>
                    <option value="lastTwelve">12 Months</option>
                </select>
            </div>

            <div className="card-body">
                {loading ? (
                    <div className={'loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {t('text_fetching_details')}
                    </div>
                ) : (
                    <div className="chart-wrapper">
                        <Bar data={barData} options={options} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default BarChartsComponentForTransactions
