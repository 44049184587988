// packages block
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
// common block
import { ManagementTable } from '../../../components/managementTable'
// constants, theme, utils and api block
import '../style.css'
import { TableHeader } from './tableHeader'
import backButtonIcon from '../../../assets/images/back-button-icon.svg'
import AgentInvoiceServices from '../../../apis/agent/invoices'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

export const InvoicesTable = () => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [invoices, setInvoices] = useState([])
    const [loading] = useState(false)

    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(null)
    const [startEntry, setStartEntry] = useState(1)
    const [endEntry, setEndEntry] = useState(10)

    const { t } = useTranslation()
    // eslint-disable-next-line
    let limit = 10
    const INVOICES_COLUMNS = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('text_name'),
        },
        {
            id: 'invoice',
            numeric: false,
            disablePadding: true,
            label: 'Invoice',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: true,
            label: t('text_amount'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
    ]

    const handlePage = (pageNumber: number) => setPage(pageNumber)

    const handleSelection = () => {
        return
    }

    const handleBack = () => {
        window.location.href = '/agent/dashboard'
    }
    useEffect(() => {
        if (auth.user && pathname.startsWith('/agent')) {
            AgentInvoiceServices.getAgentInvoices(
                auth.user.id,
                page,
                limit,
                auth?.token
            ).then((response: any) => {
                if (response.status) {
                    setInvoices(response.data?.rows)
                    setTotalCount(response?.data.count)
                    setStartEntry(page === 0 ? 1 : page * limit)
                    setEndEntry(
                        (page + 1) * limit > response?.data.count
                            ? response.data.count
                            : (page + 1) * limit
                    )
                }
            })
        }
    }, [page])

    return (
        <div className="transactions-management-page">
            <Box display="flex" alignItems="center" mb={2}>
                <Button
                    variant="contained"
                    onClick={handleBack}
                    style={{
                        backgroundColor: 'transparent',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                    }}
                >
                    <img
                        src={backButtonIcon}
                        className="backbut"
                        style={{ width: 48, cursor: 'pointer' }}
                        alt="back-button-icon"
                    />
                </Button>

                <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                    Invoices
                </Typography>
            </Box>

            <div>
                {/* <TableHeader /> */}

                {loading ? (
                    <div className={'transactions-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {t('text_fetching_user_data')}
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={page}
                        handlePage={handlePage}
                        totalCount={totalCount}
                        tableFor={'invoices'}
                        data={invoices}
                        columns={INVOICES_COLUMNS}
                        startEntry={startEntry}
                        endEntry={endEntry}
                    />
                )}
            </div>
        </div>
    )
}
