import axios from 'axios'
import {
    CREATE_INVOICES_WITH_AGENT_ID_URL,
    GET_INVOICES_WITH_AGENT_ID_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgentInvoiceServices = {
    getAgentInvoices: async (
        agentId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_INVOICES_WITH_AGENT_ID_URL}?agentId=${agentId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get invoices. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    createInvoiceForCustomer: async (
        agentId: string | number,
        firstName: string,
        lastName: string,
        amount: string | number,
        email: string,
        phone: string,
        countryCode: string,
        invoiceDescription: string,
        paymentDescription: string,
        sendEmail: boolean,
        sendSms: boolean,
        sendWhatsApp: boolean,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const invoiceObj = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                countryCode: countryCode,
                amount: amount,
                agentId: agentId,
                invoiceDescription: invoiceDescription,
                paymentDescription: paymentDescription,
                sendEmail: sendEmail,
                sendSms: sendSms,
                sendWhatsApp: sendWhatsApp,
            }
            const response = await axios.post(
                `${CREATE_INVOICES_WITH_AGENT_ID_URL}`,
                invoiceObj,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not create invoice. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgentInvoiceServices
