import { Box, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import doneIcon from '../../assets/confirm-icon-animation.json'
import failedIcon from '../../assets/failed-icon-animation.json'
import { FormButton } from '../../components/common/button'
import { BLACK_EIGHT, WHITE } from '../../theme'
import { useLocation } from 'react-router'

const PaymentStatus = (): JSX.Element => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const status = queryParams.get('status')
    console.log(status)
    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Lottie
                    animationData={status === 'success' ? doneIcon : failedIcon}
                    style={{ width: 150 }}
                    loop={true}
                />
                <Typography variant="h2" mt="10px" color="green">
                    {status === 'success' ? 'Successfully!' : 'Failed!'}
                </Typography>
                <Typography variant="h6" mt="4px" mb="0px">
                    {status === 'success'
                        ? 'Payment Done Successfully'
                        : 'Payment Failed'}
                </Typography>
            </Box>

            {/*<Box mt={4} justifyContent="center" display="flex">*/}
            {/*    <FormButton*/}
            {/*        width={'14vw'}*/}
            {/*        height={45}*/}
            {/*        borderColor={WHITE}*/}
            {/*        titleColor={WHITE}*/}
            {/*        hoverTitleColor={BLACK_EIGHT}*/}
            {/*        hoverBorderColor={BLACK_EIGHT}*/}
            {/*        backgroundColor={BLACK_EIGHT}*/}
            {/*        hoverBackgroundColor={WHITE}*/}
            {/*        borderTopRightRadius={15}*/}
            {/*        borderTopLeftRadius={15}*/}
            {/*        borderBottomLeftRadius={15}*/}
            {/*        borderBottomRightRadius={15}*/}
            {/*        title={'Ok'}*/}
            {/*        style={{*/}
            {/*            textTransform: 'none',*/}
            {/*            fontSize: '0.8vw',*/}
            {/*            fontWeight: '700',*/}
            {/*            padding: 0,*/}
            {/*        }}*/}
            {/*        onClickHandler={() => {*/}
            {/*            return*/}
            {/*        }}*/}
            {/*        disabled={false}*/}
            {/*        overRideButtonCheck={true}*/}
            {/*    />*/}
            {/*</Box>*/}
        </Box>
    )
}

export default PaymentStatus
