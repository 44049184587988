import React from 'react'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Search } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
    search: {
        margin: '0',
    },

    searchInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            border: '1px solid #006B7A',

            '& .MuiOutlinedInput-input': {
                fontSize: '10px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '0px',
                border: 'none',
            },
        },
    },
}))

export const SearchInput = ({
    error = false,
    label,
    onChange,
    required = false,
    disabled = false,
    value,
    type = 'text',
    name,
}: any) => {
    const classes = useStyles()

    return (
        <FormControl
            className={classes.search}
            variant="outlined"
            fullWidth
            error={error}
        >
            <TextField
                InputLabelProps={{ shrink: true }}
                required={required}
                color="primary"
                type={type}
                className={classes.searchInput}
                value={value}
                disabled={disabled}
                id="search-input"
                size="small"
                placeholder={label}
                variant="outlined"
                onChange={onChange(name)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search fontSize="small" />
                            <i
                                className="fa fa-search"
                                style={{ color: '#bbbbbb', marginLeft: 5 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    )
}
