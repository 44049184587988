import axios from 'axios'
import {
    GET_AGENCY_PAYMENT_LINKS_URL,
    UPDATE_PAYMENT_LINK_STATUS_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgencyPaymentLinks = {
    getAgencyPaymentLinks: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_AGENCY_PAYMENT_LINKS_URL}?agencyId=${agencyId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get payment links. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    updatePaymentLinkStatus: async (
        paymentLinkId: string | number,
        status: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.put(
                `${UPDATE_PAYMENT_LINK_STATUS_URL}?paymentLinkId=${paymentLinkId}`,
                { status: status },
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not update agent status. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgencyPaymentLinks
