import { TableRow, TableCell } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Check, Clear } from '@mui/icons-material'

const useStyles = makeStyles({
    customTable: {
        '& .MuiTableCell-alignLeft': {
            padding: '0px 0px 0px 0px',
        },
    },
})
export const AccountRow = ({
    isItemSelected,
    row,
    defaultAccountsData,
}: any) => {
    const classes = useStyles()

    return (
        <TableRow
            classes={{ root: classes.customTable }}
            hover
            style={{ padding: '3px 0px' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.name}
            selected={isItemSelected}
        >
            <TableCell padding="none"></TableCell>
            <TableCell align="left" style={{ fontWeight: 500 }}>
                {row['banksLean'].name}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                {row['bankSwiftCode']}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                {row['bankAddress']}
            </TableCell>
            <TableCell
                align="center"
                style={{ textTransform: 'capitalize', fontWeight: 500 }}
            >
                {row['accountNumber']}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                {row['accountTitle']}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                {row['accountIban']}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                {defaultAccountsData[0]?.defaultLeanBankAccount ===
                row['id'] ? (
                    <Check />
                ) : (
                    <Clear />
                )}
            </TableCell>
        </TableRow>
    )
}
