import { makeStyles } from '@mui/styles'
export const BLUE_ONE = '#5AACFF'
export const BLUE_SEVEN = '#3699FF'
export const WHITE = '#FFFFFF'

export const useLoginStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },

    passwordIcon: {
        color: BLUE_ONE,
    },

    forgotPassword: {
        position: 'absolute',
        right: 0,
        width: 140,
        top: '-25px',
    },
}))
