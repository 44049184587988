// packages block
import { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { AddCircle } from '@mui/icons-material'
// common block
import { FormButton } from '../../../common/button'
import { SearchInput } from '../../../common/search'
// constants, theme, utils and api block
import '../../style.css'
import { BLACK_EIGHT, WHITE } from '../../../../theme'

export const TableHeader = ({ handleAdd, handleSearching }: any) => {
    const { t } = useTranslation()

    const [{ search, searchError }, setData] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            search: '',
            searchError: false,
        }
    )

    const handleChange = (prop: any) => (event: { target: { value: any } }) => {
        handleSearching(event.target.value)
        setData({
            [prop]: event.target.value,
            searchError: false,
        })
    }

    return (
        <div className="table-header student-popup">
            <div className="left-column">
                <div className="student_SearchBar">
                    <SearchInput
                        searchIcon={true}
                        error={searchError}
                        value={search}
                        onChange={handleChange}
                        label={'search by employee name'}
                        name="search"
                        height={45}
                    />
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 4,
                }}
            >
                <FormButton
                    width={'10vw'}
                    height={53}
                    borderColor={BLACK_EIGHT}
                    titleColor={WHITE}
                    hoverTitleColor={BLACK_EIGHT}
                    hoverBorderColor={BLACK_EIGHT}
                    backgroundColor={BLACK_EIGHT}
                    hoverBackgroundColor={WHITE}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={t('text_add_user')}
                    onClickHandler={handleAdd}
                    disabled={false}
                    overRideButtonCheck={true}
                />
                <div></div>
            </div>
        </div>
    )
}
