//packages block
import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormControl, TextField } from '@mui/material'
// components block
import ShowPassword from '../common/ShowPassword'
//styles and interfaceTypes
import { LoginInputControlProps, PasswordType } from '../../interfacesTypes'
import { PASSWORD, TEXT } from '../../constants'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiTextField-root': {
            margin: 1,

            '& .MuiOutlinedInput-root': {
                borderRadius: 10,

                '& .MuiInputBase-input': {
                    fontFamily: 'Montserrat',
                    height: '1.0375em',
                },
            },

            '& .MuiInputLabel-root': {
                color: 'black',
            },
        },
    },
}))

const LoginController: FC<LoginInputControlProps> = ({
    control,
    controllerName,
    controllerLabel,
    fieldType,
    error,
    isPassword,
    disabled,
    required,
}): JSX.Element => {
    const [passwordType, setPasswordType] = useState<PasswordType>(PASSWORD)
    const classes = useStyles()

    const handleClickShowPassword = () => {
        if (passwordType === PASSWORD) {
            setPasswordType(TEXT)
        } else {
            setPasswordType(PASSWORD)
        }
    }

    return (
        <Controller
            name={controllerName}
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid } }) => (
                <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.root}
                >
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label={controllerLabel}
                        type={
                            fieldType === 'password' ? passwordType : fieldType
                        }
                        id={controllerName}
                        required={required}
                        variant="standard"
                        error={invalid}
                        disabled={disabled}
                        fullWidth
                        helperText={error && error}
                        {...field}
                        InputProps={
                            isPassword
                                ? {
                                      endAdornment: (
                                          <ShowPassword
                                              isPassword={isPassword}
                                              passwordType={passwordType}
                                              handleShowPassword={
                                                  handleClickShowPassword
                                              }
                                          />
                                      ),
                                  }
                                : undefined
                        }
                    />
                </FormControl>
            )}
        />
    )
}

export default LoginController
