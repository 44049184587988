import googlePayIcon from '../assets/paymentMethods/google-pay-icon.png'
import visaIcon from '../assets/paymentMethods/visa-icon.png'
import americanExpressIcon from '../assets/paymentMethods/american-express-icon.png'
import applePayIcon from '../assets/paymentMethods/apple-pay-icon.png'
import maestroIcon from '../assets/paymentMethods/maestro-icon.png'
import masterCardIcon from '../assets/paymentMethods/master-card-icon.png'
import moment from 'moment'
import paymentReceivedIcon from '../assets/home/payments-home-icon.png'
import pendingTransactionsIcon from '../assets/home/pending-transactions-home-icon.png'
import completeTransactionsIcon from '../assets/home/complete-transactions-home-icon.png'
import paymentLinkIcon from '../assets/home/payment-link-icon.png'
import invoicesIcon from '../assets/home/invoice-icon.png'
import createQRIcon from '../assets/home/qr-code-icon.png'
import smsIcon from '../assets/images/sms-icon.svg'
import emailIcon from '../assets/images/email-icon.svg'
import watsappIcon from '../assets/images/whatsapp-icon.svg'
import hoverCreateQRIcon from '../assets/home/qr-code-hover-icon.png'
import hoverInvoicesIcon from '../assets/home/invoice-hover-icon.png'
import hoverPaymentLinkIcon from '../assets/home/payment-link-hover-icon.png'

export const TEXT = 'text'
export const EMAIL = 'Email'
export const SIGN_IN = 'Sign In'
export const FOUR_O_FOUR = '404'
export const PASSWORD = 'password'
export const PASSWORD_LABEL = 'Password'
export const CONFIRM_PASSWORD_LABEL = 'Confirm Password'
export const VERIFY_OTP_LABEL = 'Verify OTP'
export const BACK_TO_HOME = 'Return to Home'
export const FORGOT_PASSWORD = 'Forgot Password?'
export const INVALID_EMAIL = 'Invalid email address'
export const NOTHING_HERE_TEXT = 'Seems there is nothing here'

export const AGENCY_SUPPORT_ROUTE = '/agency/support'
export const AGENCY_SETTINGS_ROUTE = '/agency/settings'

export const AGENT_CREATE_QR_CODE_ROUTE = '/agent/create-qr-code'
export const LOGIN_ROUTE = '/'
export const AGENCY_LOGIN_ROUTE = '/agency/login'
export const AGENT_LOGIN_ROUTE = '/agent/login'
export const AGENCY_SIGNUP_ROUTE = '/agency/sign-up'
export const AGENT_SIGNUP_ROUTE = '/agent/sign-up'
export const AGENCY_DASHBOARD_ROUTE = '/agency/dashboard'
export const USERS_ROUTE = '/agency/users'
export const TRANSACTIONS_ROUTE = '/transactions'
export const TRANSACTIONS_AGENCY_ROUTE = '/agency/transactions'
export const ACCOUNTS_ROUTE = '/accounts'
export const AGENCY_ACCOUNTS_ROUTE = '/agency/accounts'
export const AGENCY_REPORTS_ROUTE = '/agency/reports'
export const SUPPORT_AGENCY_ROUTE = '/agency/support'
export const SETTINGS_AGENCY_ROUTE = '/agency/settings'
export const AGENCY_DETAIL_USER_ROUTE = '/agency/users/:userId'
export const AGENCY_DETAIL_TRANSACTION_ROUTE = '/agency/transactions/:id'

export const AGENT_DASHBOARD_ROUTE = '/agent/dashboard'
export const AGENT_PAYMENT_LINKS_ROUTE = '/agent/paymentLinks'
export const AGENT_INVOICES_ROUTE = '/agent/invoices'
export const AGENT_TRANSACTIONS_ROUTE = '/agent/transactions'
export const AGENT_SUPPORT_ROUTE = '/agent/support'
export const AGENT_SETTINGS_ROUTE = '/agent/settings'
export const DETAIL_USER_ROUTE = '/users/userId'

export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const FORGOT_PASSWORD_AGENCY_ROUTE = '/agency/forgot-password'
export const FORGOT_PASSWORD_AGENT_ROUTE = '/agent/forgot-password'
export const RESET_PASSWORD_AGENCY_ROUTE = '/agency/reset-password'
export const RESET_PASSWORD_AGENT_ROUTE = '/agent/reset-password'
export const VERIFY_OTP_AGENCY_ROUTE = '/agency/verify-otp'
export const VERIFY_OTP_AGENT_ROUTE = '/agent/verify-otp'

// AGENCY URLS //
export const GET_AGENCY_INFO_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAgencyUserInfo`
export const SIGNUP_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/signUp`
export const SIGNIN_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/signIn`
export const FORGOT_PASSWORD_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/forgotPassword`
export const VERIFY_OTP_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/verifyOtp`
export const RESET_PASSWORD_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/resetPassword`
export const UPDATE_AGENCY_USER_URL = `${process.env.REACT_APP_API_URL}/api/agency/updateAgencyUser`
export const UPDATE_AGENCY_INFO_USER_URL = `${process.env.REACT_APP_API_URL}/api/agency/updateAgency`
export const UPDATE_AGENCY_PROFILE_PICTURE_URL = `${process.env.REACT_APP_API_URL}/api/agency/updateProfilePicture`
export const UPDATE_AGENCY_COMPANY_PROFILE_PICTURE_URL = `${process.env.REACT_APP_API_URL}/api/agency/updateProfilePictureForAgency`
export const CHANGE_AGENCY_USER_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/agency/changePassword`
export const GET_ALL_AGENTS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllAgents`
export const GET_ALL_USERS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllUsers`
export const GET_AGENCY_TRANSACTIONS_STATS_URL = `${process.env.REACT_APP_API_URL}/api/transactions/agencyStats`
export const GET_ALL_ACTIVE_AGENT_USERS_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllActiveAgentUsers`
export const GET_ALL_ACTIVE_AGENCY_USERS_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllActiveAgencyUsers`
export const GET_ALL_INACTIVE_AGENT_USERS_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllInActiveAgentUsers`
export const GET_ALL_INACTIVE_AGENCY_USERS_URL = `${process.env.REACT_APP_API_URL}/api/agency/getAllInActiveAgencyUsers`

export const GET_TRANSACTIONS_BY_AGENT_ID_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getAgencyTransactions`
// AGENT URLS //
export const SIGNIN_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/signIn`
export const FORGOT_PASSWORD_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/forgotPassword`
export const VERIFY_OTP_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/verifyOtp`
export const RESET_PASSWORD_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/resetPassword`
export const LIST_AGENTS_URL = `${process.env.REACT_APP_API_URL}/api/agent/getAgentInfo`
export const DELETED_AGENT_INFO_URL = `${process.env.REACT_APP_API_URL}/api/agent/getDeletedAgentInfo`
export const UPDATE_AGENT_STATUS_URL = `${process.env.REACT_APP_API_URL}/api/agent/updateStatus`
export const ADD_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/signUp`
export const DELETE_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/agent/delete`
export const DELETE_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/agency/delete`
export const UPDATE_AGENT_USER_URL = `${process.env.REACT_APP_API_URL}/api/agent/updateAgentInfo`
export const UPDATE_AGENT_PROFILE_PICTURE_URL = `${process.env.REACT_APP_API_URL}/api/agent/updateProfilePicture`
export const CHANGE_AGENT_USER_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/agent/changePassword`
export const UPDATE_PAYMENT_LINK_STATUS_URL = `${process.env.REACT_APP_API_URL}/api/paymentLinks/updatePaymentLinkStatus`
export const ADD_MULTIPLE_USERS_URL = `${process.env.REACT_APP_API_URL}/api/agency/csvAddUsers`
export const UPDATE_AGENCY_USER_STATUS_URL = `${process.env.REACT_APP_API_URL}/api/agency/updateUserStatus`

export const GET_TRANSACTIONS_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getTransactions`
export const GET_TRANSACTIONS_BY_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getAgentTransactions`
export const GET_INVOICE_TRANSACTIONS_BY_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getAgentInvoiceTransactions`
export const GET_PAYMENT_LINK_TRANSACTIONS_BY_AGENT_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getAgentPaymentLinkTransactions`
export const GET_PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getAgencyPaymentLinkTransactions`
export const PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/paymentLinkTransactionsForAgency`
export const PENDING_PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/pendingPaymentLinkTransactionsForAgency`
export const DASHBOARD_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/dashboardTransactionsForAgency`
export const DASHBOARD_PENDING_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/dashboardPendingTransactionsForAgency`
export const AGENT_TRANSACTIONS_AGAINST_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/agentTransactionsAgainstAgency`
export const GET_AGENT_PAYMENT_LINKS_URL = `${process.env.REACT_APP_API_URL}/api/paymentLinks/allAgentPaymentLinks`
export const GET_AGENT_PAYMENT_LINKS_WITH_UUID_URL = `${process.env.REACT_APP_API_URL}/api/paymentLinks/agentPaymentLinkDetails`
export const CREATE_STANDALONE_PAYMENT_LINK_URL = `${process.env.REACT_APP_API_URL}/api/paymentLinks/create`
export const GET_AGENCY_PAYMENT_LINKS_URL = `${process.env.REACT_APP_API_URL}/api/paymentLinks/allAgencyPaymentLinks`
export const GET_INVOICES_WITH_AGENT_ID_URL = `${process.env.REACT_APP_API_URL}/api/invoices/getInvoicesAgent`
export const CREATE_INVOICES_WITH_AGENT_ID_URL = `${process.env.REACT_APP_API_URL}/api/invoices/createAndSend`
export const GET_INVOICE_TRANSACTIONS_WITH_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/transactions/invoiceTransactions`
export const INVOICE_TRANSACTIONS_BY_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/transactions/invoiceTransactionsForAgency`
export const PENDING_INVOICE_TRANSACTIONS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/transactions/pendingInvoiceTransactionsForAgency`
export const GET_TRANSACTIONS_STATS_URL = `${process.env.REACT_APP_API_URL}/api/transactions/agentStats`
export const GET_TRANSACTIONS_USING_PAYMENT_LINK_URL = `${process.env.REACT_APP_API_URL}/api/transactions/createTransactionByPaymentLink`
export const GET_BANK_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/banks/getBank`
export const EDIT_BANK_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/banks/editBank`
export const DELETE_BANK_AGENCY_URL = `${process.env.REACT_APP_API_URL}/api/banks/deleteBank`
export const GET_TRANSACTIONS_GRAPH_FOR_AGENCY = `${process.env.REACT_APP_API_URL}/api/transactions/getTransactionsGraphForAgency`
export const GET_PAYMENT_LINKS_GRAPH_FOR_AGENCY = `${process.env.REACT_APP_API_URL}/api/transactions/getPaymentLinksGraphForAgency`
export const GET_TRANSACTIONS_GRAPH_FOR_AGENT = `${process.env.REACT_APP_API_URL}/api/transactions/getTransactionsGraphForAgent`
export const GET_PAYMENT_LINKS_GRAPH_FOR_AGENT = `${process.env.REACT_APP_API_URL}/api/transactions/getPaymentLinksGraphForAgent`
export const GET_FETCH_USERS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/agency/fetchUsers`
export const GET_TRANSACTION_DETAIL_URL = `${process.env.REACT_APP_API_URL}/api/transactions/getTransactionDetailsById`
export const FETCH_TRANSACTION_DETAIL_URL = `${process.env.REACT_APP_API_URL}/api/transactions/fetchTransactionDetailsById`
export const GET_FETCH_AGENTS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/agency/fetchActiveAgents`
export const GET_FETCH_AGENCY_USERS_BY_AGENCY_ID_URL = `${process.env.REACT_APP_API_URL}/api/agency/fetchActiveAgencyUsers`

export const STATS = [
    {
        id: '0',
        name: 'Payments Received',
        value: 'AED 126,000',
        icon: paymentReceivedIcon,
    },
    {
        id: '1',
        name: 'Completed Transactions',
        value: '52',
        icon: pendingTransactionsIcon,
    },
    {
        id: '2',
        name: 'Pending Transactions',
        value: '12',
        icon: completeTransactionsIcon,
    },
]

export const amountFormat = (amount: number | bigint) => {
    const formatter = new Intl.NumberFormat('en-Us', {
        style: 'currency',
        currency: 'AED',
    })
    const val = formatter.format(amount)
    return val.substr(0, val.indexOf('.'))
}

export const dateFormat = (date: string | number | Date) => {
    const formattedDate = moment(new Date(date)).format('DD-MM-YYYY  h:mm a')
    return formattedDate
}

export const BASE_IMG = 'https://stage-api.paynestschools.ae/upload/'

export const ACTIONS_DATA = [
    {
        id: '0',
        name: 'Create QR',
        icon: createQRIcon,
        borderColor: '#F1BF42',
        textColor: '#F1BF42',
        link: './create-qr-code',
        hoverIcon: hoverCreateQRIcon,
        hoverTextColor: '#F1BF42',
    },
    {
        id: '1',
        name: 'Create Payment Link',
        icon: paymentLinkIcon,
        borderColor: '#74ba6b',
        textColor: '#74ba6b',
        link: './paymentLinks/create-payment-link',
        hoverIcon: hoverPaymentLinkIcon,
        hoverTextColor: '#74ba6b',
    },
    {
        id: '2',
        name: 'Generate Invoice',
        icon: invoicesIcon,
        borderColor: '#2d6978',
        textColor: '#2d6978',
        link: './invoices/generate-invoice',
        hoverIcon: hoverInvoicesIcon,
        hoverTextColor: '#2d6978',
    },
]

export const COUNTRIES = [
    {
        value: 'Pakistan',
        label: 'Pakistan',
    },
    {
        value: 'India',
        label: 'India',
    },

    { value: 'afghan', label: 'Afghan' },
    { value: 'albanian', label: 'Albanian' },
    { value: 'algerian', label: 'Algerian' },
    { value: 'american', label: 'American' },
    { value: 'andorran', label: 'Andorran' },
    { value: 'angolan', label: 'Angolan' },
    { value: 'antiguans', label: 'Antiguans' },
    { value: 'argentinean', label: 'Argentinean' },
    { value: 'armenian', label: 'Armenian' },
    { value: 'australian', label: 'Australian' },
    { value: 'austrian', label: 'Austrian' },
    { value: 'azerbaijani', label: 'Azerbaijani' },
    { value: 'bahamian', label: 'Bahamian' },
    { value: 'bahraini', label: 'Bahraini' },
    { value: 'bangladeshi', label: 'Bangladeshi' },
    { value: 'barbadian', label: 'Barbadian' },
    { value: 'barbudans', label: 'Barbudans' },
    { value: 'batswana', label: 'Batswana' },
    { value: 'belarusian', label: 'Belarusian' },
    { value: 'belgian', label: 'Belgian' },
    { value: 'belizean', label: 'Belizean' },
    { value: 'beninese', label: 'Beninese' },
    { value: 'bhutanese', label: 'Bhutanese' },
    { value: 'bolivian', label: 'Bolivian' },
    { value: 'bosnian', label: 'Bosnian' },
    { value: 'brazilian', label: 'Brazilian' },
    { value: 'british', label: 'British' },
    { value: 'bruneian', label: 'Bruneian' },
    { value: 'bulgarian', label: 'Bulgarian' },
    { value: 'burkinabe', label: 'Burkinabe' },
    { value: 'burmese', label: 'Burmese' },
    { value: 'burundian', label: 'Burundian' },
    { value: 'cambodian', label: 'Cambodian' },
    { value: 'cameroonian', label: 'Cameroonian' },
    { value: 'canadian', label: 'Canadian' },
    { value: 'cape verdean', label: 'Cape Verdean' },
    { value: 'central african', label: 'Central African' },
    { value: 'chadian', label: 'Chadian' },
    { value: 'chilean', label: 'Chilean' },
    { value: 'chinese', label: 'Chinese' },
    { value: 'colombian', label: 'Colombian' },
    { value: 'comoran', label: 'Comoran' },
    { value: 'congolese', label: 'Congolese' },
    { value: 'costa rican', label: 'Costa Rican' },
    { value: 'croatian', label: 'Croatian' },
    { value: 'cuban', label: 'Cuban' },
    { value: 'cypriot', label: 'Cypriot' },
    { value: 'czech', label: 'Czech' },
    { value: 'danish', label: 'Danish' },
    { value: 'djibouti', label: 'Djibouti' },
    { value: 'dominican', label: 'Dominican' },
    { value: 'dutch', label: 'Dutch' },
    { value: 'east timorese', label: 'East Timorese' },
    { value: 'ecuadorean', label: 'Ecuadorean' },
    { value: 'egyptian', label: 'Egyptian' },
    { value: 'emirian', label: 'Emirian' },
    { value: 'equatorial guinean', label: 'Equatorial Guinean' },
    { value: 'eritrean', label: 'Eritrean' },
    { value: 'estonian', label: 'Estonian' },
    { value: 'ethiopian', label: 'Ethiopian' },
    { value: 'fijian', label: 'Fijian' },
    { value: 'filipino', label: 'Filipino' },
    { value: 'finnish', label: 'Finnish' },
    { value: 'french', label: 'French' },
    { value: 'gabonese', label: 'Gabonese' },
    { value: 'gambian', label: 'Gambian' },
    { value: 'georgian', label: 'Georgian' },
    { value: 'german', label: 'German' },
    { value: 'ghanaian', label: 'Ghanaian' },
    { value: 'greek', label: 'Greek' },
    { value: 'grenadian', label: 'Grenadian' },
    { value: 'guatemalan', label: 'Guatemalan' },
    { value: 'guinea-bissauan', label: 'Guinea-Bissauan' },
    { value: 'guinean', label: 'Guinean' },
    { value: 'guyanese', label: 'Guyanese' },
    { value: 'haitian', label: 'Haitian' },
    { value: 'herzegovinian', label: 'Herzegovinian' },
    { value: 'honduran', label: 'Honduran' },
    { value: 'hungarian', label: 'Hungarian' },
    { value: 'icelander', label: 'Icelander' },
    { value: 'indian', label: 'Indian' },
    { value: 'indonesian', label: 'Indonesian' },
    { value: 'iranian', label: 'Iranian' },
    { value: 'iraqi', label: 'Iraqi' },
    { value: 'irish', label: 'Irish' },
    { value: 'israeli', label: 'Israeli' },
    { value: 'italian', label: 'Italian' },
    { value: 'ivorian', label: 'Ivorian' },
    { value: 'jamaican', label: 'Jamaican' },
    { value: 'japanese', label: 'Japanese' },
    { value: 'jordanian', label: 'Jordanian' },
    { value: 'kazakhstani', label: 'Kazakhstani' },
    { value: 'kenyan', label: 'Kenyan' },
    { value: 'kittian and nevisian', label: 'Kittian and Nevisian' },
    { value: 'kuwaiti', label: 'Kuwaiti' },
    { value: 'kyrgyz', label: 'Kyrgyz' },
    { value: 'laotian', label: 'Laotian' },
    { value: 'latvian', label: 'Latvian' },
    { value: 'lebanese', label: 'Lebanese' },
    { value: 'liberian', label: 'Liberian' },
    { value: 'libyan', label: 'Libyan' },
    { value: 'liechtensteiner', label: 'Liechtensteiner' },
    { value: 'lithuanian', label: 'Lithuanian' },
    { value: 'luxembourger', label: 'Luxembourger' },
    { value: 'macedonian', label: 'Macedonian' },
    { value: 'malagasy', label: 'Malagasy' },
    { value: 'malawian', label: 'Malawian' },
    { value: 'malaysian', label: 'Malaysian' },
    { value: 'maldivan', label: 'Maldivan' },
    { value: 'malian', label: 'Malian' },
    { value: 'maltese', label: 'Maltese' },
    { value: 'marshallese', label: 'Marshallese' },
    { value: 'mauritanian', label: 'Mauritanian' },
    { value: 'mauritian', label: 'Mauritian' },
    { value: 'mexican', label: 'Mexican' },
    { value: 'micronesian', label: 'Micronesian' },
    { value: 'moldovan', label: 'Moldovan' },
    { value: 'monacan', label: 'Monacan' },
    { value: 'mongolian', label: 'Mongolian' },
    { value: 'moroccan', label: 'Moroccan' },
    { value: 'mosotho', label: 'Mosotho' },
    { value: 'motswana', label: 'Motswana' },
    { value: 'mozambican', label: 'Mozambican' },
    { value: 'namibian', label: 'Namibian' },
    { value: 'nauruan', label: 'Nauruan' },
    { value: 'nepalese', label: 'Nepalese' },
    { value: 'new zealander', label: 'New Zealander' },
    { value: 'ni-vanuatu', label: 'Ni-Vanuatu' },
    { value: 'nicaraguan', label: 'Nicaraguan' },
    { value: 'nigerien', label: 'Nigerien' },
    { value: 'north korean', label: 'North Korean' },
    { value: 'northern irish', label: 'Northern Irish' },
    { value: 'norwegian', label: 'Norwegian' },
    { value: 'omani', label: 'Omani' },
    { value: 'pakistani', label: 'Pakistani' },
    { value: 'palauan', label: 'Palauan' },
    { value: 'panamanian', label: 'Panamanian' },
    { value: 'papua new guinean', label: 'Papua New Guinean' },
    { value: 'paraguayan', label: 'Paraguayan' },
    { value: 'peruvian', label: 'Peruvian' },
    { value: 'polish', label: 'Polish' },
    { value: 'portuguese', label: 'Portuguese' },
    { value: 'qatari', label: 'Qatari' },
    { value: 'romanian', label: 'Romanian' },
    { value: 'russian', label: 'Russian' },
    { value: 'rwandan', label: 'Rwandan' },
    { value: 'saint lucian', label: 'Saint Lucian' },
    { value: 'salvadoran', label: 'Salvadoran' },
    { value: 'samoan', label: 'Samoan' },
    { value: 'san marinese', label: 'San Marinese' },
    { value: 'sao tomean', label: 'Sao Tomean' },
    { value: 'saudi', label: 'Saudi' },
    { value: 'scottish', label: 'Scottish' },
    { value: 'senegalese', label: 'Senegalese' },
    { value: 'serbian', label: 'Serbian' },
    { value: 'seychellois', label: 'Seychellois' },
    { value: 'sierra leonean', label: 'Sierra Leonean' },
    { value: 'singaporean', label: 'Singaporean' },
    { value: 'slovakian', label: 'Slovakian' },
    { value: 'slovenian', label: 'Slovenian' },
    { value: 'solomon islander', label: 'Solomon Islander' },
    { value: 'somali', label: 'Somali' },
    { value: 'south african', label: 'South African' },
    { value: 'south korean', label: 'South Korean' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'sri lankan', label: 'Sri Lankan' },
    { value: 'sudanese', label: 'Sudanese' },
    { value: 'surinamer', label: 'Surinamer' },
    { value: 'swazi', label: 'Swazi' },
    { value: 'swedish', label: 'Swedish' },
    { value: 'swiss', label: 'Swiss' },
    { value: 'syrian', label: 'Syrian' },
    { value: 'taiwanese', label: 'Taiwanese' },
    { value: 'tajik', label: 'Tajik' },
    { value: 'tanzanian', label: 'Tanzanian' },
    { value: 'thai', label: 'Thai' },
    { value: 'togolese', label: 'Togolese' },
    { value: 'tongan', label: 'Tongan' },
    { value: 'trinidadian or tobagonian', label: 'Trinidadian or Tobagonian' },
    { value: 'tunisian', label: 'Tunisian' },
    { value: 'turkish', label: 'Turkish' },
    { value: 'tuvaluan', label: 'Tuvaluan' },
    { value: 'ugandan', label: 'Ugandan' },
    { value: 'ukrainian', label: 'Ukrainian' },
    { value: 'uruguayan', label: 'Uruguayan' },
    { value: 'uzbekistani', label: 'Uzbekistani' },
    { value: 'venezuelan', label: 'Venezuelan' },
    { value: 'vietnamese', label: 'Vietnamese' },
    { value: 'welsh', label: 'Welsh' },
    { value: 'yemenite', label: 'Yemenite' },
    { value: 'zambian', label: 'Zambian' },
    { value: 'zimbabwean', label: 'Zimbabwean' },
]

export const ROLES = [
    {
        value: 'agent',
        label: 'Agent',
    },
    {
        value: 'admin',
        label: 'Admin',
    },
]

export const NATIONALITY = [
    {
        value: 'afghan',
        label: 'Afghan',
    },
    {
        value: 'albanian',
        label: 'Albanian',
    },
    {
        value: 'australian',
        label: 'Australian',
    },
    {
        value: 'bangladeshi',
        label: 'Bangladeshi',
    },
    {
        value: 'brazilian',
        label: 'Brazilian',
    },
    {
        value: 'bulgarian',
        label: 'Bulgarian',
    },
    {
        value: 'canadian',
        label: 'Canadian',
    },
    {
        value: 'chinese',
        label: 'Chinese',
    },
    {
        value: 'colombian',
        label: 'Colombian',
    },
    {
        value: 'egyptian',
        label: 'Egyptian',
    },
    {
        value: 'english',
        label: 'English',
    },
    {
        value: 'french',
        label: 'French',
    },
    {
        value: 'german',
        label: 'German',
    },
    {
        value: 'greek',
        label: 'Greek',
    },
    {
        value: 'icelandic',
        label: 'Icelandic',
    },
    {
        value: 'indian',
        label: 'Indian',
    },
    {
        value: 'indonasian',
        label: 'Indonasion',
    },
    {
        value: 'iranian',
        label: 'Iranian',
    },
    {
        value: 'iraqi',
        label: 'Iraqi',
    },
    {
        value: 'irish',
        label: 'Irish',
    },
    {
        value: 'israeli',
        label: 'Israeli',
    },
    {
        value: 'japanese',
        label: 'Japanese',
    },
    {
        value: 'kuwaiti',
        label: 'Kuwaiti',
    },
    {
        value: 'lebanese',
        label: 'Lebanese',
    },
    {
        value: 'mexican',
        label: 'Mexican',
    },
    {
        value: 'moroocan',
        label: 'Moroocan',
    },
    {
        value: 'nigerian',
        label: 'Nigerian',
    },
    {
        value: 'pakistani',
        label: 'Pakistani',
    },
    {
        value: 'phillippine',
        label: 'Phillippine',
    },
    {
        value: 'romanian',
        label: 'Romanian',
    },
    {
        value: 'russian',
        label: 'Russian',
    },
    {
        value: 'saudi',
        label: 'Saudi',
    },
    {
        value: 'scottish',
        label: 'Scottish',
    },
    {
        value: 'singaporian',
        label: 'Singaporian',
    },
    {
        value: 'korean',
        label: 'Korean',
    },

    {
        value: 'spanish',
        label: 'Spanish',
    },
    {
        value: 'tajikistan',
        label: 'tajikistan',
    },
    {
        value: 'thai',
        label: 'Thai',
    },
    {
        value: 'turkish',
        label: 'Turkish',
    },
    {
        value: 'emairati',
        label: 'Emairati',
    },
    {
        value: 'welsh',
        label: 'Welsh',
    },
    {
        value: 'zambian',
        label: 'Zambian',
    },
    {
        value: 'zimbabwean',
        label: 'Zimbabwean',
    },
]

export const RELIGIONS_LIST = [
    {
        value: 'islam',
        label: 'Islam',
    },
    {
        value: 'hinduism',
        label: 'Hinduism',
    },
    {
        value: 'buddhism',
        label: 'Buddhism',
    },
    {
        value: 'christianity',
        label: 'Christianity',
    },
    {
        value: 'judasim',
        label: 'Judasim',
    },
    {
        value: 'irreligion',
        label: 'Irreligion',
    },
]

export const dummyBankDetailData = [
    {
        name: 'Bank Name',
        value: 'EMIRTAES NBD',
    },
    {
        name: 'Bank Swift Code',
        value: '09966',
    },
    {
        name: 'Bank Address',
        value: 'MOE DUBAI',
    },
    {
        name: 'Account Number',
        value: '2309801112',
    },
    {
        name: 'Account Title',
        value: 'AHMAD MUBASHAR',
    },
    {
        name: 'Account IBAN',
        value: 'PK2309801112',
    },
]

export const dummyAgentDetailData = [
    {
        name: 'Rera No.',
        value: '786745',
    },
    {
        name: 'Full Name',
        value: 'Ali Ramish',
    },
    {
        name: 'Phone Number',
        value: '786745',
    },
    {
        name: 'Email',
        value: 'ali@yahoo.com',
    },
    {
        name: 'Nationality',
        value: 'Pakistan',
    },
]

export const radioBtnData = [
    {
        value: 'dailyReport',
        label: 'Download Daily Report',
    },
    {
        value: 'monthlyReport',
        label: 'Download Monthly Report',
    },
]

export const ACTIVE_INACTIVE_DUMMY_DATA = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'Inactive',
        label: 'Inactive',
    },
    {
        value: 'all',
        label: 'All',
    },
]

export const paymentMegthodsIconData = [
    {
        icon: googlePayIcon,
        alt: 'google-pay-icon',
    },
    {
        icon: applePayIcon,
        alt: 'apple-pay-icon',
    },
    {
        icon: visaIcon,
        alt: 'visa-icon',
    },
    {
        icon: masterCardIcon,
        alt: 'master-card-icon',
    },
    {
        icon: maestroIcon,
        alt: 'maestro-icon',
    },
    {
        icon: americanExpressIcon,
        alt: 'american-express-icon',
    },
]

export const AGENT_CREATE_PAYMENT_LINK_ROUTE =
    '/agent/paymentLinks/create-payment-link'

export const paymentRadioBtnData = [
    {
        value: 'Rental Commission',
        label: 'Rental Commission',
    },
    {
        value: 'Sale Commission',
        label: 'Sale Commission',
    },
]

export const swicthButtonData = [
    {
        value: 'Customer details',
        label: 'Customer details',
        description:
            'If enabled, your customer would be required to enter their personal details.',
    },
    {
        value: 'Message',
        label: 'Message',
        description:
            'If enabled, your customer would be required to enter their personal details.',
    },
    {
        value: 'Share Receipt',
        label: 'Share Receipt',
        description:
            'If enabled, your customer would be required to enter their personal details.',
    },
]

export const AGENT_GENERATE_INVOICE_ROUTE = '/agent/invoices/generate-invoice'

export const invoicesCheckBoxData = [
    {
        value: true,
        label: 'Email Notification',
        icon: emailIcon,
    },
    {
        value: false,
        label: 'SMS Notification',
        icon: smsIcon,
    },
    {
        value: false,
        label: 'WhatsApp Notification',
        icon: watsappIcon,
    },
]

export const USER_ACTIVE_INACTIVE_DUMMY_DATA = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
]

export const TRANSACTION_ACTIVE_INACTIVE_DUMMY_DATA = [
    {
        value: 'Pending',
        label: 'Active',
    },
    {
        value: 'Inactive',
        label: 'Inactive',
    },
    {
        value: 'Deleted',
        label: 'Delete',
    },
]
export const AGENT_EXTERNAL_PAYMENT_LINK_ROUTE = '/agent/payment-link/:uuid'
export const AGENT_PAYMENT_STATUS_ROUTE = '/agent/payment-status'

export const OTP_LABEL = 'OTP'
export const CONFIRM_YOUR_PASSWORD = 'Confirm your password'
export const PASSWORDS_MUST_MATCH = 'Passwords must match'
export const LAST_NAME = 'Last Name'
export const FIRST_NAME = 'First Name'
export const NAME = 'Name'
export const RERA_NO = 'Rera No'
