import { Typography } from '@mui/material'
import './style.css'

export const Widget = ({ name, value, icon }: any) => {
    return (
        <div className="widget-container">
            <div className="widget-inner-container">
                <div className="widget-value-container">
                    <Typography
                        variant="h5"
                        color="black"
                        fontWeight={600}
                        mb={1}
                    >
                        {name}
                    </Typography>
                    <Typography variant="h2" color="black">
                        {value && value}
                    </Typography>
                </div>
                <img src={icon} alt="" className="original-icon" />
            </div>
        </div>
    )
}
