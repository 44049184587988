// packages block
import { BrowserRouter as Router } from 'react-router-dom'
// components
import Routes from './routes'
// styles, history, constants and block
// import history from "./components/common/history";

const AppContainer = () => {
    return (
        <Router>
            <Routes />
        </Router>
    )
}

export default AppContainer
