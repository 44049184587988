import Checkbox from '@mui/material/Checkbox'
import { FormControl, FormGroup, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
    root: {
        '& label': {
            marginTop: -3, // fix the icon alignment issue
        },
    },
    label: {
        display: 'inline-flex',
        alignItems: 'center',
    },
})
const ControlledCheckbox = ({ value, label, icon, setCheckbox }: any) => {
    const classes = useStyles()
    return (
        <FormGroup>
            <FormControlLabel
                labelPlacement="end"
                value="end"
                control={
                    <Checkbox
                        color="success"
                        checked={value}
                        onChange={() => setCheckbox(!value)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                label={
                    <div className={classes.label}>
                        <img src={icon} alt="" style={{ marginRight: 20 }} />{' '}
                        <span>{label}</span>
                    </div>
                }
            />
        </FormGroup>
    )
}

export default ControlledCheckbox
