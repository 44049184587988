// packages block
import { FC } from 'react'
import { Route } from 'react-router-dom'
// packages block
// context, interfaces/types and constants block
import { PrivateRouteProps } from '../interfacesTypes'
import MainLayout from '../components/mainLayout'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { PageNotFound } from '../pages/404'

const PrivateRoute: FC<PrivateRouteProps> = ({
    component: Component,
    ...rest
}): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const { user } = auth
    let isAccess = false
    if (rest.path && user?.userType) {
        isAccess =
            user?.userType === 'admin' || user?.userType === 'agent'
                ? true
                : false
    }
    return (
        <>
            {isAccess ? (
                <MainLayout>
                    <Route
                        {...rest}
                        render={(props) => {
                            return <Component {...props} />
                        }}
                    />
                </MainLayout>
            ) : (
                <Route component={PageNotFound} />
            )}
        </>
    )
}

export default PrivateRoute
