// packages block
import { TableRow, TableCell, Avatar, Typography } from '@mui/material'
// common block
// constants, theme, utils and api block
import {
    BLACK_EIGHT,
    BLUE_FOUTEEN,
    LIGHT_GREEN,
    ORANGE_TWO,
    RED,
} from '../../../theme'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { dateFormat } from '../../../constants'
// import BasicSelect from "../../common/select";

export const InActiveAgencyUsersTableRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const { auth } = useSelector((state: RootState) => state)

    const firstName = row?.firstName ?? ''
    const lastName = row?.lastName ?? ''
    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return (
        <>
            {row?.id !== auth?.user?.id ? (
                <TableRow
                    hover
                    style={{
                        padding: '3px 0px',
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.name}
                    selected={isItemSelected}
                >
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.reraNo}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar className="table-avatar">
                            {firstName || lastName
                                ? `${firstName[0]?.toUpperCase() || ''}` +
                                  ' ' +
                                  `${lastName[0]?.toUpperCase() || ''}`
                                : 'N/A'}
                        </Avatar>

                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.8vw',
                                }}
                            >
                                {firstName || lastName
                                    ? `${firstName}` + ' ' + `${lastName}`
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </TableCell>

                    <TableCell align="center" style={{ width: 300 }}>
                        {/* <BasicSelect /> */}
                        <Typography
                            variant="h5"
                            style={{ color: BLUE_FOUTEEN, fontSize: '0.8vw' }}
                        >
                            {row?.email ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: 300 }}>
                        {/* <FormSelect /> */}
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.countryCode ?? ''} {row?.phoneNumber ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" hidden={false}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.type
                                ? capitalizeFirstLetter(row?.type)
                                : 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" hidden={false}>
                        <Typography variant="h5" style={{ fontSize: '0.8vw' }}>
                            {dateFormat(row?.deletedAt) ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography
                            variant="h5"
                            style={{
                                color: ORANGE_TWO,
                                fontSize: '0.8vw',
                            }}
                        >
                            Deleted
                        </Typography>
                    </TableCell>
                </TableRow>
            ) : (
                <></>
            )}
        </>
    )
}
