// packages block
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@mui/material'
// common block
import { TableHeader } from './tableHeader'
import { AddUserDialog } from '../userForm'
import { PreAddDialog } from '../../common/preAddDialog'
import { FileUploader } from '../../common/fileUploader'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
import { AddMultiUserDialog } from '../userForm/addMultiUserDialog'
import { ManagementTable } from '../../../components/managementTable'
// constants, theme, utils and api block
import '../style.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import AuthState from '../../../interfacesTypes/auth'
import UserManagementServices from '../../../apis/agency/user-management'
import debounce from 'lodash/debounce'
import Alert from '../../common/Alert'
import { AgencyUserTableHeader } from './agencyUsersTableHeader'

export const UsersTableContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [isPreAddDialogVisible, setIsPreAddDialogVisible] = useState(false)
    const [isAddUserDialogVisible, setIsAddUserDialogVisible] = useState(false)
    const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)
    const [isFileUploaderVisible, setIsFileUploaderVisible] = useState(false)
    const [isAddMultiUserDialogVisible, setIsAddMultiUserDialogVisible] =
        useState(false)
    const [activeAgentLoading, setActiveAgentLoading] = useState(true)
    const [activeAgencyUserLoading, setActiveAgencyUserLoading] = useState(true)
    const [inActiveAgentLoading, setInActiveAgentLoading] = useState(true)
    const [inActiveAgencyUserLoading, setInActiveAgencyUserLoading] =
        useState(true)

    const [activeAgentpage, setActiveAgentPage] = useState(0)
    const [activeAgencypage, setActiveAgencyPage] = useState(0)
    const [inActiveAgentpage, setInActiveAgentPage] = useState(0)
    const [inActiveAgencypage, setInActiveAgencyPage] = useState(0)

    const [totalActiveAgentsCount, setTotalActiveAgentsCount] = useState(null)
    const [totalActiveAgencyUsersCount, setTotalActiveAgencyUsersCount] =
        useState(null)
    const [totalInActiveAgentsCount, setTotalInActiveAgentsCount] =
        useState(null)
    const [totalInActiveAgencyUsersCount, setTotalInActiveAgencyUsersCount] =
        useState(null)

    const [selectedIds, setSelectedIds] = useState([])
    const [startActiveAgentEntry, setStartActiveAgentEntry] = useState(1)
    const [startActiveAgencyEntry, setStartActiveAgencyEntry] = useState(1)
    const [startInActiveAgentEntry, setStartInActiveAgentEntry] = useState(1)
    const [startInActiveAgencyEntry, setStartInActiveAgencyEntry] = useState(1)

    const [endActiveAgentEntry, setEndActiveAgentEntry] = useState(10)
    const [endActiveAgencyEntry, setEndActiveAgencyEntry] = useState(10)
    const [endInActiveAgentEntry, setEndInActiveAgentEntry] = useState(10)
    const [endInActiveAgencyEntry, setEndInActiveAgencyEntry] = useState(10)

    const [activeAgents, setActiveAgents] = useState([])
    const [activeAgencyUsers, setActiveAgencyUsers] = useState([])
    const [inActiveAgents, setInActiveAgents] = useState([])
    const [inActiveAgencyUsers, setInActiveAgencyUsers] = useState([])

    const [searchActiveAgentString, setSearchActiveAgentString] = useState('')
    const [searchActiveAgencyString, setSearchActiveAgencyString] = useState('')
    const { t } = useTranslation()

    // eslint-disable-next-line
    let limit = 5
    const ACTIVE_AGENTS_MANAGEMENT_COLUMNS = [
        {
            id: 'reraNum',
            numeric: false,
            disablePadding: true,
            label: `RERA No.`,
        },
        {
            id: 'employeeName',
            numeric: false,
            disablePadding: true,
            label: 'Employee Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: t('text_email'),
        },
        {
            id: 'phoneNumber',
            numeric: false,
            disablePadding: true,
            label: t('text_phone_number'),
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: true,
            label: t('text_role'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ]

    const ACTIVE_AGENCY_USERS_MANAGEMENT_COLUMNS = [
        {
            id: 'reraNum',
            numeric: false,
            disablePadding: true,
            label: `RERA No.`,
        },
        {
            id: 'employeeName',
            numeric: false,
            disablePadding: true,
            label: 'Employee Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: t('text_email'),
        },
        {
            id: 'phoneNumber',
            numeric: false,
            disablePadding: true,
            label: t('text_phone_number'),
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: true,
            label: t('text_role'),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: t('text_status'),
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: true,
            label: 'Actions',
        },
    ]

    const IN_ACTIVE_AGENTS_MANAGEMENT_COLUMNS = [
        {
            id: 'reraNum',
            numeric: false,
            disablePadding: true,
            label: `RERA No.`,
        },
        {
            id: 'employeeName',
            numeric: false,
            disablePadding: true,
            label: 'Employee Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: t('text_email'),
        },
        {
            id: 'phoneNumber',
            numeric: false,
            disablePadding: true,
            label: t('text_phone_number'),
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: true,
            label: t('text_role'),
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: true,
            label: 'Date',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
    ]

    const IN_ACTIVE_AGENCY_USERS_MANAGEMENT_COLUMNS = [
        {
            id: 'reraNum',
            numeric: false,
            disablePadding: true,
            label: `RERA No.`,
        },
        {
            id: 'employeeName',
            numeric: false,
            disablePadding: true,
            label: 'Employee Name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: true,
            label: t('text_email'),
        },
        {
            id: 'phoneNumber',
            numeric: false,
            disablePadding: true,
            label: t('text_phone_number'),
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: true,
            label: t('text_role'),
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: true,
            label: 'Date',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
    ]

    const openPreAddDialog = () => setIsPreAddDialogVisible(true)
    const closePreAddDialog = () => setIsPreAddDialogVisible(false)

    const openAddUserDialog = () => {
        closePreAddDialog()
        setIsAddUserDialogVisible(true)
    }

    const openAddMultiUserDialog = () => {
        closePreAddDialog()
        setIsAddMultiUserDialogVisible(true)
    }

    const closeAddUserDialog = () => {
        setIsAddUserDialogVisible(false)
        setIsPreAddDialogVisible(true)
    }

    const closeAddMultipleUserDialog = () => {
        setIsAddMultiUserDialogVisible(false)
        setIsPreAddDialogVisible(true)
    }

    const goHome = () => {
        setIsAddUserDialogVisible(false)
        setIsPreAddDialogVisible(false)
        setIsSuccessDialogVisible(false)
        window.location.reload()
    }

    const handleActiveAgentPage = (pageNumber: number) =>
        setActiveAgentPage(pageNumber)
    const handleActiveAgencyPage = (pageNumber: number) =>
        setActiveAgencyPage(pageNumber)
    const handleInActiveAgentPage = (pageNumber: number) =>
        setInActiveAgentPage(pageNumber)
    const handleInActiveAgencyPage = (pageNumber: number) =>
        setInActiveAgencyPage(pageNumber)

    const handleSelection = (ids: any) => {
        setSelectedIds(ids)
    }

    const debounceActiveAgentSearchString = useCallback(
        debounce(setSearchActiveAgentString, 400),
        []
    )
    const debounceActiveAgencyUserSearchString = useCallback(
        debounce(setSearchActiveAgencyString, 400),
        []
    )

    const fetchActiveAgents = async (auth: AuthState) => {
        if (auth.user && pathname.startsWith('/agency')) {
            setActiveAgentLoading(true)
            UserManagementServices.getAllActiveAgentUsers(
                auth.user.agencyId,
                activeAgentpage,
                limit,
                auth?.token
            ).then((response: any) => {
                console.log(response)

                setActiveAgents(response?.data?.rows)
                setTotalActiveAgentsCount(response?.data.count)
                setStartActiveAgentEntry(
                    activeAgentpage === 0 ? 1 : activeAgentpage * limit
                )
                setEndActiveAgentEntry(
                    (activeAgentpage + 1) * limit > response?.data.count
                        ? response.data.count
                        : (activeAgentpage + 1) * limit
                )
                setActiveAgentLoading(false)
            })

            setActiveAgentLoading(false)
        }
    }

    const fetchInActiveAgents = async (auth: AuthState) => {
        if (auth.user && pathname.startsWith('/agency')) {
            setInActiveAgentLoading(true)
            UserManagementServices.getAllInActiveAgentUsers(
                auth.user.agencyId,
                inActiveAgentpage,
                limit,
                auth?.token
            ).then((response: any) => {
                console.log(response)

                setInActiveAgents(response?.data?.rows)
                setTotalInActiveAgentsCount(response?.data.count)
                setStartInActiveAgentEntry(
                    inActiveAgentpage === 0 ? 1 : inActiveAgentpage * limit
                )
                setEndInActiveAgentEntry(
                    (inActiveAgentpage + 1) * limit > response?.data.count
                        ? response.data.count
                        : (inActiveAgentpage + 1) * limit
                )
                setInActiveAgentLoading(false)
            })

            setInActiveAgentLoading(false)
        }
    }

    const fetchActiveAgencyUsers = async (auth: AuthState) => {
        if (auth.user && pathname.startsWith('/agency')) {
            setActiveAgencyUserLoading(true)
            UserManagementServices.getAllActiveAgencyUsers(
                auth.user.agencyId,
                activeAgencypage,
                limit,
                auth?.token
            ).then((response: any) => {
                console.log(response)

                setActiveAgencyUsers(response?.data?.rows)
                setTotalActiveAgencyUsersCount(response?.data.count)
                setStartActiveAgencyEntry(
                    activeAgencypage === 0 ? 1 : activeAgencypage * limit
                )
                setEndActiveAgencyEntry(
                    (activeAgencypage + 1) * limit > response?.data.count
                        ? response.data.count
                        : (activeAgencypage + 1) * limit
                )
                setActiveAgencyUserLoading(false)
            })

            setActiveAgencyUserLoading(false)
        }
    }

    const fetchInActiveAgencyUsers = async (auth: AuthState) => {
        if (auth.user && pathname.startsWith('/agency')) {
            setInActiveAgencyUserLoading(true)
            UserManagementServices.getAllInActiveAgencyUsers(
                auth.user.agencyId,
                inActiveAgencypage,
                limit,
                auth?.token
            ).then((response: any) => {
                console.log(response)

                setInActiveAgencyUsers(response?.data?.rows)
                setTotalInActiveAgencyUsersCount(response?.data.count)
                setStartInActiveAgencyEntry(
                    inActiveAgencypage === 0 ? 1 : inActiveAgencypage * limit
                )
                setEndInActiveAgencyEntry(
                    (inActiveAgencypage + 1) * limit > response?.data.count
                        ? response.data.count
                        : (inActiveAgencypage + 1) * limit
                )
                setInActiveAgencyUserLoading(false)
            })

            setInActiveAgencyUserLoading(false)
        }
    }

    const successCallback = async () => {
        closeAddUserDialog()
        setIsSuccessDialogVisible(true)
    }

    const multipleUserSuccessCallback = async () => {
        closeAddMultipleUserDialog()
        setIsSuccessDialogVisible(true)
    }

    const handleFileUpload = async (file: any) => {
        closePreAddDialog()
        setIsFileUploaderVisible(true)

        try {
            // setLoading(true);
            const response = await UserManagementServices.addMultipleUsers(
                file,
                auth?.user?.agencyId,
                auth?.token
            )
            if (response.status) {
                Alert.success('Users added successfully')
                // setLoading(false);
                setIsFileUploaderVisible(false)
                // setLoading(false);
            } else {
                // setLoading(false);
                setIsFileUploaderVisible(false)
                setIsSuccessDialogVisible(false)
                Alert.error('Something went wrong')
            }
        } catch (err) {
            setIsFileUploaderVisible(false)
            // setLoading(false);
            setIsSuccessDialogVisible(false)
            Alert.error('Something went wrong')
        }
    }

    useEffect(() => {
        if (inActiveAgentpage) {
            fetchInActiveAgents(auth).catch((error) => console.error(error))
        } else {
            fetchInActiveAgents(auth).catch((error) => console.error(error))
        }
        // eslint-disable-next-line
    }, [inActiveAgentpage])

    useEffect(() => {
        if (inActiveAgencypage) {
            fetchInActiveAgencyUsers(auth).catch((error) =>
                console.error(error)
            )
        } else {
            fetchInActiveAgencyUsers(auth).catch((error) =>
                console.error(error)
            )
        }
        // eslint-disable-next-line
    }, [inActiveAgencypage])

    useEffect(() => {
        if (searchActiveAgentString === '') {
            setActiveAgentLoading(true)
            fetchActiveAgents(auth).catch((error) => console.error(error))
        } else {
            (async () => {
                setActiveAgentLoading(true)
                try {
                    if (auth.user && pathname.startsWith('/agency')) {
                        await UserManagementServices.fetchActiveAgentsForAgency(
                            auth.user.agencyId,
                            activeAgentpage,
                            limit,
                            searchActiveAgentString,
                            auth?.token
                        ).then((response: any) => {
                            console.log(response)

                            setActiveAgents(response?.data?.rows)
                            setTotalActiveAgentsCount(response?.data.count)
                            setStartActiveAgentEntry(
                                activeAgentpage === 0
                                    ? 1
                                    : activeAgentpage * limit
                            )
                            setEndActiveAgentEntry(
                                (activeAgentpage + 1) * limit >
                                    response?.data.count
                                    ? response.data.count
                                    : (activeAgentpage + 1) * limit
                            )
                        })

                        setActiveAgentLoading(false)
                    }
                } catch (e) {
                    setActiveAgentLoading(false)
                }
            })()
        }
        // eslint-disable-next-line
    }, [searchActiveAgentString, activeAgentpage])

    useEffect(() => {
        if (searchActiveAgencyString === '') {
            setActiveAgencyUserLoading(true)
            fetchActiveAgencyUsers(auth).catch((error) => console.error(error))
        } else {
            (async () => {
                setActiveAgencyUserLoading(true)
                try {
                    if (auth.user && pathname.startsWith('/agency')) {
                        await UserManagementServices.fetchActiveAgencyUsersForAgency(
                            auth.user.agencyId,
                            activeAgencypage,
                            limit,
                            searchActiveAgencyString,
                            auth?.token
                        ).then((response: any) => {
                            console.log(response)

                            setActiveAgencyUsers(response?.data?.rows)
                            setTotalActiveAgencyUsersCount(response?.data.count)
                            setStartActiveAgencyEntry(
                                activeAgencypage === 0
                                    ? 1
                                    : activeAgencypage * limit
                            )
                            setEndActiveAgencyEntry(
                                (activeAgencypage + 1) * limit >
                                    response?.data.count
                                    ? response.data.count
                                    : (activeAgencypage + 1) * limit
                            )
                        })

                        setActiveAgencyUserLoading(false)
                    }
                } catch (e) {
                    setActiveAgencyUserLoading(false)
                }
            })()
        }
        // eslint-disable-next-line
    }, [searchActiveAgencyString, activeAgencypage])

    const handleActiveAgentsSearching = (value: any) => {
        setActiveAgentPage(0)
        setTotalActiveAgentsCount(null)
        debounceActiveAgentSearchString(value)
    }

    const handleActiveAgencyUsersSearching = (value: any) => {
        setActiveAgencyPage(0)
        setTotalActiveAgencyUsersCount(null)
        debounceActiveAgencyUserSearchString(value)
    }

    return (
        <div className="user-management-page">
            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                Agents
            </Typography>

            <div className="user-management-container">
                <TableHeader
                    handleSearching={handleActiveAgentsSearching}
                    selectedIds={selectedIds}
                    handleAdd={openPreAddDialog}
                />

                {activeAgentLoading ? (
                    <div className={'user-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        Fetching Active Agents Users...
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={activeAgentpage}
                        handlePage={handleActiveAgentPage}
                        totalCount={totalActiveAgentsCount}
                        tableFor="activeAgentsTable"
                        data={activeAgents}
                        columns={ACTIVE_AGENTS_MANAGEMENT_COLUMNS}
                        startEntry={startActiveAgentEntry}
                        endEntry={endActiveAgentEntry}
                        customLimit={limit}
                    />
                )}
            </div>

            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                Agency Users
            </Typography>

            <div className="user-management-container">
                <AgencyUserTableHeader
                    handleSearching={handleActiveAgencyUsersSearching}
                />

                {activeAgencyUserLoading ? (
                    <div className={'user-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        Fetching Active Agency Users...
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={activeAgencypage}
                        handlePage={handleActiveAgencyPage}
                        totalCount={totalActiveAgencyUsersCount}
                        tableFor="activeAgencyUsersTable"
                        data={activeAgencyUsers}
                        columns={ACTIVE_AGENCY_USERS_MANAGEMENT_COLUMNS}
                        startEntry={startActiveAgencyEntry}
                        endEntry={endActiveAgencyEntry}
                        customLimit={limit}
                    />
                )}
            </div>

            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                Deleted Agents
            </Typography>

            <div className="user-management-container">
                {inActiveAgentLoading ? (
                    <div className={'user-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        Fetching InActive Agents...
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={inActiveAgentpage}
                        handlePage={handleInActiveAgentPage}
                        totalCount={totalInActiveAgentsCount}
                        tableFor="inActiveAgentsTable"
                        data={inActiveAgents}
                        columns={IN_ACTIVE_AGENTS_MANAGEMENT_COLUMNS}
                        startEntry={startInActiveAgentEntry}
                        endEntry={endInActiveAgentEntry}
                        customLimit={limit}
                    />
                )}
            </div>

            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 20,
                }}
            >
                Deleted Agency Users
            </Typography>

            <div className="user-management-container">
                {inActiveAgencyUserLoading ? (
                    <div className={'user-loader'}>
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        Fetching InActive Agency Users...
                    </div>
                ) : (
                    <ManagementTable
                        handleSelection={handleSelection}
                        pageNumber={inActiveAgencypage}
                        handlePage={handleInActiveAgencyPage}
                        totalCount={totalInActiveAgencyUsersCount}
                        tableFor="inActiveAgencyUsersTable"
                        data={inActiveAgencyUsers}
                        columns={IN_ACTIVE_AGENCY_USERS_MANAGEMENT_COLUMNS}
                        startEntry={startInActiveAgencyEntry}
                        endEntry={endInActiveAgencyEntry}
                        customLimit={limit}
                    />
                )}
            </div>

            <PreAddDialog
                title={t('text_add_user')}
                singleTitle={'Single'}
                multipleTitle={'Bulk'}
                handleClose={closePreAddDialog}
                open={isPreAddDialogVisible}
                addUser={openAddUserDialog}
                addMultiUser={openAddMultiUserDialog}
                addButtonTitle={''}
                backbut={true}
            />

            {isAddMultiUserDialogVisible && (
                <AddMultiUserDialog
                    title={'Bulk Upload'}
                    multipleTitle={'Select File'}
                    handleClose={closeAddMultipleUserDialog}
                    open={isAddMultiUserDialogVisible}
                    successCallback={multipleUserSuccessCallback}
                    addButtonTitle={'Upload'}
                    handleFileUpload={handleFileUpload}
                    backbut={true}
                />
            )}

            {isAddUserDialogVisible && (
                <AddUserDialog
                    title={t('text_add_user')}
                    subHeading="Official Details"
                    handleClose={closeAddUserDialog}
                    open={isAddUserDialogVisible}
                    successCallback={successCallback}
                    showCircularProgressBar={true}
                    isEditUser={false}
                />
            )}

            {isSuccessDialogVisible && (
                <OnSuccessDialog
                    handleClose={closeAddUserDialog}
                    open={isSuccessDialogVisible}
                    goHome={goHome}
                    successMessage={'Added User Successfully'}
                />
            )}

            <FileUploader open={isFileUploaderVisible} loading={false} />
        </div>
    )
}
