import { Box, Typography } from '@mui/material'

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box
                paddingLeft={8}
                paddingRight={8}
                paddingBottom={2}
                paddingTop={2}
                width="100%"
                maxWidth={'40vw'}
                margin="40px auto"
                style={{
                    backgroundColor: 'white',
                    borderRadius: '33px',
                    boxShadow: '0px 3px 6px #00000029',
                }}
            >
                {children}
            </Box>
        </Typography>
    )
}

export default TabPanel
