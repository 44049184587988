import { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
// components
import { PageNotFound } from '../pages/404'
import Login from '../pages/auth/login'
// constants, contexts and utils
import {
    RESET_PASSWORD_AGENT_ROUTE,
    VERIFY_OTP_AGENT_ROUTE,
    RESET_PASSWORD_AGENCY_ROUTE,
    VERIFY_OTP_AGENCY_ROUTE,
    AGENCY_REPORTS_ROUTE,
    USERS_ROUTE,
    AGENCY_DASHBOARD_ROUTE,
    TRANSACTIONS_AGENCY_ROUTE,
    AGENCY_ACCOUNTS_ROUTE,
    AGENCY_DETAIL_USER_ROUTE,
    AGENCY_SETTINGS_ROUTE,
    AGENCY_SUPPORT_ROUTE,
    AGENT_DASHBOARD_ROUTE,
    AGENT_INVOICES_ROUTE,
    AGENT_PAYMENT_LINKS_ROUTE,
    AGENT_SETTINGS_ROUTE,
    AGENT_SUPPORT_ROUTE,
    AGENT_TRANSACTIONS_ROUTE,
    AGENT_CREATE_QR_CODE_ROUTE,
    SETTINGS_AGENCY_ROUTE,
    SUPPORT_AGENCY_ROUTE,
    AGENT_LOGIN_ROUTE,
    AGENT_CREATE_PAYMENT_LINK_ROUTE,
    AGENT_GENERATE_INVOICE_ROUTE,
    AGENT_EXTERNAL_PAYMENT_LINK_ROUTE,
    AGENCY_LOGIN_ROUTE,
    AGENCY_SIGNUP_ROUTE,
    FORGOT_PASSWORD_AGENCY_ROUTE,
    FORGOT_PASSWORD_AGENT_ROUTE,
    AGENT_PAYMENT_STATUS_ROUTE,
    AGENCY_DETAIL_TRANSACTION_ROUTE,
} from '../constants'
import CreateQRCode from '../pages/dashboard/home/createQRCode'
import PublicRoute from './PublicRoute'
import ResetPassword from '../pages/auth/resetPassword'
import ForgotPassword from '../pages/auth/forgotPassword'
import VerifyOtp from '../pages/auth/verifyOtp'
import Home from '../pages/dashboard/home'
// import SignUpComponent from '../components/signUp'
import PrivateRoute from './PrivateRoute'
import Users from '../pages/dashboard/users'
import Transactions from '../pages/dashboard/transactions'
import Accounts from '../pages/dashboard/accounts'
import Reports from '../pages/dashboard/reports'
import Support from '../pages/dashboard/support'
import Settings from '../pages/dashboard/settings'
import UserDetail from '../pages/dashboard/usersDetail'
import PaymentLinks from '../pages/dashboard/paymentLinks'
import Invoices from '../pages/dashboard/invoices'
import CreatePaymentLink from '../pages/dashboard/home/createPaymentLink'
import GenerateInvoice from '../pages/dashboard/home/generateInvoice'
import ExternalPaymentLink from '../pages/externalPaymentLink'
import PaymentStatus from '../pages/paymentStatus'
import TransactionDetail from '../pages/dashboard/transactions/transactionDetail'

const Routes: FC = (): JSX.Element => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={AGENCY_LOGIN_ROUTE} />
            </Route>

            {/* --------- AGENCY ROUTES --------- */}
            <PublicRoute path={AGENCY_LOGIN_ROUTE} component={Login} exact />
            {/* <PublicRoute
                path={AGENCY_SIGNUP_ROUTE}
                component={SignUpComponent}
                exact
            /> */}
            <PublicRoute
                path={FORGOT_PASSWORD_AGENCY_ROUTE}
                component={ForgotPassword}
                exact
            />
            <PublicRoute
                path={RESET_PASSWORD_AGENCY_ROUTE}
                component={ResetPassword}
                exact
            />
            <PublicRoute
                path={VERIFY_OTP_AGENCY_ROUTE}
                component={VerifyOtp}
                exact
            />

            <PublicRoute path={AGENT_LOGIN_ROUTE} component={Login} exact />

            <PrivateRoute path={USERS_ROUTE} component={Users} exact />
            <PrivateRoute
                path={AGENCY_DASHBOARD_ROUTE}
                component={Home}
                exact
            />
            <PrivateRoute
                path={AGENCY_ACCOUNTS_ROUTE}
                component={Accounts}
                exact
            />

            <PrivateRoute
                path={TRANSACTIONS_AGENCY_ROUTE}
                component={Transactions}
                exact
            />
            <PrivateRoute
                path={SETTINGS_AGENCY_ROUTE}
                component={Settings}
                exact
            />
            <PrivateRoute
                path={SUPPORT_AGENCY_ROUTE}
                component={Support}
                exact
            />
            <PrivateRoute
                path={AGENCY_DETAIL_USER_ROUTE}
                component={UserDetail}
                exact
            />

            <PrivateRoute
                path={AGENCY_DETAIL_TRANSACTION_ROUTE}
                component={TransactionDetail}
                exact
            />
            {/* <PrivateRoute
                path={AGENCY_REPORTS_ROUTE}
                component={Reports}
                exact
            /> */}

            <PrivateRoute
                path={AGENCY_DETAIL_USER_ROUTE}
                component={UserDetail}
                exact
            />

            <PrivateRoute
                path={AGENCY_SUPPORT_ROUTE}
                component={Support}
                exact
            />
            <PrivateRoute
                path={AGENCY_SETTINGS_ROUTE}
                component={Settings}
                exact
            />

            <PrivateRoute path={AGENT_DASHBOARD_ROUTE} component={Home} exact />
            <PrivateRoute
                path={AGENT_PAYMENT_LINKS_ROUTE}
                component={PaymentLinks}
                exact
            />
            <PrivateRoute
                path={AGENT_INVOICES_ROUTE}
                component={Invoices}
                exact
            />
            <PrivateRoute
                path={AGENT_TRANSACTIONS_ROUTE}
                component={Transactions}
                exact
            />
            <PrivateRoute
                path={AGENT_SUPPORT_ROUTE}
                component={Support}
                exact
            />
            <PrivateRoute
                path={AGENT_SETTINGS_ROUTE}
                component={Settings}
                exact
            />
            <PrivateRoute
                path={AGENT_CREATE_QR_CODE_ROUTE}
                component={CreateQRCode}
                exact
            />
            <PrivateRoute
                path={AGENT_CREATE_PAYMENT_LINK_ROUTE}
                component={CreatePaymentLink}
                exact
            />
            <PrivateRoute
                path={AGENT_GENERATE_INVOICE_ROUTE}
                component={GenerateInvoice}
                exact
            />
            {/* --------- AGENT ROUTES --------- */}
            <PublicRoute
                path={FORGOT_PASSWORD_AGENT_ROUTE}
                component={ForgotPassword}
                exact
            />
            <PublicRoute
                path={RESET_PASSWORD_AGENT_ROUTE}
                component={ResetPassword}
                exact
            />
            <PublicRoute
                path={VERIFY_OTP_AGENT_ROUTE}
                component={VerifyOtp}
                exact
            />
            <PublicRoute path={AGENT_LOGIN_ROUTE} component={Login} exact />

            <PublicRoute
                path={AGENT_EXTERNAL_PAYMENT_LINK_ROUTE}
                component={ExternalPaymentLink}
                exact
            />
            <PublicRoute
                path={AGENT_PAYMENT_STATUS_ROUTE}
                component={PaymentStatus}
                exact
            />
            <PrivateRoute path={AGENT_DASHBOARD_ROUTE} component={Home} exact />
            <PrivateRoute
                path={AGENT_PAYMENT_LINKS_ROUTE}
                component={PaymentLinks}
                exact
            />
            <PrivateRoute
                path={AGENT_INVOICES_ROUTE}
                component={Invoices}
                exact
            />
            <PrivateRoute
                path={AGENT_TRANSACTIONS_ROUTE}
                component={Transactions}
                exact
            />
            <PrivateRoute
                path={AGENT_SUPPORT_ROUTE}
                component={Support}
                exact
            />
            <PrivateRoute
                path={AGENT_SETTINGS_ROUTE}
                component={Settings}
                exact
            />

            {/* --------- OTHER ROUTES --------- */}
            <Route component={PageNotFound} />
            <Route path="*" component={Login} />
        </Switch>
    )
}

export default Routes
