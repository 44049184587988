// packages block
import Lottie from 'lottie-react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { QRCodeCanvas } from 'qrcode.react'
import { Download, Share } from '@mui/icons-material'
import { Box, Button, Typography, Grid } from '@mui/material'
// components block
import './style.css'
import { QrCodeContainer } from './qrContainer'
import backButtonIcon from '../../assets/images/back-button-icon.svg'
import { FormButton } from '../common/button'
import { RootState } from '../../redux/store'
import { TextFieldComponent } from '../common/textFeild'
import AgentPaymentLinks from '../../apis/agent/paymentLinks'
import { BLACK_EIGHT, BLUE_EIGHT, GREEN_THREE, WHITE } from '../../theme'
import scanQRCodeAnimation from '../../assets/scan-qr-code-animation.json'
import Alert from '../common/Alert'

export const CreateQRCodeComponent = (): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [subType, setSubType] = useState('myQRCode')
    const [isInputValue, setIsInputValue] = useState(false)
    const [isGenerateCustomQRCode, setIsGenerateCustomQRCode] = useState(false)
    const qrRef: any = useRef()
    const [paymentLink, setPaymentLink] = useState('')
    const [amount, setAmount] = useState('')
    const [isDefaultSate, setIsDefaultSate] = useState(true)

    const handleBack = () => {
        window.location.href = '/agent/dashboard'
    }

    const handleBackDefault = () => {
        setIsDefaultSate(true)
        setIsInputValue(false)
        setIsGenerateCustomQRCode(false)
    }

    const handleCreateCustomerQRCode = () => {
        setIsInputValue(true)
        setIsDefaultSate(false)
    }

    const handleGenerateCustomerQRCode = (
        amount: string,
        setPaymentLink: any
    ) => {
        if (auth.user && pathname.startsWith('/agent')) {
            if (!amount.length) {
                Alert.error('Amount cannot be empty')
                return
            }
            AgentPaymentLinks.createStandalonePaymentLink(
                auth.user.id,
                true,
                false,
                true,
                false,
                amount,
                '',
                auth?.token
            ).then((response: any) => {
                console.log(response, 'HE')
                setPaymentLink(
                    `${process.env.REACT_APP_FE_URL}/agent/payment-link/${response.data.url}`
                )
            })
        }
        setIsGenerateCustomQRCode(true)
        setIsInputValue(false)
        setIsDefaultSate(false)
    }

    const handleReCreateCustomerQRCode = () => {
        setAmount('')
        setIsInputValue(true)
        setIsGenerateCustomQRCode(false)
        setIsDefaultSate(false)
    }

    const handleCustomQRCodeTab = () => {
        setSubType('customQRCode')
        setIsGenerateCustomQRCode(false)
        setIsInputValue(false)
    }

    const downloadQRCode = () => {
        const canvas = document.getElementById('qrCode') as HTMLCanvasElement
        if (canvas) {
            const image = canvas
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream')
            const link = document.createElement('a')
            link.href = image
            link.download = `QRCode-${paymentLink}.png`
            link.click()
        }
    }

    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={paymentLink}
            size={215}
            bgColor="white"
            level={'H'}
        />
    )

    const shareQRCode = async () => {
        if (navigator.share && typeof File !== 'undefined') {
            try {
                const canvas = document.getElementById(
                    'qrCode'
                ) as HTMLCanvasElement
                if (canvas) {
                    const blob = await new Promise<Blob | null>((resolve) =>
                        canvas.toBlob(resolve, 'image/png')
                    )

                    if (blob) {
                        const file = new File(
                            [blob],
                            `QRCode-${paymentLink}.png`,
                            {
                                type: 'image/png',
                            }
                        )
                        const shareData = {
                            title: `QRCode-${paymentLink}`,
                            text: 'Share this QR code',
                            files: [file],
                        }

                        await navigator.share(shareData)
                    }
                }
            } catch (error) {
                console.error('Error sharing QR code:', error)
            }
        } else {
            console.error('Web Share API is not supported')
        }
    }

    return (
        <div className="">
            {isDefaultSate && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={handleBack}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            My QR
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 4,
                        }}
                    >
                        <Box className="mask-box">
                            <Box
                                className="mask"
                                style={{
                                    transform: `translateX(${
                                        subType === 'myQRCode' ? 0 : '150px'
                                    })`,
                                }}
                            />
                            <Button
                                disableRipple
                                variant="text"
                                sx={{
                                    color:
                                        subType === 'myQRCode'
                                            ? '#252422'
                                            : '#FBFBFF',
                                }}
                                onClick={() => setSubType('myQRCode')}
                            >
                                My QR
                            </Button>
                            <Button
                                disableRipple
                                variant="text"
                                sx={{
                                    color:
                                        subType === 'customQRCode'
                                            ? '#252422'
                                            : '#FBFBFF',
                                }}
                                onClick={handleCustomQRCodeTab}
                            >
                                Custom QR
                            </Button>
                        </Box>
                    </Box>

                    {subType === 'myQRCode' ? (
                        <Box
                            maxWidth={300}
                            mx="auto"
                            mt={'50px'}
                            className="qrcode-outer-container"
                        >
                            <QrCodeContainer />
                        </Box>
                    ) : (
                        <Box maxWidth={600} mx="auto" mt={'50px'}>
                            <div className="qrcode-custom-outer-container">
                                <Lottie
                                    animationData={scanQRCodeAnimation}
                                    style={{ width: 300, margin: '0 auto' }}
                                    loop={true}
                                    autoPlay={true}
                                />

                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: 4,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormButton
                                        width={'22vw'}
                                        height={45}
                                        borderColor={WHITE}
                                        titleColor={WHITE}
                                        hoverTitleColor={BLACK_EIGHT}
                                        hoverBorderColor={BLACK_EIGHT}
                                        backgroundColor={BLACK_EIGHT}
                                        hoverBackgroundColor={WHITE}
                                        borderTopRightRadius={15}
                                        borderTopLeftRadius={15}
                                        borderBottomLeftRadius={15}
                                        borderBottomRightRadius={15}
                                        title="Create Custom QR Code"
                                        onClickHandler={
                                            handleCreateCustomerQRCode
                                        }
                                        disabled={false}
                                        overRideButtonCheck={true}
                                    />
                                </div>
                            </div>
                        </Box>
                    )}
                </>
            )}

            {isInputValue && (
                <>
                    <Box display="flex" alignItems="center" mb={4}>
                        <Button
                            variant="contained"
                            onClick={handleBackDefault}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Create Custom QR Code
                        </Typography>
                    </Box>

                    <Typography variant="body1" color="#70707087">
                        Enter the amount that the customer has to pay. Note that
                        the amount will not be editable by the customer
                    </Typography>

                    <>
                        <Box maxWidth={600} mx="auto" mt={'50px'}>
                            <div className="qrcode-custom-outer-container">
                                <Lottie
                                    animationData={scanQRCodeAnimation}
                                    style={{ width: 300, margin: '0 auto' }}
                                    loop={true}
                                    autoPlay={true}
                                />

                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: 4,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Grid
                                        container
                                        item
                                        xs={10}
                                        sx={{ marginBottom: '20px' }}
                                    >
                                        <TextFieldComponent
                                            onChange={(e: any) =>
                                                setAmount(e.target.value)
                                            }
                                            value={amount}
                                            label="Enter Amount"
                                            name={'customAmount'}
                                            type="number"
                                        />
                                    </Grid>

                                    <FormButton
                                        width={'22vw'}
                                        height={45}
                                        borderColor={WHITE}
                                        titleColor={WHITE}
                                        hoverTitleColor={BLACK_EIGHT}
                                        hoverBorderColor={BLACK_EIGHT}
                                        backgroundColor={BLACK_EIGHT}
                                        hoverBackgroundColor={WHITE}
                                        borderTopRightRadius={15}
                                        borderTopLeftRadius={15}
                                        borderBottomLeftRadius={15}
                                        borderBottomRightRadius={15}
                                        title="Generate QR"
                                        onClickHandler={() =>
                                            handleGenerateCustomerQRCode(
                                                amount,
                                                setPaymentLink
                                            )
                                        }
                                        disabled={false}
                                        overRideButtonCheck={true}
                                    />
                                </div>
                            </div>
                        </Box>
                    </>
                </>
            )}

            {isGenerateCustomQRCode && (
                <>
                    <Box display="flex" alignItems="center" mb={4}>
                        <Button
                            variant="contained"
                            onClick={handleReCreateCustomerQRCode}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            My QR Code
                        </Typography>
                    </Box>

                    <Typography variant="body1" color="#70707087">
                        Enter the amount that the customer has to pay. Note that
                        the amount will not be editable by the customer
                    </Typography>

                    <Box maxWidth={600} mx="auto" mt={'50px'}>
                        <div
                            className="qrcode-custom-outer-container"
                            style={{ alignItems: 'center' }}
                        >
                            <Typography variant="h6" mb={'5px'} color="gray">
                                Amount
                            </Typography>
                            <Typography variant="h2" mt={'0px'} mb={'24px'}>
                                AED {amount}
                            </Typography>

                            <div
                                ref={qrRef}
                                className="qr-code-content-container"
                            >
                                {qrcode}
                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 4,
                                    flexDirection: 'column',
                                    marginTop: '60px',
                                    rowGap: '10px',
                                }}
                            >
                                <FormButton
                                    startIcon={
                                        <Download
                                            style={{ color: 'white' }}
                                            color={'secondary'}
                                        />
                                    }
                                    width={'22vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={GREEN_THREE}
                                    hoverBorderColor={GREEN_THREE}
                                    backgroundColor={GREEN_THREE}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Download QR'}
                                    onClickHandler={() => downloadQRCode()}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />

                                <FormButton
                                    startIcon={
                                        <Share
                                            style={{ color: 'white' }}
                                            color={'secondary'}
                                        />
                                    }
                                    width={'22vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLUE_EIGHT}
                                    hoverBorderColor={BLUE_EIGHT}
                                    backgroundColor={BLUE_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Share QR'}
                                    onClickHandler={() => shareQRCode()}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />

                                <FormButton
                                    width={'22vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLACK_EIGHT}
                                    hoverBorderColor={BLACK_EIGHT}
                                    backgroundColor={BLACK_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Create Another QR Code'}
                                    onClickHandler={
                                        handleReCreateCustomerQRCode
                                    }
                                    disabled={false}
                                    overRideButtonCheck={true}
                                />
                            </div>
                        </div>
                    </Box>
                </>
            )}
        </div>
    )
}
