import {
    CONFIRM_YOUR_PASSWORD,
    EMAIL,
    FIRST_NAME,
    INVALID_EMAIL,
    LAST_NAME,
    NAME,
    OTP_LABEL,
    PASSWORD,
    PASSWORDS_MUST_MATCH,
    PASSWORD_LABEL,
    RERA_NO,
} from '../constants'
import { capitalize } from '@mui/material/utils'
import * as yup from 'yup'

export const requiredMessage = (fieldName: string) =>
    `${capitalize(fieldName)} is required`
export const passwordSchema = {
    password: yup.string().required(requiredMessage(PASSWORD_LABEL)),
}

const verifyOtpSchema = {
    verifyOtp: yup.string().required(requiredMessage(OTP_LABEL)),
}

const emailSchema = {
    email: yup.string().email(INVALID_EMAIL).required(requiredMessage(EMAIL)),
}
export const loginValidationSchema = yup.object({
    ...emailSchema,
    ...passwordSchema,
})
export const emailValidationSchema = yup.object({
    ...emailSchema,
})
export const passwordValidationSchema = yup.object({
    ...passwordSchema,
})
export const otpValidationSchema = yup.object({
    ...verifyOtpSchema,
})

const passwordAndRepeatPasswordSchema = {
    password: yup.string().required(requiredMessage(PASSWORD)),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], PASSWORDS_MUST_MATCH)
        .required(CONFIRM_YOUR_PASSWORD),
}

export const resetPasswordValidationSchema = yup.object({
    ...passwordAndRepeatPasswordSchema,
})

export const ALPHABETS_REGEX = /^[^\s].([A-Za-z]+\s)*[A-Za-z]+$/

export const MaxLength = (fieldName: string, length: number) =>
    `${fieldName} can be up to ${length} characters long`

export const MinLength = (fieldName: string, length: number) =>
    `${fieldName} should be at least ${length} characters long`

export const ValidMessage = (fieldName: string, Example?: string) =>
    `Please enter valid ${fieldName.toLowerCase()}`

const nameSchema = (label: string) => {
    return yup
        .string()
        .matches(ALPHABETS_REGEX, ValidMessage(label))
        .min(2, MinLength(label, 2))
        .max(26, MaxLength(label, 26))
        .required(requiredMessage(label))
}

const firstLastNameSchema = {
    lastName: nameSchema(LAST_NAME),
    firstName: nameSchema(FIRST_NAME),
}

const fullNameSchema = {
    name: nameSchema(NAME),
}

const reraNumberSchema = {
    reraNumber: yup.string().required(requiredMessage(RERA_NO)),
}

export const userValidationSchema = yup.object({
    ...fullNameSchema,
    ...reraNumberSchema,
    ...passwordSchema,
})
