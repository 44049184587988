// packages block
import { FC } from 'react'
import { Box, Typography, Button, Container } from '@mui/material'
// styles, context, history
import { useNetworkStyles } from '../../styles/networkStyles'
import {
    BACK_TO_HOME,
    FOUR_O_FOUR,
    LOGIN_ROUTE,
    NOTHING_HERE_TEXT,
} from '../../constants'
import { NotificationAdd } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { PageNotFoundType } from '../../interfacesTypes'

const PageNotFound: FC<PageNotFoundType> = ({
    isCustomRoute = false,
    customRoute = LOGIN_ROUTE,
}): JSX.Element => {
    const classes = useNetworkStyles()

    return (
        <Box className={classes.root}>
            <Container maxWidth="md">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.textContainer}
                >
                    <Typography
                        component="h1"
                        variant="h1"
                        className={classes.pageNotFoundText}
                        align="center"
                    >
                        {FOUR_O_FOUR}
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        position="absolute"
                    >
                        <NotificationAdd />

                        <Box maxWidth={500} pt={8} pb={2}>
                            <Typography variant="body2" align="center">
                                {NOTHING_HERE_TEXT}
                            </Typography>
                        </Box>
                        <Link
                            to={isCustomRoute ? customRoute : LOGIN_ROUTE}
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                className={classes.link}
                            >
                                {BACK_TO_HOME}
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default PageNotFound
