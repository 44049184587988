// packages block
import { useState } from 'react'
import ReactDropzone from 'react-dropzone'
import { Button, Dialog, Typography } from '@mui/material'
// common block
import '../../users/style.css'
import { FormButton } from '../../common/button'
import { TextFieldComponent } from '../../common/textFeild'
// constants, theme, utils and api block
//import csvStd from "../../assets/csv/std.csv";
import { BLACK_EIGHT, WHITE } from '../../../theme'
import { preaddStyles } from '../../../styles/preAddStyles'
import backButtonIcon from '../../../assets/images/back-button-icon.svg'

export const AddMultiUserDialog = ({
    open,
    handleClose,
    title,
    multipleTitle,
    backbut = false,
    addButtonTitle = 'Add',
    handleFileUpload = () => null,
    successCallback,
}: any) => {
    const classes = preaddStyles()
    const [file, setFile] = useState(null as any)

    const uploadFile = (acceptedFiles: string | any[]) => {
        const fileSize = acceptedFiles[0].size / 1024 / 1024
        if (fileSize < 10 && acceptedFiles.length > 0) setFile(acceptedFiles[0])
    }

    const handleUpload = async () => {
        successCallback()
        if (file.type === 'application/vnd.ms-excel') {
            handleFileUpload(file)
        } else {
            handleFileUpload(file)
        }
    }

    return (
        <Dialog
            sx={{ maxWidth: '370px', margin: 'auto' }}
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className={classes.mainContainer}>
                <div
                    style={{
                        alignItems: 'center',
                        position: 'relative',
                        padding: 10,
                    }}
                >
                    <Button
                        variant={'contained'}
                        onClick={handleClose}
                        style={{
                            backgroundColor: 'transparent',
                            maxWidth: '30px',
                            maxHeight: '30px',
                            minWidth: '30px',
                            minHeight: '30px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            position: 'absolute',
                            left: '20px',
                            top: '20px',
                        }}
                    >
                        <img
                            src={backButtonIcon}
                            className="backbut"
                            style={{ width: 48, cursor: 'pointer' }}
                            alt="back-button-icon"
                        />
                    </Button>

                    <Typography
                        display="flex"
                        variant="h3"
                        style={{
                            color: 'black',
                            width: '100%',
                            textAlign: 'center',
                            marginTop: '10px',
                            justifyContent: 'center',
                        }}
                    >
                        {title ?? ''}
                    </Typography>
                </div>
                <div style={{ display: 'flex', flex: 1 }}>
                    <PreDialogOption
                        style={{ cursor: 'pointer', width: '100%' }}
                    >
                        <div className={classes.csvDropZoneContainer}>
                            <ReactDropzone onDrop={uploadFile} multiple={false}>
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ marginBottom: 10 }}>
                                        <div {...getRootProps()}>
                                            <input
                                                {...getInputProps()}
                                                accept=".csv"
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    className={
                                                        classes.csvDetail
                                                    }
                                                >
                                                    {!file && (
                                                        <>
                                                            <TextFieldComponent
                                                                onChange={() =>
                                                                    null
                                                                }
                                                                label="Upload Csv File"
                                                                name={''}
                                                            />
                                                            <div
                                                                style={{
                                                                    marginLeft: 10,
                                                                    marginTop: 3,
                                                                }}
                                                            >
                                                                <FormButton
                                                                    title={
                                                                        multipleTitle
                                                                    }
                                                                    height={40}
                                                                    padding="2px 10px"
                                                                    width={
                                                                        '7vw'
                                                                    }
                                                                    borderColor={
                                                                        BLACK_EIGHT
                                                                    }
                                                                    titleColor={
                                                                        WHITE
                                                                    }
                                                                    hoverTitleColor={
                                                                        BLACK_EIGHT
                                                                    }
                                                                    hoverBorderColor={
                                                                        BLACK_EIGHT
                                                                    }
                                                                    backgroundColor={
                                                                        BLACK_EIGHT
                                                                    }
                                                                    hoverBackgroundColor={
                                                                        WHITE
                                                                    }
                                                                    onClickHandler={
                                                                        uploadFile
                                                                    }
                                                                    borderTopRightRadius={
                                                                        10
                                                                    }
                                                                    borderTopLeftRadius={
                                                                        10
                                                                    }
                                                                    borderBottomLeftRadius={
                                                                        10
                                                                    }
                                                                    borderBottomRightRadius={
                                                                        10
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </ReactDropzone>

                            <FormButton
                                title={addButtonTitle}
                                height={45}
                                width={'15vw'}
                                borderColor={BLACK_EIGHT}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                borderTopRightRadius={15}
                                borderTopLeftRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                onClickHandler={handleUpload}
                            />
                        </div>
                    </PreDialogOption>
                </div>
            </div>
            <Typography
                variant="body2"
                display="flex"
                justifyContent="center"
                mb={4}
                fontWeight={700}
            >
                <a href="" style={{ color: BLACK_EIGHT }}>
                    Download{' '}
                </a>
                &nbsp;Our Pre Defined Template
            </Typography>
        </Dialog>
    )
}

const PreDialogOption = ({ style, title, children, onClick }: any) => {
    const classes = preaddStyles()
    return (
        <div style={style} onClick={onClick}>
            <Typography className={classes.predialogTitle} variant="h6">
                {title}
            </Typography>

            <div className={classes.predialogChildern}>{children}</div>
        </div>
    )
}
