import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ElementType } from 'react'
import { GRAY_FIFTEEN } from '../../../theme'
export const FormButton = ({
    disabled,
    onClickHandler,
    title,
    startIcon,
    width,
    hoverBackgroundColor,
    backgroundColor,
    titleColor,
    hoverTitleColor = 'white',
    borderTopRightRadius = 4,
    borderTopLeftRadius = 4,
    borderBottomLeftRadius = 4,
    borderBottomRightRadius = 4,
    height = 40,
    loading = false,
    style,
    type,
    borderColor,
    padding,
    hoverBorderColor,
    noMargin,
}: any) => {
    const useStyles = makeStyles((theme) => ({
        buttonBackground: {
            width: width,
            color: titleColor,
            backgroundColor: backgroundColor,
            border: `1px solid ${borderColor}`,
            borderTopRightRadius: borderTopRightRadius,
            borderTopLeftRadius: borderTopLeftRadius,
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
            height: height,
            padding: padding,

            '&:hover': {
                backgroundColor: hoverBackgroundColor,
                border: `1px solid ${hoverBorderColor}`,
                color: hoverTitleColor,
                cursor: 'pointer',
            },

            '&:disabled': {
                backgroundColor: GRAY_FIFTEEN,
                cursor: 'no-drop',
            },
        },
        circularProgress: {
            // color: loadingColor,
        },
    }))

    const classes = useStyles()
    return (
        <Typography
            width={{ xs: ` ${width} !important` }}
            component={'button' as ElementType}
            style={{ marginRight: noMargin ? 0 : 10 }}
            disabled={disabled}
            className={classes.buttonBackground}
            onClick={
                onClickHandler
                    ? () => !loading && onClickHandler()
                    : () => console.log('No onclick handler')
            }
            variant="button"
            type={type}
            startIcon={startIcon}
        >
            {loading ? (
                <CircularProgress
                    size={20}
                    className={classes.circularProgress}
                />
            ) : (
                <span style={style}>
                    {startIcon && startIcon}
                    {title}
                </span>
            )}
        </Typography>
    )
}
