import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

export const RadioButton = ({
    radioBtnValue,
    radioBtnData,
    handleRadioBtnChange,
}: any) => {
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioBtnValue}
                onChange={handleRadioBtnChange}
            >
                {radioBtnData.map(({ value, label }: any) => {
                    return (
                        <FormControlLabel
                            value={value}
                            control={
                                <Radio
                                    color="success"
                                    style={{ marginRight: 10 }}
                                />
                            }
                            label={label}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}
