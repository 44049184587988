// packages block
import Lottie from 'lottie-react'
import { Dialog, Typography } from '@mui/material'
// common block
import '../users/style.css'
import { PreDialogHeader } from './preDialogHeader'
// constants, theme, utils and api block
import { preaddStyles } from '../../styles/preAddStyles'
import addUserIconAnimation from '../../assets/single-user-icon-animation.json'
import addMultiUserIconAnimation from '../../assets/multi-users-icon-animation.json'

export const PreAddDialog = ({
    open,
    handleClose,
    title,
    multipleTitle,
    addUser,
    backbut = false,
    singleTitle = 'Add Single',
    addMultiUser,
}: any) => {
    const classes = preaddStyles()

    const close = () => {
        handleClose()
    }

    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className={classes.mainContainer}>
                <PreDialogHeader
                    title={title}
                    handleClose={close}
                    backbut={backbut}
                />
                <div className={classes.dropContainer}>
                    <PreDialogOption
                        onClick={addUser}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className={classes.singleDropZoneContainer}>
                            <Lottie
                                animationData={addUserIconAnimation}
                                style={{ width: 150 }}
                                loop={true}
                            />
                        </div>

                        <Typography
                            className={classes.predialogTitle}
                            variant="h6"
                        >
                            {singleTitle}
                        </Typography>
                    </PreDialogOption>

                    <PreDialogOption
                        onClick={addMultiUser}
                        style={{ cursor: 'pointer' }}
                    >
                        <div className={classes.singleDropZoneContainer}>
                            <Lottie
                                animationData={addMultiUserIconAnimation}
                                style={{ width: 150 }}
                                loop={true}
                            />
                        </div>
                        <Typography
                            className={classes.predialogTitle}
                            variant="h6"
                        >
                            {multipleTitle}
                        </Typography>
                    </PreDialogOption>
                </div>
            </div>
        </Dialog>
    )
}

const PreDialogOption = ({ style, title, children, onClick }: any) => {
    const classes = preaddStyles()
    return (
        <div className={'dialog-component'} style={style} onClick={onClick}>
            <Typography className={classes.predialogTitle} variant="h6">
                {title}
            </Typography>

            <div className={classes.predialogChildern}>{children}</div>
        </div>
    )
}
