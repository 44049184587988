import { ChangeEvent, useEffect, useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './setting.css'
import { styled } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import {
    IconButton,
    Tab,
    Tabs,
    Avatar,
    Box,
    CircularProgress,
    Grid,
    Typography,
} from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'
import TabPanel from '../common/tabPanel'
import { BLACK_EIGHT, GRAY_FOURTEEN, WHITE } from '../../theme'
import { FormButton } from '../common/button'
import { TextFieldComponent } from '../common/textFeild'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import AgencyProfileServices from '../../apis/agency/settings/profile'
import { authActions } from '../../redux/store/slices/auth'
import Alert from '../common/Alert'
import AgentProfileServices from '../../apis/agent/settings/profile'
import AgentService from '../../apis/agent/users'

const debug = process.env.REACT_APP_DEBUG

const Input = styled('input')({
    display: 'none',
})

export const SettingsComponent = () => {
    const { auth } = useSelector((state: RootState) => state)

    const dispatch = useDispatch()
    const [userFirstName, userLastName] = String(auth?.user?.name).split(' ')
    console.log(auth?.user?.agencyId)
    const { t } = useTranslation()
    const [tabValue, setTabValue] = useState(0)
    const [
        {
            agencyName,
            agencyAddress,
            agencyEmail,
            agencyPhoneNumber,
            agencyCountryCode,
            agencyProfilePicture,
            agencyReraNo,
            agencyTradeLicenceNumber,
        },
        setAgencyData,
    ] = useState({
        agencyName: auth?.user?.agencyName ?? '',
        agencyAddress: auth?.user?.agencyAddress ?? '',
        agencyEmail: auth?.user?.agencyEmail ?? '',
        agencyPhoneNumber:
            `${auth?.user?.agencyCountryCode}${auth?.user?.agencyPhoneNumber}` ??
            '',
        agencyCountryCode: auth?.user?.agencyCountryCode ?? '',
        agencyProfilePicture: auth?.user?.agencyProfilePicture ?? '',
        agencyReraNo: auth?.user?.agencyReraNo ?? '',
        agencyTradeLicenceNumber: auth?.user?.agencyTradeLicenceNumber ?? '',
    })

    //* Use this state to store the userInfo data and also Update the state for update UserInfo
    const [
        {
            firstName,
            lastName,
            email,
            phoneNumber,
            countryCode,
            profilePicture,
        },
        setUserData,
    ] = useState({
        firstName: userFirstName,
        lastName: userLastName,
        email: auth?.user?.email ?? '',
        phoneNumber:
            `${auth?.user?.countryCode}${auth?.user?.phoneNumber}` ?? '',
        countryCode: auth?.user?.countryCode ?? '',
        profilePicture: auth?.user?.profilePicture ?? '',
    })
    const [loading, setLoading] = useState(false)
    const [base64, setBase64] = useState<string | null>(null)
    const [agencyBase64, setAgencyBase64] = useState<string | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [agencyImageFile, setAgencyImageFile] = useState<File | null>(null)

    const pathname = window.location.pathname

    //* using useReducer Hook to pass the initialState and save resetPassword values
    const [
        {
            oldPassword,
            newPassword,
            confirmPassword,
            oldPasswordError,
            newPasswordError,
            confirmPasswordError,
        },
        setPasswordData,
    ] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        oldPasswordError: '',
        newPasswordError: '',
        confirmPasswordError: '',
    })
    const uploadProfilePicture = async () => {
        if (imageFile && auth.user && pathname.startsWith('/agent')) {
            const formData = new FormData()
            formData.append('profilePicture', imageFile)
            formData.append('agentId', auth.user?.id as unknown as string)
            AgentProfileServices.updateProfilePicture(formData)
                .then((response) => {
                    if (response.status) {
                        dispatch(
                            authActions.updateProfilePicture({
                                profilePicture: response.data.profilePicture,
                            })
                        )
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (imageFile && auth.user && pathname.startsWith('/agency')) {
            const formData = new FormData()
            formData.append('profilePicture', imageFile)
            formData.append('agencyUserId', auth.user?.id as unknown as string)
            AgencyProfileServices.updateProfilePicture(formData)
                .then((response) => {
                    console.log(response, 'response')
                    if (response.status) {
                        dispatch(
                            authActions.updateProfilePicture({
                                profilePicture: response.data.profilePicture,
                            })
                        )
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const uploadAgencyProfilePicture = async () => {
        if (agencyImageFile && auth.user && pathname.startsWith('/agency')) {
            const formData = new FormData()
            formData.append('file', agencyImageFile)
            formData.append(
                'agencyId',
                auth.user?.agencyId as unknown as string
            )
            AgencyProfileServices.updateProfilePictureForAgency(formData)
                .then((response) => {
                    console.log(response, 'response')
                    if (response.status) {
                        dispatch(
                            authActions.updateProfilePictureForAgency({
                                agencyProfilePicture:
                                    response.data.profilePicture,
                            })
                        )
                        setAgencyData((prevState) => ({
                            ...prevState,
                            agencyProfilePicture: response.data.profilePicture,
                        }))
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    //*  for reset user password
    const handlePasswordReset = async () => {
        let isError = false
        setPasswordData((prevState) => ({
            ...prevState,
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
        }))
        if (oldPassword === '') {
            isError = true
            setPasswordData((prevState) => ({
                ...prevState,
                oldPasswordError: 'Field cannot be empty',
            }))
        }
        if (newPassword === '') {
            isError = true
            setPasswordData((prevState) => ({
                ...prevState,
                newPasswordError: 'Field cannot be empty',
            }))
        }
        if (confirmPassword === '') {
            isError = true
            setPasswordData((prevState) => ({
                ...prevState,
                confirmPasswordError: 'Field cannot be empty',
            }))
        }
        if (isError) return
        if (newPassword !== confirmPassword) {
            setPasswordData((prevState) => ({
                ...prevState,
                confirmPasswordError:
                    'Confirm password and new password do not match',
            }))
            return
        }
        if (auth.user && pathname.startsWith('/agency')) {
            setLoading(true)
            AgencyProfileServices.changePassword(
                auth.user.id,
                oldPassword,
                newPassword,
                auth?.token
            )
                .then((response) => {
                    if (!response.status) {
                        Alert.error(response.statusMsg)
                        setLoading(false)
                        return
                    }
                    setPasswordData((prevState) => ({
                        ...prevState,
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }))
                    Alert.success(response.statusMsg)
                    setLoading(false)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not update user. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                    setLoading(false)
                })
        }
        if (auth.user && pathname.startsWith('/agent')) {
            setLoading(true)
            AgentProfileServices.changePassword(
                auth.user.id,
                oldPassword,
                newPassword,
                auth?.token
            )
                .then((response) => {
                    if (!response.status) {
                        Alert.error(response.statusMsg)
                        setLoading(false)
                        return
                    }
                    setPasswordData((prevState) => ({
                        ...prevState,
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }))
                    Alert.success(response.statusMsg)
                    setLoading(false)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not update user. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                    setLoading(false)
                })
        }
    }

    const resetPasswordData = () => {
        setPasswordData({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
        })
    }

    //* for get the userInfo data values
    const handleChangePasswordFields = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setPasswordData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handleChangeUserFields = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleChangeAgencyFields = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setAgencyData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    let customMobileNumber: any
    let customAgencyMobileNumber: any

    const handleOnChange = (value: string, country: any) => {
        setUserData((prevState) => ({
            ...prevState,
            phoneNumber: value.replace(/\D/g, ''),
            countryCode: country.dialCode,
        }))
    }

    const handleAgencyPhoneNumberOnChange = (value: string, country: any) => {
        setAgencyData((prevState) => ({
            ...prevState,
            agencyPhoneNumber: value.replace(/\D/g, ''),
            agencyCountryCode: country.dialCode,
        }))
    }

    const resetUserData = async () => {
        setUserData({
            firstName: userFirstName,
            lastName: userLastName,
            email: auth?.user?.email ?? '',
            phoneNumber: auth?.user?.phoneNumber ?? '',
            profilePicture: auth?.user?.profilePicture ?? '',
            countryCode: auth?.user?.countryCode ?? '',
        })
        setBase64(null)
    }

    const resetAgencyData = async () => {
        setAgencyData({
            agencyName: auth?.user?.agencyName ?? '',
            agencyAddress: auth?.user?.agencyAddress ?? '',
            agencyEmail: auth?.user?.agencyEmail ?? '',
            agencyPhoneNumber:
                `${auth?.user?.agencyCountryCode}${auth?.user?.agencyPhoneNumber}` ??
                '',
            agencyCountryCode: auth?.user?.agencyCountryCode ?? '',
            agencyProfilePicture: auth?.user?.agencyProfilePicture ?? '',
            agencyReraNo: auth?.user?.agencyReraNo ?? '',
            agencyTradeLicenceNumber:
                auth?.user?.agencyTradeLicenceNumber ?? '',
        })
        setAgencyBase64(null)
    }

    //* Update userInfo and save in state
    const updateUser = async () => {
        if (!firstName.length) {
            const err = {
                status: false,
                statusMsg: 'First Name cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!lastName.length) {
            const err = {
                status: false,
                statusMsg: 'Last Name cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!email.length) {
            const err = {
                status: false,
                statusMsg: 'Email cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        customMobileNumber = phoneNumber?.substring(
            countryCode?.length,
            phoneNumber?.length
        )

        setLoading(true)
        uploadProfilePicture()
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })

        if (auth.user && pathname.startsWith('/agency')) {
            AgencyProfileServices.updateProfile(
                auth?.user?.id,
                firstName,
                lastName,
                email,
                customMobileNumber,
                countryCode,
                auth?.token
            )
                .then((response) => {
                    if (!response.status) {
                        Alert.error(response.statusMsg)
                        setLoading(false)
                        return
                    }

                    dispatch(
                        authActions.updateUser({
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            phoneNumber: customMobileNumber,
                            countryCode: countryCode,
                        })
                    )
                    Alert.success(response.statusMsg)
                    setLoading(false)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not update user. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                    setLoading(false)
                })
        }
        if (auth.token && auth.user && pathname.startsWith('/agent')) {
            AgentProfileServices.updateProfile(
                auth.user.id,
                firstName,
                lastName,
                email,
                customMobileNumber,
                countryCode,
                auth.token
            )
                .then((response) => {
                    if (!response.status) {
                        Alert.error(response.statusMsg)
                        setLoading(false)
                        return
                    }

                    dispatch(
                        authActions.updateUser({
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            phoneNumber: customMobileNumber,
                            countryCode: countryCode,
                        })
                    )
                    Alert.success(response.statusMsg)
                    setLoading(false)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not update user. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                    setLoading(false)
                })
        }
    }

    const onGetAgencyFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            setAgencyImageFile(file)
            const reader = new FileReader()
            reader.onloadend = () => {
                setAgencyBase64(reader.result as string)
            }
            reader.readAsDataURL(file)
        }
    }

    //* when we have upload a ProfilePic
    const onGetFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            setImageFile(file)
            const reader = new FileReader()
            reader.onloadend = () => {
                setBase64(reader.result as string)
            }
            reader.readAsDataURL(file)
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }
    console.log('agencyPhoneNumber', agencyPhoneNumber)

    const updateAgency = async () => {
        if (!agencyName?.length) {
            const err = {
                status: false,
                statusMsg: 'Agency name cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!agencyAddress.length) {
            const err = {
                status: false,
                statusMsg: 'Agency address cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!agencyPhoneNumber) {
            const err = {
                status: false,
                statusMsg: 'Agency phone number cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!agencyEmail?.length) {
            const err = {
                status: false,
                statusMsg: 'Agency email cannot be empty',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }
        if (!agencyReraNo?.length && !agencyTradeLicenceNumber?.length) {
            const err = {
                status: false,
                statusMsg:
                    'At least one of Rera No. or Trade Licence Number must have a value',
            }
            Alert.error(err?.statusMsg)
            if (debug) console.log(err)
            return err
        }

        customAgencyMobileNumber = agencyPhoneNumber?.substring(
            agencyCountryCode?.length,
            agencyPhoneNumber?.length
        )

        setLoading(true)
        uploadAgencyProfilePicture()
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })

        if (auth?.user && pathname.startsWith('/agency')) {
            AgencyProfileServices.updateProfileForAgency(
                auth?.user?.agencyId,
                agencyName,
                agencyAddress,
                agencyEmail,
                customAgencyMobileNumber,
                agencyCountryCode,
                agencyReraNo,
                agencyTradeLicenceNumber,
                auth?.token
            )
                .then((response) => {
                    if (!response.status) {
                        Alert.error(response.statusMsg)
                        setLoading(false)
                        return
                    }

                    dispatch(
                        authActions.updateAgency({
                            agencyName: agencyName,
                            agencyAddress: agencyAddress,
                            agencyEmail: agencyEmail,
                            agencyPhoneNumber: `${agencyCountryCode}${agencyPhoneNumber}`,
                            agencyCountryCode: agencyCountryCode,
                            agencyProfilePicture: agencyProfilePicture,
                            agencyReraNo: agencyReraNo,
                            agencyTradeLicenceNumber: agencyTradeLicenceNumber,
                        })
                    )
                    Alert.success(response.statusMsg)
                    setLoading(false)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not update agency. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                    setLoading(false)
                })
        }
    }

    return (
        <div>
            <Typography
                variant="h2"
                fontSize={'1.15vw'}
                style={{ marginBottom: 20 }}
            >
                {t('text_settings')}
            </Typography>
            {loading ? (
                <div className={'setting-loader'}>
                    <CircularProgress size={20} style={{ color: 'black' }} />
                    {t('text_fetching_user_info')}
                </div>
            ) : (
                <>
                    <Tabs
                        textColor="secondary"
                        indicatorColor="secondary"
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="tabs"
                    >
                        <Tab
                            style={{
                                backgroundColor: GRAY_FOURTEEN,
                                color: WHITE,
                                borderRadius: 10,
                                marginRight: 15,
                                height: 40,
                                minHeight: 40,
                                width: '11vw',
                                fontSize: '0.8vw',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                            }}
                            label={t('text_account_setting')}
                        />
                        <Tab
                            style={{
                                backgroundColor: GRAY_FOURTEEN,
                                color: WHITE,
                                borderRadius: 10,
                                marginLeft: 15,
                                height: 40,
                                minHeight: 40,
                                width: '11vw',
                                fontSize: '0.8vw',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                            }}
                            label={t('text_change_password')}
                        />
                        <Tab
                            style={{
                                backgroundColor: GRAY_FOURTEEN,
                                color: WHITE,
                                borderRadius: 10,
                                marginLeft: 15,
                                height: 40,
                                minHeight: 40,
                                width: '11vw',
                                fontSize: '0.8vw',
                                fontWeight: '700',
                                textTransform: 'capitalize',
                            }}
                            label={'Company Details'}
                        />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                alignItems: 'center',
                                marginBottom: '10px',
                                paddingTop: '30px',
                            }}
                        >
                            <span
                                className="title-heading"
                                style={{
                                    color: 'black',
                                    fontSize: 'medium',
                                    fontWeight: '700',
                                    marginBottom: 10,
                                }}
                            >
                                {t('text_edit_profile')}
                            </span>

                            <div className="img-box">
                                <div className="setting_img">
                                    {base64 || profilePicture.length ? (
                                        <img
                                            src={
                                                base64 ??
                                                process.env.REACT_APP_API_URL +
                                                    profilePicture
                                            }
                                            alt="profile pic"
                                        />
                                    ) : (
                                        <Avatar />
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '15px',
                                    left: '52%',
                                }}
                            >
                                <label
                                    htmlFor="icon-button-file"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
                                        accept="image/*"
                                        id="icon-button-file"
                                        type="file"
                                        onChange={onGetFile}
                                        name="file"
                                    />
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        style={{ padding: 0 }}
                                    >
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            </div>
                        </div>

                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={firstName}
                                    onChange={(e: Event) =>
                                        handleChangeUserFields(e)
                                    }
                                    label={'First Name'}
                                    name={'firstName'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={lastName}
                                    onChange={(e: Event) =>
                                        handleChangeUserFields(e)
                                    }
                                    label={'Last Name'}
                                    name={'lastName'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <ReactPhoneInput
                                    containerClass="custom-phone-input"
                                    inputStyle={{
                                        width: 'inherit',
                                        height: '35px',
                                        borderRadius: 10,
                                        fontFamily: 'Montserrat',
                                    }}
                                    value={phoneNumber}
                                    onChange={handleOnChange}
                                    country={'ae'}
                                    specialLabel={'Phone Number'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={email}
                                    onChange={(e: Event) =>
                                        handleChangeUserFields(e)
                                    }
                                    label={'Email'}
                                    name={'email'}
                                />
                            </Grid>
                        </Grid>

                        <div className="button-wrapper-box">
                            <FormButton
                                onClickHandler={updateUser}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_save_changes')}
                                width={'100%'}
                                borderTopLeftRadius={15}
                                borderTopRightRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />

                            <FormButton
                                width={'100%'}
                                borderTopRightRadius={15}
                                borderTopLeftRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                onClickHandler={resetUserData}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_reset_changes')}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />
                        </div>
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            marginBottom={4}
                            marginTop={4}
                        >
                            <span
                                className="title-heading"
                                style={{
                                    color: 'black',
                                    fontSize: 'medium',
                                    fontWeight: '700',
                                    marginBottom: 10,
                                }}
                            >
                                {t('text_change_password')}
                            </span>
                        </Box>

                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={oldPassword}
                                    onChange={(e: Event) =>
                                        handleChangePasswordFields(e)
                                    }
                                    label={'Current Password'}
                                    name={'oldPassword'}
                                    type={'password'}
                                    errorMessage={oldPasswordError}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={newPassword}
                                    onChange={(e: Event) =>
                                        handleChangePasswordFields(e)
                                    }
                                    label={'New Password'}
                                    name={'newPassword'}
                                    type={'password'}
                                    errorMessage={newPasswordError}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={confirmPassword}
                                    onChange={(e: Event) =>
                                        handleChangePasswordFields(e)
                                    }
                                    label={'Confirm New Password'}
                                    name={'confirmPassword'}
                                    type={'password'}
                                    errorMessage={confirmPasswordError}
                                />
                            </Grid>
                        </Grid>

                        <div className="button-wrapper-box">
                            <FormButton
                                width={'100%'}
                                onClickHandler={handlePasswordReset}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_save_changes')}
                                borderTopLeftRadius={15}
                                borderTopRightRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />

                            <FormButton
                                width={'100%'}
                                onClickHandler={resetPasswordData}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_reset_changes')}
                                borderTopRightRadius={15}
                                borderTopLeftRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />
                        </div>
                    </TabPanel>

                    <TabPanel value={tabValue} index={2}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                alignItems: 'center',
                                marginBottom: '10px',
                                paddingTop: '30px',
                            }}
                        >
                            <span
                                className="title-heading"
                                style={{
                                    color: 'black',
                                    fontSize: 'medium',
                                    fontWeight: '700',
                                    marginBottom: 10,
                                }}
                            >
                                Edit Company Profile
                            </span>

                            <div className="img-box">
                                <div className="setting_img">
                                    {agencyProfilePicture?.length ? (
                                        <img
                                            src={agencyProfilePicture}
                                            alt="profile pic"
                                        />
                                    ) : (
                                        <Avatar />
                                    )}
                                </div>
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '15px',
                                    left: '52%',
                                }}
                            >
                                <label
                                    htmlFor="icon-button-file-for-agency"
                                    style={{ marginBottom: 0 }}
                                >
                                    <Input
                                        accept="image/*"
                                        id="icon-button-file-for-agency"
                                        type="file"
                                        onChange={onGetAgencyFile}
                                        name="agencyFile"
                                    />
                                    <IconButton
                                        color="primary"
                                        aria-label="upload agency picture"
                                        component="span"
                                        style={{ padding: 0 }}
                                    >
                                        <PhotoCamera />
                                    </IconButton>
                                </label>
                            </div>
                        </div>

                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={agencyName}
                                    onChange={(e: Event) =>
                                        handleChangeAgencyFields(e)
                                    }
                                    label={'Company Name'}
                                    name={'agencyName'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={agencyAddress}
                                    onChange={(e: Event) =>
                                        handleChangeAgencyFields(e)
                                    }
                                    label={'Company Address'}
                                    name={'agencyAddress'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={agencyReraNo}
                                    onChange={(e: Event) =>
                                        handleChangeAgencyFields(e)
                                    }
                                    label={'RERA Number'}
                                    name={'agencyReraNo'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    value={agencyTradeLicenceNumber}
                                    onChange={(e: Event) =>
                                        handleChangeAgencyFields(e)
                                    }
                                    label={'Trade Licence Number'}
                                    name={'agencyTradeLicenceNumber'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <ReactPhoneInput
                                    containerClass="custom-phone-input"
                                    inputStyle={{
                                        width: 'inherit',
                                        height: '35px',
                                        borderRadius: 10,
                                        fontFamily: 'Montserrat',
                                    }}
                                    value={agencyPhoneNumber}
                                    onChange={handleAgencyPhoneNumberOnChange}
                                    country={'ae'}
                                    specialLabel={'Phone Number'}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <TextFieldComponent
                                    required={true}
                                    value={agencyEmail}
                                    onChange={(e: Event) =>
                                        handleChangeAgencyFields(e)
                                    }
                                    label={'Email'}
                                    name={'agencyEmail'}
                                />
                            </Grid>
                        </Grid>

                        <div className="button-wrapper-box">
                            <FormButton
                                onClickHandler={updateAgency}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_save_changes')}
                                width={'100%'}
                                borderTopLeftRadius={15}
                                borderTopRightRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />

                            <FormButton
                                width={'100%'}
                                borderTopRightRadius={15}
                                borderTopLeftRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                onClickHandler={resetAgencyData}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                hoverBorderColor={BLACK_EIGHT}
                                style={{ border: `1ps soild ${BLACK_EIGHT}` }}
                                loading={loading}
                                title={t('text_reset_changes')}
                                isSetting={true}
                                borderColor={BLACK_EIGHT}
                            />
                        </div>
                    </TabPanel>
                </>
            )}
        </div>
    )
}
