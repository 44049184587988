import { TableRow, TableCell, Avatar, Typography } from '@mui/material'
import { amountFormat } from '../../../constants'
import { BLACK_EIGHT, LIGHT_GREEN } from '../../../theme'

export const InvoiceRow = ({ isItemSelected, row }: any) => {
    const firstName =
        row.payeeInfo?.firstName ?? row?.paymentLink?.payeeInfos[0].firstName
    const lastName =
        row.payeeInfo?.lastName ?? row?.paymentLink?.payeeInfos[0].lastName
    return (
        <TableRow
            hover
            style={{ padding: '3px 0px' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.name}
            selected={isItemSelected}
        >
            <TableCell
                align="center"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Avatar className="table-avatar">
                    {firstName || lastName
                        ? `${firstName[0]?.toUpperCase() || ''}` +
                          ' ' +
                          `${lastName[0]?.toUpperCase() || ''}`
                        : 'N/A'}
                </Avatar>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {firstName || lastName
                            ? `${firstName}` + ' ' + `${lastName}`
                            : 'N/A'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                style={{ textTransform: 'capitalize', fontWeight: 500 }}
            >
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {' '}
                    {amountFormat(row?.amount) ?? 'N/A'}
                </Typography>
            </TableCell>

            <TableCell align="center" style={{ fontWeight: 500 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {amountFormat(row?.amount) ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    align="center"
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {row.status ?? 'Completed'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
