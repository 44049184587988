import { Box, Typography } from '@mui/material'
import './barChart.css'
import BarChartsComponentForTransactions from './barChartsComponentForTransactions'
import BarChartComponentForPayments from './barChartsComponentForPayments'
import { BarChartsProps } from '../../interfacesTypes'

const BarChartContainer: React.FC<BarChartsProps> = ({
    id,
    loggedIn,
    type,
}) => {
    return (
        <>
            <Typography
                variant="h4"
                style={{ marginBottom: 20, marginTop: 20 }}
            >
                Analytics
            </Typography>

            <Box className="charts-row">
                <BarChartComponentForPayments
                    id={id}
                    loggedIn={loggedIn}
                    type={type}
                />
                <BarChartsComponentForTransactions
                    id={id}
                    loggedIn={loggedIn}
                    type={type}
                />
            </Box>
        </>
    )
}

export default BarChartContainer
