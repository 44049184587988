// packages block
import { AddCircle } from '@mui/icons-material'
// common block
import { FormButton } from '../../../common/button'
// constants, theme, utils and api block
import '../../style.css'
import { BLACK_EIGHT, GREY_NINETEEN, WHITE } from '../../../../theme'
import {
    Box,
    //  Grid,
    //   SelectChangeEvent
} from '@mui/material'
// import { FormSelect } from '../../../common/select'
// import { ACTIVE_INACTIVE_DUMMY_DATA } from '../../../../constants'
// import { useState } from 'react'
// import { UserStatusSelect } from '../../../common/userStatusSelector'
// import { StatusSelect } from '../../../common/statusSelect'

export const TableHeader = ({
    handleInvoices,
    handlePaymentLinks,
    isPaymentLinks,
    isInvoices,
}: any) => {
    // const [status, setStatus] = useState<string>('all')
    // const handleChange = (event: SelectChangeEvent) => {
    //     setStatus(event.target.value)
    // }
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className="table-header student-popup"
        >
            <div>
                <FormButton
                    width={'10vw'}
                    height={45}
                    borderColor={isInvoices ? BLACK_EIGHT : WHITE}
                    titleColor={WHITE}
                    hoverTitleColor={WHITE}
                    hoverBorderColor={BLACK_EIGHT}
                    backgroundColor={isInvoices ? BLACK_EIGHT : GREY_NINETEEN}
                    hoverBackgroundColor={BLACK_EIGHT}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={'Invoices'}
                    onClickHandler={handleInvoices}
                    disabled={false}
                    overRideButtonCheck={true}
                />

                <FormButton
                    width={'10vw'}
                    height={45}
                    borderColor={isPaymentLinks ? BLACK_EIGHT : WHITE}
                    titleColor={WHITE}
                    hoverTitleColor={WHITE}
                    hoverBorderColor={BLACK_EIGHT}
                    backgroundColor={
                        isPaymentLinks ? BLACK_EIGHT : GREY_NINETEEN
                    }
                    hoverBackgroundColor={BLACK_EIGHT}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={'Payment Links'}
                    onClickHandler={handlePaymentLinks}
                    disabled={false}
                    overRideButtonCheck={true}
                />
            </div>
            {/* 
            <Grid container md={2}>
                <StatusSelect
                    value={status}
                    onChange={handleChange}
                    label={''}
                    name={''}
                    list={ACTIVE_INACTIVE_DUMMY_DATA}
                />
            </Grid> */}
        </Box>
    )
}
