// packages block
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    Typography,
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
// components block
import backButtonIcon from '../../../assets/images/back-button-icon.svg'
import { BLACK_EIGHT, WHITE } from '../../../theme'
import { FormButton } from '../../common/button'
import './style.css'
import { RadioButton } from '../../common/radioButton'
import { invoicesCheckBoxData, paymentRadioBtnData } from '../../../constants'
import { TextFieldComponent } from '../../common/textFeild'
import ControlledCheckbox from '../../common/checkbox'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
import { NotificationDialog } from '../../common/notificationDialog'
import oneIcon from '../../../assets/images/one-icon.png'
import twoIcon from '../../../assets/images/two-icon.png'
import AgentInvoiceServices from '../../../apis/agent/invoices'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import smsIcon from '../../../assets/images/sms-icon.svg'
import emailIcon from '../../../assets/images/email-icon.svg'
import whatsAppIcon from '../../../assets/images/whatsapp-icon.svg'
import Alert from '../../common/Alert'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

export const GenerateInvoiceComponent = (): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [radioBtnValue, setRadioBtnValue] = useState(
        paymentRadioBtnData[0].value
    )
    const [isStepOne, setIsStepOne] = useState(true)
    const [isInvoiceForm, setIsInvoiceForm] = useState(true)
    const [isInvoiceDetail, setIsInvoiceDetail] = useState(false)
    const [showNotificationDialog, setShowNotificationDialog] = useState(false)
    const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)

    const [emailCheckBoxValue, setEmailCheckBoxValue] = useState(false)
    const [smsCheckBoxValue, setSmsCheckBoxValue] = useState(false)
    const [whatsAppCheckBoxValue, setWhatsAppCheckBoxValue] = useState(false)

    const [
        {
            firstName,
            lastName,
            phoneNumber,
            countryCode,
            email,
            amount,
            invoiceDescription,
            paymentDescription,
        },
        setCustomerData,
    ] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        countryCode: '',
        email: '',
        amount: '',
        invoiceDescription: '',
        paymentDescription: '',
    })
    const handleChangeCustomerFields = (e: Event) => {
        if (typeof e === 'string' || e instanceof String) return
        const { name, value } = e.target as HTMLInputElement
        setCustomerData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handlePhoneNumber = (value: string, country: any) => {
        setCustomerData((prevState) => ({
            ...prevState,
            phoneNumber: value.replace(/\D/g, ''),
            countryCode: country.dialCode,
        }))
    }
    let customMobileNumber: any
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioBtnValue((event.target as HTMLInputElement).value)
    }
    const handleBack = () => {
        window.location.href = '/agent/invoices'
    }

    const handleBackInForm = () => {
        setIsInvoiceForm(true)
        setIsInvoiceDetail(false)
        setShowNotificationDialog(false)
        setIsSuccessDialogVisible(false)
    }

    const handleInvoiceFormContinue = () => {
        if (
            !firstName.length ||
            !lastName.length ||
            !phoneNumber.length ||
            !email.length ||
            !amount.length
        ) {
            console.log('firstName:', firstName)
            console.log('lastName:', lastName)
            console.log('phoneNumber:', phoneNumber)
            console.log('email:', email)
            console.log('amount:', amount)
            Alert.error('All fields are required')
            return
        }
        setIsStepOne(false)
    }

    const handleInvoiceFormSave = () => {
        if (!invoiceDescription.length) {
            Alert.error('Invoice details is required')
            return
        }
        setIsStepOne(false)
        setIsInvoiceForm(false)
        setIsInvoiceDetail(true)
    }

    const handleNotificationDialog = () => {
        setShowNotificationDialog(true)
    }

    const closeNotificationDialog = () => {
        setShowNotificationDialog(false)
    }

    const goHome = () => {
        setIsSuccessDialogVisible(false)
        window.location.href = '/agent/invoices'
    }

    const handleNotificationSend = () => {
        customMobileNumber = phoneNumber?.substring(
            countryCode?.length,
            phoneNumber?.length
        )

        if (auth.user && pathname.startsWith('/agent')) {
            AgentInvoiceServices.createInvoiceForCustomer(
                auth.user.id,
                firstName,
                lastName,
                amount,
                email,
                customMobileNumber,
                countryCode,
                invoiceDescription,
                paymentDescription.length ? paymentDescription : radioBtnValue,
                emailCheckBoxValue,
                smsCheckBoxValue,
                whatsAppCheckBoxValue,
                auth?.token
            ).then((response) => console.log(response))
        }
        setShowNotificationDialog(false)
        setIsSuccessDialogVisible(true)
    }

    return (
        <div className="">
            <Box display="flex" alignItems="center">
                <Button
                    variant="contained"
                    onClick={isInvoiceForm ? handleBack : handleBackInForm}
                    style={{
                        backgroundColor: 'transparent',
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px',
                        borderRadius: '3px',
                        boxShadow: 'none',
                    }}
                >
                    <img
                        src={backButtonIcon}
                        className="backbut"
                        style={{ width: 48, cursor: 'pointer' }}
                        alt="back-button-icon"
                    />
                </Button>

                <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                    Invoices
                </Typography>
            </Box>

            {isInvoiceForm && (
                <>
                    <Box my="30px">
                        <Typography variant="h6" color="#252422">
                            Create and send invoice
                        </Typography>

                        <Typography variant="body1" color="#70707087">
                            This invoice will be sent to your customer with a
                            payment link for them to settle the invoice
                        </Typography>
                    </Box>

                    <Box
                        className="generate-invoice-container"
                        maxWidth="640px"
                        mx="auto"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems={isStepOne ? 'center' : 'flex-start'}
                        px={'150px'}
                    >
                        {isStepOne ? (
                            <>
                                <Typography variant="h6" color="#252422" mb={8}>
                                    Customer
                                </Typography>
                                <Grid container spacing={4}>
                                    <Grid item sm={6} xs={12}>
                                        <TextFieldComponent
                                            required={true}
                                            value={firstName}
                                            onChange={(e: Event) =>
                                                handleChangeCustomerFields(e)
                                            }
                                            label={'First Name'}
                                            name={'firstName'}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <TextFieldComponent
                                            required={true}
                                            value={lastName}
                                            onChange={(e: Event) =>
                                                handleChangeCustomerFields(e)
                                            }
                                            label={'Last Name'}
                                            name={'lastName'}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        {/* <TextFieldComponent
                                            required={true}
                                            type="number"
                                            value={phoneNumber}
                                            onChange={(e: Event) =>
                                                handleChangeCustomerFields(e)
                                            }
                                            label={'Phone Number'}
                                            name={'phoneNumber'}
                                        /> */}

                                        <ReactPhoneInput
                                            containerClass="custom-phone-input"
                                            inputStyle={{
                                                width: 'inherit',
                                                height: '35px',
                                                borderRadius: 10,
                                                fontFamily: 'Montserrat',
                                            }}
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                            country={'ae'}
                                            specialLabel={'Phone Number'}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <TextFieldComponent
                                            required={true}
                                            value={email}
                                            onChange={(e: Event) =>
                                                handleChangeCustomerFields(e)
                                            }
                                            label={'Email Address'}
                                            name={'email'}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <TextFieldComponent
                                            required={true}
                                            type="number"
                                            value={amount}
                                            onChange={(e: Event) =>
                                                handleChangeCustomerFields(e)
                                            }
                                            label={'Amount'}
                                            name={'amount'}
                                        />
                                    </Grid>
                                </Grid>

                                <Box mt={8}>
                                    <FormButton
                                        width={'20vw'}
                                        height={45}
                                        borderColor={WHITE}
                                        titleColor={WHITE}
                                        hoverTitleColor={BLACK_EIGHT}
                                        hoverBorderColor={BLACK_EIGHT}
                                        backgroundColor={BLACK_EIGHT}
                                        hoverBackgroundColor={WHITE}
                                        borderTopRightRadius={15}
                                        borderTopLeftRadius={15}
                                        borderBottomLeftRadius={15}
                                        borderBottomRightRadius={15}
                                        title={'Continue'}
                                        onClickHandler={
                                            handleInvoiceFormContinue
                                        }
                                        disabled={false}
                                        overRideButtonCheck={true}
                                    />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box display="flex">
                                    <img
                                        src={oneIcon}
                                        alt=""
                                        width={20}
                                        height={20}
                                    />
                                    <Typography
                                        variant="h6"
                                        color="#252422"
                                        ml={1}
                                    >
                                        Invoice details
                                    </Typography>
                                </Box>

                                <Typography variant="body1" color="#70707087">
                                    Add details to help you keep track of this
                                    payment
                                </Typography>

                                <Grid container item sm={10} xs={12} my="20px">
                                    <TextFieldComponent
                                        required={true}
                                        value={invoiceDescription}
                                        onChange={(e: Event) =>
                                            handleChangeCustomerFields(e)
                                        }
                                        label={'Description'}
                                        name={'invoiceDescription'}
                                        type="text"
                                    />
                                </Grid>

                                <Box display="flex">
                                    <img
                                        src={twoIcon}
                                        alt=""
                                        width={20}
                                        height={20}
                                    />
                                    <Typography
                                        variant="h6"
                                        color="#252422"
                                        ml={1}
                                    >
                                        What’s the payment for?
                                    </Typography>
                                </Box>

                                <Typography variant="body1" color="#70707087">
                                    Add a short description to help your
                                    customers understand what this payment link
                                    is for
                                </Typography>

                                <Box mb={2}>
                                    <RadioButton
                                        handleRadioBtnChange={handleChange}
                                        radioBtnValue={radioBtnValue}
                                        radioBtnData={paymentRadioBtnData}
                                    />
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <Divider
                                        sx={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            minWidth: '300px',
                                            height: '2px',
                                            mr: '10px',
                                        }}
                                    />
                                    <Typography variant="h6">OR</Typography>
                                    <Divider
                                        sx={{
                                            width: '100%',
                                            maxWidth: '300px',
                                            minWidth: '300px',
                                            height: '2px',
                                            ml: '10px',
                                        }}
                                    />
                                </Box>

                                <Grid container item sm={10} xs={12} my="20px">
                                    <TextFieldComponent
                                        required={false}
                                        value={paymentDescription}
                                        onChange={(e: Event) =>
                                            handleChangeCustomerFields(e)
                                        }
                                        label={'Other Payment Types'}
                                        name={'paymentDescription'}
                                        type="text"
                                    />
                                </Grid>

                                <Box mt={4} display="flex" alignSelf="center">
                                    <FormButton
                                        width={'20vw'}
                                        height={45}
                                        borderColor={WHITE}
                                        titleColor={WHITE}
                                        hoverTitleColor={BLACK_EIGHT}
                                        hoverBorderColor={BLACK_EIGHT}
                                        backgroundColor={BLACK_EIGHT}
                                        hoverBackgroundColor={WHITE}
                                        borderTopRightRadius={15}
                                        borderTopLeftRadius={15}
                                        borderBottomLeftRadius={15}
                                        borderBottomRightRadius={15}
                                        title={'Continue'}
                                        onClickHandler={handleInvoiceFormSave}
                                        disabled={false}
                                        overRideButtonCheck={true}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </>
            )}

            {isInvoiceDetail && (
                <Box>
                    <Box my="30px">
                        <Typography variant="h6" color="#252422">
                            Invoice details
                        </Typography>

                        <Typography variant="body1" color="#70707087">
                            Select how you wish for your customer to be notified
                            :
                        </Typography>
                    </Box>

                    <Box
                        className="generate-invoice-container"
                        px="70px"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        maxWidth="700px"
                        mx="auto"
                        mt="30px"
                    >
                        <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                        >
                            <ControlledCheckbox
                                label={'Email Notification'}
                                value={emailCheckBoxValue}
                                icon={emailIcon}
                                setCheckbox={setEmailCheckBoxValue}
                            />
                            <ControlledCheckbox
                                label={'SMS Notification'}
                                value={smsCheckBoxValue}
                                icon={smsIcon}
                                setCheckbox={setSmsCheckBoxValue}
                            />
                            <ControlledCheckbox
                                label={'WhatsApp Notification'}
                                value={whatsAppCheckBoxValue}
                                icon={whatsAppIcon}
                                setCheckbox={setWhatsAppCheckBoxValue}
                            />
                        </FormControl>

                        <Box mt={4} display="flex" alignSelf="center">
                            <FormButton
                                width={'20vw'}
                                height={45}
                                borderColor={WHITE}
                                titleColor={WHITE}
                                hoverTitleColor={BLACK_EIGHT}
                                hoverBorderColor={BLACK_EIGHT}
                                backgroundColor={BLACK_EIGHT}
                                hoverBackgroundColor={WHITE}
                                borderTopRightRadius={15}
                                borderTopLeftRadius={15}
                                borderBottomLeftRadius={15}
                                borderBottomRightRadius={15}
                                title={'Continue'}
                                onClickHandler={handleNotificationDialog}
                                disabled={false}
                                overRideButtonCheck={true}
                            />
                        </Box>
                    </Box>
                </Box>
            )}

            {showNotificationDialog && (
                <NotificationDialog
                    handleClose={closeNotificationDialog}
                    open={showNotificationDialog}
                    handleClicker={handleNotificationSend}
                    notificationTitle="Send Invoice"
                    notificationDescription={`Send invoice for AED ${amount} to ${
                        firstName + ' ' + lastName
                    }? Invoice will be sent via email and can’t be edited after`}
                />
            )}

            {isSuccessDialogVisible && (
                <OnSuccessDialog
                    open={isSuccessDialogVisible}
                    goHome={goHome}
                    successMessage={'Email Send Successfully'}
                />
            )}
        </div>
    )
}
