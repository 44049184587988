// packages block
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
} from '@mui/material'
// components block
import LoginController from './LoginController'
// history, context, constants, and utils block
import {
    AGENCY_DASHBOARD_ROUTE,
    AGENT_DASHBOARD_ROUTE,
    FORGOT_PASSWORD_AGENCY_ROUTE,
    FORGOT_PASSWORD_AGENT_ROUTE,
    PASSWORD_LABEL,
} from '../../constants'
import { LoginUserInput } from '../../interfacesTypes'
import { loginValidationSchema } from '../../validationSchemas'

import {
    AuthContainer,
    CompanyAuthLogo,
    FormContainer,
    LoginFormGroup,
    LoginFormInput,
} from './login.style'
import { BLACK, BLACK_EIGHT, GREY_SEVENTEEN, WHITE } from '../../theme'
import { useHistory } from 'react-router'
import { FormButton } from '../common/button'
import Alert from '../common/Alert'
import AgencyAuth from '../../apis/agency/auth'
import { useDispatch } from 'react-redux'
import { authActions } from '../../redux/store/slices/auth'
import AgentAuth from '../../apis/agent/auth'
import paynestCollectLogo from '../../assets/images/paynest-collect-login-logo.png'
import './index.css'
import backgroundImage from '../../assets/images/bg-image.png'

const debug = Boolean(process.env.REACT_APP_DEBUG)

const LoginComponent = (): JSX.Element => {
    const dispatch = useDispatch()
    const [videoLoadError, setVideoLoadError] = useState(false)
    const [checked, setChecked] = useState(true)
    const [isArabic, setIsArabic] = useState(false)
    const [isEnglish, setIsEnglish] = useState(true)
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginUserInput>({
        mode: 'all',
        resolver: yupResolver(loginValidationSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    })

    const handleVideoError = () => {
        setVideoLoadError(true)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    const validateInput: SubmitHandler<LoginUserInput> = (data) => {
        if (data.email === '') {
            const err = {
                status: false,
                statusMsg: 'Email cannot be empty',
            }
            if (debug) console.error(err)
            return err
        }

        if (data.password === '') {
            const err = {
                status: false,
                statusMsg: 'Password cannot be empty',
            }
            if (debug) console.error(err)
            return err
        }
        return {
            status: true,
            statusMsg: 'Validated fields',
        }
    }

    const onSubmit: SubmitHandler<LoginUserInput> = async (data) => {
        // const validate = validateInput(data) as {
        //     status: boolean
        //     statusMsg: string
        // }
        // if (!validate.status) {
        //     Alert.error(validate.statusMsg)
        //     return validate
        // }
        const pathname = window.location.pathname

        let signInResponse
        if (pathname === '/agency/login') {
            signInResponse = await AgencyAuth.signIn(data)
            if (signInResponse.status) {
                Alert.success(signInResponse.statusMsg)
                dispatch(
                    authActions.signIn({
                        token: signInResponse.data.token,
                    })
                )
                return history.push(AGENCY_DASHBOARD_ROUTE)
            }
        }
        if (pathname === '/agent/login') {
            signInResponse = await AgentAuth.signIn(data)
            if (signInResponse.status) {
                Alert.success(signInResponse.statusMsg)
                dispatch(
                    authActions.signIn({
                        token: signInResponse.data.token,
                    })
                )
                return history.push(AGENT_DASHBOARD_ROUTE)
            }
        }

        Alert.info(signInResponse.statusMsg)
        return signInResponse
    }

    const {
        email: { message: emailError } = {},
        password: { message: passwordError } = {},
    } = errors

    const changeLanguage = (lng: string) => {
        if (lng === 'en') {
            setIsEnglish(true)
            setIsArabic(false)
        } else {
            setIsArabic(true)
            setIsEnglish(false)
        }

        i18n.changeLanguage(lng)
    }

    const forgetClickHandler = () => {
        const pathname = window.location.pathname
        if (pathname === '/agency/login') {
            history.push(FORGOT_PASSWORD_AGENCY_ROUTE)
        }
        if (pathname === '/agent/login') {
            history.push(FORGOT_PASSWORD_AGENT_ROUTE)
        }
    }

    return (
        <AuthContainer>
            <video
                autoPlay
                loop
                muted
                poster={backgroundImage}
                className="background-video"
                src="../../assets/video.mp4"
            />
            <FormContainer>
                <CompanyAuthLogo>
                    <img src={paynestCollectLogo} alt="paynest-collect-logo" />
                </CompanyAuthLogo>

                <LoginFormGroup>
                    <Box>
                        <Typography
                            variant="h3"
                            style={{
                                marginBottom: 10,
                                marginLeft: 8,
                                color: BLACK,
                                fontWeight: 700,
                                fontSize: '1.18vw',
                            }}
                        >
                            {t('text_sign_in')}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                marginLeft: 8,
                                fontSize: '0.65vw',
                                marginBottom: 10,
                                fontWeight: 500,
                            }}
                        >
                            {t(
                                'text_please_enter_your_registered_email_id_and_password'
                            )}
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <LoginFormInput>
                            <LoginController
                                control={control}
                                controllerName="email"
                                controllerLabel={'Email Address'}
                                fieldType="text"
                                error={emailError}
                            />
                        </LoginFormInput>

                        <LoginFormInput>
                            <LoginController
                                control={control}
                                controllerName="password"
                                isPassword={true}
                                controllerLabel={PASSWORD_LABEL}
                                fieldType="password"
                                error={passwordError}
                            />
                        </LoginFormInput>

                        <Box
                            style={{
                                marginTop: 15,
                                fontWeight: '500',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                onClick={forgetClickHandler}
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.65vw',
                                    fontFamily: 'Montserrat',
                                    lineHeight: '18px',
                                    fontWeight: '6--',
                                    cursor: 'pointer',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {t('text_forgot_password')}
                            </Button>
                        </Box>

                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <FormControlLabel
                                sx={{
                                    '& span': {
                                        fontSize: '0.65vw',
                                        color: '#74ba6b',
                                    },
                                }}
                                control={
                                    <Checkbox
                                        color="success"
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                    />
                                }
                                label={t('text_i_accept_terms_and_conditions')}
                            />
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                            }}
                        >
                            <FormButton
                                noMargin={true}
                                type="submit"
                                height={50}
                                width={'100%'}
                                borderColor={BLACK_EIGHT}
                                title={`${t('text_sign_in')}`}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={WHITE}
                                hoverBackgroundColor={BLACK_EIGHT}
                                loading={false}
                                borderTopLeftRadius={15}
                                borderTopRightRadius={15}
                                borderBottomRightRadius={15}
                                borderBottomLeftRadius={15}
                                disabled={!checked}
                                style={{ fontSize: '0.9vw' }}
                            />
                        </div>

                        <Box
                            display="flex"
                            justifyContent="center"
                            mt={4}
                            mb={2}
                        >
                            <Typography
                                variant="h3"
                                style={{
                                    color: isEnglish ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    borderRight: `2px solid ${BLACK}`,
                                    paddingRight: 5,
                                }}
                                onClick={() => changeLanguage('en')}
                            >
                                {t('text_english')}
                            </Typography>

                            <Typography
                                variant="h3"
                                style={{
                                    color: isArabic ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    marginLeft: 5,
                                }}
                                onClick={() => changeLanguage('ar')}
                            >
                                {t('text_arabic')}
                            </Typography>
                        </Box>
                    </form>
                </LoginFormGroup>
            </FormContainer>
        </AuthContainer>
    )
}

export default LoginComponent
