//packages blocks
import { useTranslation } from 'react-i18next'
import { useState, useEffect, FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory, withRouter } from 'react-router'
//common blocks
import './sideBar.css'
//styles, utils, assets and api blocks
import { BLACK, BLACK_EIGHT, GREY_SEVENTEEN } from '../../theme'
import paynestCollectLogo from '../../assets/images/paynest-collect-logo.png'
import { useDispatch } from 'react-redux'
import { authActions } from '../../redux/store/slices/auth'

const Side: FC = (): JSX.Element => {
    const dispatch = useDispatch()
    const [selectedPage, setSelectedPage] = useState('dashboard')
    const history = useHistory()
    const path = document.location.pathname
    const [isEnglish, setIsEnglish] = useState(true)
    const [isArabic, setIsArabic] = useState(false)

    const { t, i18n } = useTranslation()

    useEffect(() => {
        if (path.includes('/dashboard')) setSelectedPage('dashboard')
        else if (path.includes('/users')) setSelectedPage('users')
        else if (path.includes('/transactions')) setSelectedPage('transactions')
        else if (path.includes('/accounts')) setSelectedPage('accounts')
        // else if (path.includes('/reports')) setSelectedPage('reports')
        else if (path.includes('/support')) setSelectedPage('support')
        else if (path.includes('/settings')) setSelectedPage('settings')
        else if (path.includes('/invoices')) setSelectedPage('invoices')
        else if (path.includes('/paymentLinks')) setSelectedPage('paymentLinks')
    }, [setSelectedPage, path])

    const clickLink = (path: string) => {
        if (path === 'logout') {
            dispatch(authActions.signOut())
            history.push('/')
        } else history.push(path)
    }

    const handleLogoutOut = () => {
        if (path.includes('/agency')) {
            dispatch(authActions.signOut())
            history.push('/agency/login')
        } else if (path.includes('/agent')) {
            dispatch(authActions.signOut())
            history.push('/agent/login')
        }
    }

    const changeLanguage = (lng: string) => {
        if (lng === 'en') {
            setIsEnglish(true)
            setIsArabic(false)
        } else {
            setIsArabic(true)
            setIsEnglish(false)
        }

        i18n.changeLanguage(lng)
    }

    return (
        <>
            <div className="sidebar">
                <div className="brand_img">
                    <img src={paynestCollectLogo} alt="paynest-collect-logo" />
                </div>

                <div className="sidebar-inner-container">
                    <div>
                        <div className="menu_options">
                            {path.includes('/agency') && (
                                <>
                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon dashboard_icon"></div>
                                        }
                                        id={'dashboard'}
                                        path={'/agency/dashboard'}
                                        name={t('text_dashboard')}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon user_icon"></div>
                                        }
                                        id={'users'}
                                        path={'/agency/users'}
                                        name={'User Management'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon transaction_icon"></div>
                                        }
                                        id={'transactions'}
                                        path={'/agency/transactions'}
                                        name={'Transactions History'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon account_icon"></div>
                                        }
                                        id={'accounts'}
                                        path={'/agency/accounts'}
                                        name={'Account Management'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    {/* <MenuOption
                    icon={
                      <div className="menu_options_icon report_icon"></div>
                    }
                    id={'reports'}
                    path={'/agency/reports'}
                    name={'Reports'}
                    subMenus={[]}
                    clickLink={clickLink}
                    selectedPage={selectedPage}
                  /> */}

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon support_icon"></div>
                                        }
                                        id={'support'}
                                        path={'/agency/support'}
                                        name={'Support'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon setting_icon"></div>
                                        }
                                        id={'settings'}
                                        path={'/agency/settings'}
                                        name={t('text_settings')}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />
                                </>
                            )}

                            {path.includes('/agent') && (
                                <>
                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon dashboard_icon"></div>
                                        }
                                        id={'dashboard'}
                                        path={'/agent/dashboard'}
                                        name={t('text_dashboard')}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon payment_links_icon"></div>
                                        }
                                        id={'paymentLinks'}
                                        path={'/agent/paymentLinks'}
                                        name={'Payment Links'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon invoice_icon"></div>
                                        }
                                        id={'invoices'}
                                        path={'/agent/invoices'}
                                        name={'Invoices'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon transaction_icon"></div>
                                        }
                                        id={'transactions'}
                                        path={'/agent/transactions'}
                                        name={'Transactions'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon support_icon"></div>
                                        }
                                        id={'support'}
                                        path={'/agent/support'}
                                        name={'Support'}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />

                                    <MenuOption
                                        icon={
                                            <div className="menu_options_icon setting_icon"></div>
                                        }
                                        id={'settings'}
                                        path={'/agent/settings'}
                                        name={t('text_settings')}
                                        subMenus={[]}
                                        clickLink={clickLink}
                                        selectedPage={selectedPage}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div>
                        <Box
                            onClick={() => handleLogoutOut()}
                            className="logout-container"
                        >
                            <div className="logout_icon"></div>
                            <Typography variant="h3">
                                {t('text_logout')}
                            </Typography>
                        </Box>

                        <Box display="flex" ml={5}>
                            <Typography
                                variant="h3"
                                style={{
                                    color: isEnglish ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    borderRight: `2px solid ${BLACK_EIGHT}`,
                                    paddingRight: 5,
                                }}
                                onClick={() => changeLanguage('en')}
                            >
                                {t('text_english')}
                            </Typography>

                            <Typography
                                variant="h3"
                                style={{
                                    color: isArabic ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    marginLeft: 5,
                                }}
                                onClick={() => changeLanguage('ar')}
                            >
                                {t('text_arabic')}
                            </Typography>
                        </Box>
                    </div>
                </div>
            </div>
        </>
    )
}

const MenuOption = ({
    id,
    name,
    clickLink,
    path,
    selectedPage,
    subMenus,
    icon,
}: any) => {
    return (
        <div
            key={id}
            className={`sidebar-text${
                selectedPage === id || subMenus.indexOf(selectedPage) > -1
                    ? '-selected'
                    : ''
            }`}
            onClick={() => clickLink(path)}
        >
            {icon}
            <Typography
                component="span"
                variant="subtitle1"
                fontSize={'0.85vw'}
                fontWeight={600}
            >
                {name}
            </Typography>
        </div>
    )
}

export const Sidebar = withRouter(Side)
