import { useRef } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { Divider, Typography } from '@mui/material'
import { paymentMegthodsIconData } from '../../constants'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

export const QrCodeContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const qrRef: any = useRef()

    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={`${process.env.REACT_APP_FE_URL}/agent/payment-link/${auth?.user?.paymentLink.url}`}
            size={200}
            bgColor="white"
            level={'H'}
        />
    )

    return (
        <div className="qrcode-container">
            <div className="qrcode-inner-container">
                <div ref={qrRef} className="qr-code-content-container">
                    {qrcode}
                </div>

                <Typography variant="h2" mt={'38px'} mb={'24px'}>
                    {auth.user?.name}
                </Typography>
                <Typography
                    variant="body2"
                    mb={'14px'}
                    fontWeight={600}
                    color="#70707087"
                >
                    Ask Your Customer To Scan And Pay
                </Typography>

                <Box>
                    {paymentMegthodsIconData.map(({ alt, icon }) => {
                        return <img src={icon} alt={alt} loading="lazy" />
                    })}
                </Box>
            </div>

            <Typography variant="h6" mt={'60px'} mb={'5px'}>
                Company Name
            </Typography>
            <Typography variant="body2" mb={'20px'}>
                {auth.user?.agencyName}
            </Typography>

            <Divider sx={{ width: '100%', maxWidth: '348px', height: '2px' }} />

            <Typography variant="h6" mt={'20px'} mb={'5px'}>
                RERA Number
            </Typography>
            <Typography variant="body2" mb={'20px'}>
                6546543
            </Typography>
        </div>
    )
}
