import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import { Divider } from '@mui/material'

export const SwitchesGroup = ({
    label,
    value,
    description,
    checked,
    setChecked,
}: any) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormGroup>
                <>
                    <FormControlLabel
                        sx={{ justifyContent: 'space-between', mx: '0px' }}
                        labelPlacement="start"
                        label={label}
                        control={
                            <Switch
                                color="success"
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                                name={value}
                            />
                        }
                    />
                    <FormHelperText>{description}</FormHelperText>
                    {value === 'shareReceipt' ? (
                        <Divider
                            sx={{
                                width: '100%',
                                maxWidth: '600px',
                                minWidth: '600px',
                                height: '0px',
                                borderBottomWidth: '0px',
                                my: '10px',
                            }}
                        />
                    ) : (
                        <Divider
                            sx={{
                                width: '100%',
                                maxWidth: '600px',
                                minWidth: '600px',
                                height: '2px',
                                my: '10px',
                            }}
                        />
                    )}
                </>
            </FormGroup>
        </FormControl>
    )
}
