import axios from 'axios'
import {
    GET_INVOICE_TRANSACTIONS_BY_AGENT_URL,
    GET_PAYMENT_LINKS_GRAPH_FOR_AGENT,
    GET_PAYMENT_LINK_TRANSACTIONS_BY_AGENT_URL,
    GET_TRANSACTIONS_BY_AGENT_URL,
    GET_TRANSACTIONS_GRAPH_FOR_AGENT,
    GET_TRANSACTIONS_STATS_URL,
    GET_TRANSACTIONS_USING_PAYMENT_LINK_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgentTransactions = {
    stats: async (
        startDate: string,
        endDate: string,
        agentId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_STATS_URL}?startDate=${startDate}&endDate=${endDate}&agentId=${agentId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get transaction stats. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    createTransactionByPaymentLink: async (
        uuid: string,
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        countryCode: string,
        message: string,
        paymentGatewayType: string,
        amount: string,
        token: string | null
    ) => {
        const paymentLinkObj = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            countryCode: countryCode,
            message: message,
            paymentGatewayType: paymentGatewayType,
            amount: amount,
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.post(
                `${GET_TRANSACTIONS_USING_PAYMENT_LINK_URL}?uuid=${uuid}`,
                paymentLinkObj,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get transaction stats. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactionsByAgent: async (
        agentId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_BY_AGENT_URL}?agentId=${agentId}&status=completed&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getInvoiceTransactionsByAgent: async (
        agentId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_INVOICE_TRANSACTIONS_BY_AGENT_URL}?agentId=${agentId}&status=completed&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPaymentLinkTransactionsByAgent: async (
        agentId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_PAYMENT_LINK_TRANSACTIONS_BY_AGENT_URL}?agentId=${agentId}&status=completed&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPaymentLinksGraphForAgent: async (
        startDate: string,
        endDate: string,
        id: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_PAYMENT_LINKS_GRAPH_FOR_AGENT}?startDate=${startDate}&endDate=${endDate}&id=${id}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get graph transaction. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactionsGraphForAgent: async (
        startDate: string,
        endDate: string,
        id: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_GRAPH_FOR_AGENT}?startDate=${startDate}&endDate=${endDate}&id=${id}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get graph transaction. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgentTransactions
