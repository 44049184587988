export const GRAY_SIMPLE = 'gray'
export const GRAY = '#f9f9f9'
export const GRAY_ONE = '#ECF0F3'
export const GRAY_THREE = '#464E5F'
export const GRAY_FOUR = '#dddddd'
export const GRAY_SIX = '#EFF2F5'
export const GRAY_SEVEN = '#B1B3BB'
export const GRAY_EIGHT = '#A3A3C7'
export const GRAY_TEN = '#F4F6FD'
export const GRAY_ELEVEN = '#40353554'
export const GRAY_TWELVE = '#BCB3E2'
export const GRAY_THIRTEEN = '#7B7B7B'
export const GRAY_FOURTEEN = '#a9aaad'
export const GRAY_FIFTEEN = '#70707087'

export const BLACK_FOUR = '#181C32'
export const BLACK_FIVE = '#333333'
export const BLACK_SIX = '#6C7293'

export const WHITE_ONE = '#F2F3F5'
export const WHITE_TWO = '#E5E5E5'
export const WHITE_THREE = '#F3F6F9'
export const WHITE_FOUR = '#E4E6EF'
export const WHITE_SIX = '#F1FAFF'

export const BLUE_SIMPLE = 'blue'
export const BLUE_THREE = '#00A3FF'
export const BLUE_FOUR = '#1BC5BD'
export const BLUE_FIVE = '#C9F7F5'
export const BLUE_SIX = '#009EF7'
export const BLUE_SEVEN = '#3699FF'
export const BLUE_NINE = '#3f51b5'
export const BLUE_TEN = '#21E1D8'
export const BLUE_ELEVEN = '#3F95FF'
export const BLACK_EIGHT = '#334050'
export const BLUE_TWELVE = '#70aeb6'
export const BLUE_THIRTEEN = '#cce2e5'
export const BLUE_FOUTEEN = '#1492E6'

export const LIGHT_GREEN = '#74BA6B'
export const LIGHT_GREEN_ONE = '#56c356'
export const DARK_GREEN = 'darkgreen'

export const ORANGE_SIMPLE = 'orange'
export const ORANGE = '#FFF9F4'
export const ORANGE_ONE = '#F89C47'
export const ORANGE_TWO = '#da5e60'

export const PINK = '#F1416C'
export const PINK_TWO = '#FF6A7A'

export const RED_THREE = '#f44336'

// ** NEW UI COLORS **

export const BLUE = '#204ECF'
export const BLUE_ONE = '#5AACFF'
export const BLUE_TWO = '#1C6DD0'

export const GREEN = '#03CC83'
export const GREEN_ONE = '#0BB783'
export const GREEN_TWO = '#F0FDF4'

export const BLACK = '#262D3D'
export const BLACK_ONE = '#3F4254'
export const BLACK_TWO = '#202020'
export const BLACK_THREE = '#6B7280'

export const GREY = '#F3F4F6'
export const GREY_ONE = '#C9CED6'
export const GREY_TWO = '#A1A5B7'
export const GREY_THREE = '#7E8299'
export const GREY_FOUR = '#EEF6FF'
export const GREY_FIVE = '#D1D5DB'
export const GREY_SIX = '#9CA3AF'
export const GREY_SEVEN = '#B5B5C3'
export const GREY_EIGHT = '#E7E8ED'
export const GREY_NINE = '#F9FAFB'
export const GREY_TEN = '#E0E1E2'
export const GREY_ELEVEN = '#F5F8FA'
export const GREY_TWELVE = '#F9FBFF'
export const GREY_THIRTEEN = '#7E7E7E'
export const GREY_FOURTEEN = '#F4F4F4'
export const GREY_FIFTEEN = '#5E6278'
export const GREY_SIXTEEN = '#B3B3B3'
export const GREY_SEVENTEEN = '#bec5c6'
export const GREY_EIGHTEEN = '#AAAAAB'
export const GREY_NINETEEN = '#00000029'

export const WHITE = '#FFFFFF'

export const RED = '#DD1010'
export const RED_ONE = '#F64E60'

export const PURPLE = 'purple'
export const PURPLE_ONE = '#7239EA'
export const PURPLE_TWO = '#A075F8'

export const MILD = '#FFA800'
export const ACUTE = '#DD1010'
export const MODERATE = '#FF6B00'
export const VERY_MILD = '#FFC700'

// APPOINTMENT STATUS RGBA-COLORS

export const GREEN_THREE = '#74ba6b'
export const BLUE_EIGHT = '#7FB3E0'
export const GREEN_RGBA = `rgba(0,128,0,0.1)`
export const RED_THREE_RGBA = `rgba(244,67,54,0.1)`
export const RED_RGBA = `rgba(255,0,0,0.1)`
export const LIGHT_GREEN_RGBA = `rgba(144,238,144,0.1)`
export const DARK_GREEN_RGBA = `rgba(0,100,0,0.1)`
export const BLUE_SEVEN_RGBA = `rgba(54,153,255,0.1)`
export const ORANGE_SIMPLE_RGBA = `rgba(255,162,0, 0.1)`
export const GRAY_SIMPLE_RGBA = `rgba(128,128,128,0.1)`
export const PURPLE_RGBA = `rgba(128,0,128,0.1)`
export const BLUE_RGBA = `rgba(0,0,255,0.1)`
