import { Box, Typography } from '@mui/material'
import { BLACK_EIGHT, WHITE } from '../../theme'
import { FormButton } from '../common/button'
import './support.css'

export const SupportComponent = () => {
    return (
        <div>
            <Typography variant="h2" fontSize={'1.15vw'} mb={2}>
                Support
            </Typography>

            <Box
                className="support-container"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography variant="h1" mb={4}>
                    Are You Facing Difficulty?
                </Typography>

                <Typography
                    variant="h6"
                    mb={4}
                    textAlign="center"
                    color="#70707087"
                    maxWidth={350}
                >
                    If You Are Facing Any Difficulty Or You Have Any Question,
                    You Can Contact Us By The Following Ways.
                </Typography>

                <Box display="flex" flexDirection="column" rowGap={3} mt={2}>
                    <FormButton
                        title={'Live Chat Customer Support'}
                        height={60}
                        width={'30vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={BLACK_EIGHT}
                        borderColor={BLACK_EIGHT}
                        titleColor={WHITE}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={BLACK_EIGHT}
                        hoverTitleColor={BLACK_EIGHT}
                        onClickHandler={() => {
                            window.open('https://wa.me/+971525919430', '_blank')
                        }}
                        style={{ fontSize: '0.9vw' }}
                    />

                    <FormButton
                        title={'Contact Us By Email'}
                        height={60}
                        width={'30vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={BLACK_EIGHT}
                        borderColor={BLACK_EIGHT}
                        titleColor={WHITE}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={BLACK_EIGHT}
                        hoverTitleColor={BLACK_EIGHT}
                        onClickHandler={() => {
                            window.location.href = 'mailto:info@paynest.com'
                        }}
                        style={{ fontSize: '0.9vw' }}
                    />

                    <FormButton
                        title={"Check The FAQ'S"}
                        height={60}
                        width={'30vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={BLACK_EIGHT}
                        borderColor={BLACK_EIGHT}
                        titleColor={WHITE}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={BLACK_EIGHT}
                        hoverTitleColor={BLACK_EIGHT}
                        onClickHandler={() => {
                            return
                        }}
                        style={{ fontSize: '0.9vw' }}
                    />
                </Box>
            </Box>
        </div>
    )
}
