import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import {
    PDFDownloadLink,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from '@react-pdf/renderer'
import './index.css'
// Theme

// Assets
import backButtonIcon from '../../../assets/images/back-button-icon.svg'

// Services/APIs
import AgencyTransactions from '../../../apis/agency/transactions'

// Redux
import { RootState } from '../../../redux/store'
import { TRANSACTIONS_ROUTE, dateFormat } from '../../../constants'
import PageNotFound from '../../404'
import paynestSmallLogo from '../../../assets/images/pynest-small-logo.png'
import { BLACK_EIGHT } from '../../../theme'

// Components

interface RouteParams {
    id: string
}
export const TransactionDetailContainer = () => {
    const { auth } = useSelector((state: RootState) => state)
    const { id } = useParams<RouteParams>()
    const [payeeInfoFName, setPayeeInfoFName] = useState<any>('')
    const [payeeInfoLName, setPayeeInfoLName] = useState<any>('')
    const [payeeInfoEmail, setPayeeInfoEmail] = useState<any>('')
    const [agencyName, setAgencyName] = useState<any>('')
    const [agencyAddress, setAgencyAddress] = useState<any>('')
    const [agencyEmail, setAgencyEmail] = useState<any>('')
    const [description, setDescription] = useState<any>('')
    const [paymentVia, setPaymentVia] = useState<any>('')

    const [agencyProfilePicture, setAgencyProfilePicture] = useState<any>('')
    const [payeeInfoPhoneNumber, setPayeeInfoPhoneNumber] = useState<any>(null)
    const [invoiceId, setInvoiceId] = useState<any>(null)
    const [orderId, setOrderId] = useState<any>(null)
    const [invoiceAmount, setInvoiceAmount] = useState<any>(null)
    const [dateOfIssue, setDateOfIssue] = useState<any>(null)
    const [qrUrl, setQrUrl] = useState<any>(null)
    const [isError, setisError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transactionStatus, setTransactionStatus] = useState<string>('')

    const handleBack = () => {
        window.location.href = '/agency/transactions'
    }

    useEffect(() => {
        if (id) {
            setLoading(true)
            AgencyTransactions.fetchTransactionDetailByTransactionId(
                id,
                auth?.token
            ).then(async (response: any) => {
                if (response?.data?.status) {
                    const transactionData = response?.data?.data[0]
                    setPaymentVia(transactionData?.type ?? '')
                    setDescription(transactionData?.description ?? '')
                    setAgencyName(transactionData?.agencyName ?? '')
                    setAgencyAddress(transactionData?.agencyAddress ?? '')
                    setAgencyEmail(transactionData?.agencyEmail ?? '')
                    setTransactionStatus(transactionData?.paymentStatus)
                    setAgencyProfilePicture(response?.data?.companyLogo)
                    setPayeeInfoFName(transactionData?.firstName)
                    setPayeeInfoLName(transactionData?.lastName)
                    setPayeeInfoEmail(transactionData?.email)
                    setPayeeInfoPhoneNumber(
                        `${transactionData?.countryCode ?? '-'}${
                            transactionData?.phone ?? '-'
                        } `
                    )
                    setInvoiceId(transactionData?.id)
                    setOrderId(transactionData?.orderId)
                    setInvoiceAmount(transactionData?.amount)
                    setDateOfIssue(transactionData?.createdAt)
                    setQrUrl(response?.data?.qrCode)
                    setLoading(false)
                } else {
                    setisError(true)
                    setLoading(false)
                }
            })
        }
    }, [])

    return (
        <>
            {loading ? (
                <div className="transaction-loader">
                    <CircularProgress size={20} style={{ color: 'black' }} />
                    {'Fetching Transaction Data'}
                </div>
            ) : isError ? (
                <PageNotFound
                    isCustomRoute={true}
                    customRoute={TRANSACTIONS_ROUTE}
                />
            ) : (
                <Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="left"
                    >
                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Transaction Details
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleBack}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                                marginLeft: '30px',
                                marginTop: '30px',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>
                    </Box>

                    {transactionStatus === 'Pending' ||
                    transactionStatus === 'Inactive' ||
                    transactionStatus === 'Deleted' ? (
                        <>
                            <PDFDownloadLink
                                className="downloadLink-button"
                                document={
                                    <InvoicePdf
                                        agencyProfilePicture={
                                            agencyProfilePicture
                                        }
                                        agencyName={agencyName}
                                        dateOfIssue={dateOfIssue}
                                        agencyAddress={agencyAddress}
                                        agencyEmail={agencyEmail}
                                        invoiceId={invoiceId}
                                        payeeInfoFName={payeeInfoFName}
                                        payeeInfoLName={payeeInfoLName}
                                        payeeInfoEmail={payeeInfoEmail}
                                        payeeInfoPhoneNumber={
                                            payeeInfoPhoneNumber
                                        }
                                        invoiceAmount={invoiceAmount}
                                        description={description}
                                        qrUrl={qrUrl}
                                    />
                                }
                                fileName="mydocument.pdf"
                            >
                                {({ loading, error }) => {
                                    console.log(error)
                                    return loading
                                        ? 'Loading...'
                                        : 'Download Invoice'
                                }}
                            </PDFDownloadLink>

                            <div className="main-conatiner">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 50,
                                    }}
                                >
                                    <img
                                        style={{ width: 200 }}
                                        src={agencyProfilePicture}
                                    />
                                </div>
                                <table
                                    width="100%"
                                    className="upper-table-container"
                                >
                                    <tr>
                                        <td width="50%">
                                            <div className="heading-text">
                                                Company Name
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyName}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="heading-text">
                                                Date / Time
                                            </div>
                                            <div className="dynamic-text">
                                                {dateFormat(dateOfIssue)}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            width="50%"
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <div className="heading-text">
                                                From
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyAddress ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyEmail ?? ''}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="heading-text">
                                                Invoice ID
                                            </div>
                                            <div className="dynamic-text">
                                                {invoiceId}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="50%">
                                            <div className="heading-text">
                                                Bill to
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoFName ?? ''}{' '}
                                                {payeeInfoLName ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoEmail ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoPhoneNumber ?? ''}
                                            </div>

                                            <div
                                                style={{ marginTop: 70 }}
                                                className="heading-text"
                                            >
                                                Invoice Amount
                                            </div>
                                            <div className="payment-text">
                                                {invoiceAmount
                                                    ? `AED ${invoiceAmount}.00`
                                                    : 'N/A'}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="scan-text">
                                                Scan To Pay
                                            </div>
                                            <img
                                                style={{ width: '150px' }}
                                                src={qrUrl}
                                            />
                                        </td>
                                    </tr>
                                </table>

                                <table width="100%" className="lower-table">
                                    <tr>
                                        <td width="100%">
                                            <div className="lower-table-header-container">
                                                <span className="description-text">
                                                    Description
                                                </span>

                                                <span className="amount-text">
                                                    Amount
                                                </span>

                                                <span className="total-text">
                                                    Total
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="100%">
                                            <div
                                                style={{
                                                    width: 'inherit',
                                                    borderRadius: '16px',
                                                    padding: '10px',
                                                }}
                                            >
                                                <span className="description-dynamic-text">
                                                    {description}
                                                </span>

                                                <span className="amount-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>

                                                <span className="total-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    height: '1px',
                                                    backgroundColor: '#707070',
                                                }}
                                            ></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 'inherit',
                                                    borderRadius: '16px',
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: '15px',
                                                        marginRight: 5,
                                                    }}
                                                    src={paynestSmallLogo}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 10,
                                                        fontFamily:
                                                            'Montserrat, sans-serif',
                                                    }}
                                                >
                                                    Powered by PayNest
                                                </span>
                                            </div>
                                            <div className="button-container">
                                                <span className="button-text">
                                                    Total
                                                </span>

                                                <span className="button-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </>
                    ) : transactionStatus === 'Completed' ? (
                        <>
                            <PDFDownloadLink
                                className="downloadLink-button"
                                document={
                                    <ReceiptPdf
                                        agencyProfilePicture={
                                            agencyProfilePicture
                                        }
                                        agencyName={agencyName}
                                        dateOfIssue={dateOfIssue}
                                        agencyAddress={agencyAddress}
                                        agencyEmail={agencyEmail}
                                        orderId={orderId}
                                        payeeInfoFName={payeeInfoFName}
                                        payeeInfoLName={payeeInfoLName}
                                        payeeInfoEmail={payeeInfoEmail}
                                        payeeInfoPhoneNumber={
                                            payeeInfoPhoneNumber
                                        }
                                        invoiceAmount={invoiceAmount}
                                        description={description}
                                        paymentVia={paymentVia}
                                        qrUrl={qrUrl}
                                    />
                                }
                                fileName="receipt.pdf"
                            >
                                {({ loading, error }) => {
                                    console.log(error)
                                    return loading
                                        ? 'Loading...'
                                        : 'Download Receipt'
                                }}
                            </PDFDownloadLink>

                            <div className="main-conatiner">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 50,
                                    }}
                                >
                                    <img
                                        style={{ width: 200 }}
                                        src={agencyProfilePicture}
                                    />
                                </div>
                                <table
                                    width="100%"
                                    className="upper-table-container"
                                >
                                    <tr>
                                        <td width="50%">
                                            <div className="heading-text">
                                                Company Name
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyName}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="heading-text">
                                                Date / Time
                                            </div>
                                            <div className="dynamic-text">
                                                {dateFormat(dateOfIssue)}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            width="50%"
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <div className="heading-text">
                                                From
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyAddress ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {agencyEmail ?? ''}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="heading-text">
                                                Receipt ID
                                            </div>
                                            <div className="dynamic-text">
                                                {orderId}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="50%">
                                            <div className="heading-text">
                                                Bill to
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoFName ?? ''}{' '}
                                                {payeeInfoLName ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoEmail ?? ''}
                                            </div>
                                            <div className="dynamic-text">
                                                {payeeInfoPhoneNumber ?? ''}
                                            </div>

                                            <div
                                                style={{ marginTop: 70 }}
                                                className="heading-text"
                                            >
                                                Invoice Amount
                                            </div>
                                            <div className="payment-text">
                                                {invoiceAmount
                                                    ? `AED ${invoiceAmount}.00`
                                                    : 'N/A'}
                                            </div>
                                        </td>
                                        <td width="50%" align="right">
                                            <div className="heading-text">
                                                Payment Info
                                            </div>
                                            <div className="dynamic-text">
                                                {description ?? ''}
                                            </div>

                                            <div
                                                style={{ marginTop: 70 }}
                                                className="heading-text"
                                            >
                                                Payment Via
                                            </div>
                                            <div className="dynamic-text">
                                                {paymentVia ?? ''}
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <table width="100%" className="lower-table">
                                    <tr>
                                        <td width="100%">
                                            <div className="lower-table-header-container">
                                                <span className="description-text">
                                                    Description
                                                </span>

                                                <span className="amount-text">
                                                    Amount
                                                </span>

                                                <span className="total-text">
                                                    Total
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="100%">
                                            <div
                                                style={{
                                                    width: 'inherit',
                                                    borderRadius: '16px',
                                                    padding: '10px',
                                                }}
                                            >
                                                <span className="description-dynamic-text">
                                                    {description}
                                                </span>

                                                <span className="amount-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>

                                                <span className="total-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                            <div
                                                style={{
                                                    height: '1px',
                                                    backgroundColor: '#707070',
                                                }}
                                            ></div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 'inherit',
                                                    borderRadius: '16px',
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        width: '15px',
                                                        marginRight: 5,
                                                    }}
                                                    src={paynestSmallLogo}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 10,
                                                        fontFamily:
                                                            'Montserrat, sans-serif',
                                                    }}
                                                >
                                                    Powered by PayNest
                                                </span>
                                            </div>
                                            <div className="button-container">
                                                <span className="button-text">
                                                    Total
                                                </span>

                                                <span className="button-dynamic-text">
                                                    {invoiceAmount
                                                        ? `AED ${invoiceAmount}.00`
                                                        : 'N/A'}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
            )}
        </>
    )
}

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: '30px',
        paddingLeft: '60px',
        paddingRight: '60px',
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: '70px',
    },
    smalLogo: {
        width: '15px',
        height: '15px',
        marginRight: 5,
    },
    headingText: {
        fontFamily: 'Helvetica-Oblique',
        fontSize: '10px',
        color: '#707070',
        fontWeight: 'semibold',
    },
    dynamicText: {
        fontFamily: 'Helvetica-Oblique',
        fontSize: '12px',
        color: '#252422',
        marginTop: '5px',
        fontWeight: 'bold',
    },
    buttonDynamicText: {
        color: 'black',
        fontFamily: 'Helvetica-Oblique',
        fontSize: '12px',
        textAlign: 'center',
    },
    buttonText: {
        color: '#707070',
        fontFamily: 'Helvetica-Oblique',
        fontSize: '12px',
        textAlign: 'center',
        marginRight: '5px',
    },
    container: {
        padding: 10,
    },
    paymentText: {
        fontFamily: 'Helvetica-Oblique',
        fontSize: '20px',
        color: '#252422',
        marginTop: '10px',
        fontWeight: 'bold',
    },
    tableText: {
        color: 'black',
        fontFamily: 'Helvetica-Oblique',
        fontSize: '12px',
        textAlign: 'center',
        fontWeight: 'extrabold',
        padding: '5px',
    },
    tableDynamicText: {
        color: 'black',
        fontFamily: 'Helvetica-Oblique',
        fontSize: '10px',
        textAlign: 'center',
        fontWeight: 'light',
        borderTop: '1px solid black',
        padding: '5px',
    },
    footerText: {
        marginRight: 5,
        fontSize: '10px',
    },
    containerDiv: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: '20px',
    },
    scanText: {
        fontFamily: 'Helvetica-Oblique',
        fontSize: '10px',
        color: '#707070',
    },
    qrLogo: {
        width: '100px',
    },
    customFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
        flexDirection: 'row',
    },
    customBorder: {
        border: '1px solid black',
    },
    tableContainerDiv: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: '20px',
    },
    customWidth: {
        width: '50%',
    },
})

const InvoicePdf = ({
    agencyProfilePicture,
    agencyName,
    dateOfIssue,
    agencyAddress,
    agencyEmail,
    invoiceId,
    payeeInfoFName,
    payeeInfoLName,
    payeeInfoEmail,
    payeeInfoPhoneNumber,
    invoiceAmount,
    description,
    qrUrl,
}: any) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src={agencyProfilePicture} />
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Company Name</Text>
                            <Text style={styles.dynamicText}>{agencyName}</Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Date / Time</Text>
                            <Text style={styles.dynamicText}>
                                {dateFormat(dateOfIssue)}
                            </Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>From</Text>
                            <Text style={styles.dynamicText}>
                                {agencyAddress ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {agencyEmail ?? ''}
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Invoice ID</Text>
                            <Text style={styles.dynamicText}>{invoiceId}</Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Bill to</Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoFName ?? ''} {payeeInfoLName ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoEmail ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoPhoneNumber ?? ''}
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.scanText}>Scan To Pay</Text>

                            <Image style={styles.qrLogo} src={qrUrl} />
                        </div>
                    </View>

                    <Text style={styles.headingText}>Invoice Amount</Text>
                    <Text style={styles.paymentText}>
                        {invoiceAmount ? `AED ${invoiceAmount}.00` : 'N/A'}
                    </Text>

                    <View style={styles.tableContainerDiv}>
                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Description</Text>
                            <Text style={styles.tableDynamicText}>
                                {description}
                            </Text>
                        </div>

                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Amount</Text>
                            <Text style={styles.tableDynamicText}>
                                {invoiceAmount
                                    ? `AED ${invoiceAmount}.00`
                                    : 'N/A'}
                            </Text>
                        </div>

                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Total</Text>
                            <Text style={styles.tableDynamicText}>
                                {invoiceAmount
                                    ? `AED ${invoiceAmount}.00`
                                    : 'N/A'}
                            </Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customFlex}>
                            <Image
                                style={styles.smalLogo}
                                src={paynestSmallLogo}
                            />
                            <Text style={styles.footerText}>
                                Powered by PayNest
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <div style={styles.customFlex}>
                                <Text style={styles.buttonText}>Total:</Text>

                                <Text style={styles.buttonDynamicText}>
                                    {invoiceAmount
                                        ? `AED ${invoiceAmount}.00`
                                        : 'N/A'}
                                </Text>
                            </div>
                        </div>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const ReceiptPdf = ({
    agencyProfilePicture,
    agencyName,
    dateOfIssue,
    agencyAddress,
    agencyEmail,
    orderId,
    payeeInfoFName,
    payeeInfoLName,
    payeeInfoEmail,
    payeeInfoPhoneNumber,
    invoiceAmount,
    description,
    qrUrl,
    paymentVia,
}: any) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src={agencyProfilePicture} />
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Company Name</Text>
                            <Text style={styles.dynamicText}>{agencyName}</Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Date / Time</Text>
                            <Text style={styles.dynamicText}>
                                {dateFormat(dateOfIssue)}
                            </Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>From</Text>
                            <Text style={styles.dynamicText}>
                                {agencyAddress ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {agencyEmail ?? ''}
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Receipt ID</Text>
                            <Text style={styles.dynamicText}>{orderId}</Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Bill to</Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoFName ?? ''} {payeeInfoLName ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoEmail ?? ''}
                            </Text>
                            <Text style={styles.dynamicText}>
                                {payeeInfoPhoneNumber ?? ''}
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <Text style={styles.headingText}>Payment Info</Text>
                            <Text style={styles.dynamicText}>
                                {description ?? ''}
                            </Text>

                            <Text style={styles.headingText}>Payment Via</Text>
                            <Text style={styles.dynamicText}>
                                {paymentVia ?? ''}
                            </Text>
                        </div>
                    </View>

                    <Text style={styles.headingText}>Invoice Amount</Text>
                    <Text style={styles.paymentText}>
                        {invoiceAmount ? `AED ${invoiceAmount}.00` : 'N/A'}
                    </Text>

                    <View style={styles.tableContainerDiv}>
                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Description</Text>
                            <Text style={styles.tableDynamicText}>
                                {description}
                            </Text>
                        </div>

                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Amount</Text>
                            <Text style={styles.tableDynamicText}>
                                {invoiceAmount
                                    ? `AED ${invoiceAmount}.00`
                                    : 'N/A'}
                            </Text>
                        </div>

                        <div style={styles.customBorder}>
                            <Text style={styles.tableText}>Total</Text>
                            <Text style={styles.tableDynamicText}>
                                {invoiceAmount
                                    ? `AED ${invoiceAmount}.00`
                                    : 'N/A'}
                            </Text>
                        </div>
                    </View>

                    <View style={styles.containerDiv}>
                        <div style={styles.customFlex}>
                            <Image
                                style={styles.smalLogo}
                                src={paynestSmallLogo}
                            />
                            <Text style={styles.footerText}>
                                Powered by PayNest
                            </Text>
                        </div>

                        <div style={styles.customWidth}>
                            <div style={styles.customFlex}>
                                <Text style={styles.buttonText}>Total:</Text>

                                <Text style={styles.buttonDynamicText}>
                                    {invoiceAmount
                                        ? `AED ${invoiceAmount}.00`
                                        : 'N/A'}
                                </Text>
                            </div>
                        </div>
                    </View>
                </View>
            </Page>
        </Document>
    )
}
