//packages blocks
import React, { useEffect, useReducer, useState } from 'react'
import { Typography, CircularProgress, TableContainer } from '@mui/material'
//common blocks
import { SearchInput } from '../common/search'
//styles, utils, assets and api blocks
import { BLACK } from '../../theme'
import { ManagementTable } from '../managementTable'
import '../../App.css'
import AgentService from '../../apis/agent/users'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import UserManagementServices from '../../apis/agency/user-management'

const debug = Boolean(process.env.REACT_APP_DEBUG)

export const AgentsTableContainer = () => {
    const { auth } = useSelector((state: RootState) => state)

    const [loading] = useState(false)
    const [page, setPage] = useState(0)
    const [totalCount] = useState(null)
    const [, setSelectedIds] = useState([])
    const [startEntry] = useState(1)
    const [endEntry] = useState(10)
    const [agents, setAgents] = useState([])
    const [, setSearchValue] = useState('')
    const [, setIsEditedItem] = useState(null)
    const [, setIsAddStudentDialogVisible] = useState(false)
    const [{ search, searchError }, setData] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            search: '',
            searchError: false,
        }
    )

    const handleSearching =
        (props: any) =>
        (event: { target: { value: React.SetStateAction<string> } }) => {
            setSearchValue(event.target.value)
            setData({
                search: event.target.value,
                searchError: false,
            })
        }

    const handleSelection = (ids: React.SetStateAction<never[]>) => {
        setSelectedIds(ids)
    }

    const handlePage = (pageNumber: React.SetStateAction<number>) =>
        setPage(pageNumber)

    useEffect(() => {
        if (auth.user) {
            UserManagementServices.getAllAgentsForAgency(
                auth.user.agencyId,
                auth?.token
            )
                .then((response) => {
                    setAgents(response.data)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not get list of agents. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                })
        }
        // eslint-disable-next-line
    }, [])

    const AGENTS_MANAGEMENT_COLUMNS = [
        //{ id: "id", numeric: false, disablePadding: true, label: "ID" },
        {
            id: 'agentName',
            numeric: false,
            disablePadding: true,
            label: `Agent Name`,
        },
        {
            id: 'paymentsReceived',
            numeric: false,
            disablePadding: true,
            label: 'Payments Received',
        },
        {
            id: 'numberOfPayments',
            numeric: false,
            disablePadding: true,
            label: 'Number Of Payments Received',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
    ]

    const viewItem = async (id: any) => {
        return
    }

    const editTransaction = (item: React.SetStateAction<null>) => {
        setIsEditedItem(item)
        setIsAddStudentDialogVisible(true)
    }

    const deleteItem = async (id: any) => {
        return
    }
    return (
        <div className="home-table-card">
            <div className="home-table-header">
                <Typography variant="h4" style={{ color: BLACK }}>
                    List of Agents
                </Typography>
                {/* <div className="recent-transaction-searchbox">
                    <SearchInput
                        placeholder="Search agents"
                        disabled={false}
                        hideLabel={true}
                        error={searchError}
                        value={search}
                        onChange={handleSearching}
                        label={'Search Agent ID'}
                        name="search"
                        height={35}
                    />
                </div> */}
            </div>
            <div>
                {loading ? (
                    <div
                        style={{
                            height: '400px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        fetching agents
                    </div>
                ) : (
                    <TableContainer
                        style={{
                            marginTop: 5,
                        }}
                    >
                        <ManagementTable
                            handleSelection={handleSelection}
                            pageNumber={page}
                            handlePage={handlePage}
                            totalCount={totalCount}
                            tableFor="agents"
                            data={agents}
                            editItem={editTransaction}
                            deleteItem={deleteItem}
                            columns={AGENTS_MANAGEMENT_COLUMNS}
                            viewItem={viewItem}
                            startEntry={startEntry}
                            endEntry={endEntry}
                            hidePagination={true}
                        />
                    </TableContainer>
                )}
            </div>
        </div>
    )
}
