// packages block
import { TableRow, TableCell, Typography, Avatar } from '@mui/material'
import {
    BLACK_EIGHT,
    LIGHT_GREEN,
    ORANGE_ONE,
    ORANGE_TWO,
} from '../../../theme'
import { useState } from 'react'
import AgencyPaymentLinks from '../../../apis/agency/paymentLinks'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { UserStatusSelect } from '../../common/userStatusSelector'
import { TRANSACTION_ACTIVE_INACTIVE_DUMMY_DATA } from '../../../constants'

export const PendingPaymentLinkTransactionRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const { auth } = useSelector((state: RootState) => state)

    const [userStatus, setUserStatus] = useState<string>(row['paymentStatus'])

    const [rowStatus, setRowStatus] = useState(row['paymentStatus'])

    const handleChange = (event: any) => {
        AgencyPaymentLinks.updatePaymentLinkStatus(
            row?.id,
            event?.target.value,
            auth?.token
        ).then((res) => {
            if (res?.status) {
                setUserStatus(res?.data)
                setRowStatus(res?.data)
            }
        })
    }

    const payeeFirstName = row?.firstName ?? ''
    const payeeLastName = row?.lastName ?? ''
    const agentFirstName = row?.agentFirstName ?? ''
    const agentLastName = row?.agentLastName ?? ''
    const amount = row?.amount ?? ''

    return (
        <TableRow
            hover
            style={{ padding: '3px 0px' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row?.name}
            selected={isItemSelected}
        >
            <TableCell
                align="center"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Avatar className="table-avatar">
                    {agentFirstName[0]?.toUpperCase() ||
                        '' + ' ' + agentLastName[0]?.toUpperCase() ||
                        ''}
                </Avatar>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {agentFirstName || agentLastName
                            ? `${agentFirstName}` + ' ' + `${agentLastName}`
                            : 'N/A'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    padding: 10,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className="table-avatar">
                            {row?.firstName || row?.lastName
                                ? `${row?.firstName[0]?.toUpperCase()}` +
                                  ' ' +
                                  `${row?.lastName[0]?.toUpperCase()}`
                                : 'N/A'}
                        </Avatar>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.8vw',
                                }}
                            >
                                {payeeFirstName || payeeLastName
                                    ? `${payeeFirstName}` +
                                      ' ' +
                                      `${payeeLastName}`
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </div>
                </Typography>
            </TableCell>

            <TableCell align="center" style={{ fontWeight: 500, padding: 10 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.description ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    align="center"
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {amount ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: ORANGE_TWO, fontSize: '0.8vw' }}
                >
                    {rowStatus ?? ''}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.type === 'STANDALONE'
                        ? 'Payment Link'
                        : row?.type === 'AGENT'
                        ? 'QR Code'
                        : '---' ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    <UserStatusSelect
                        disabled={userStatus === 'Deleted' ? true : false}
                        value={userStatus}
                        onChange={handleChange}
                        label={''}
                        name={''}
                        list={TRANSACTION_ACTIVE_INACTIVE_DUMMY_DATA}
                    />
                </Typography>
            </TableCell>
        </TableRow>
    )
}
