import { makeStyles, styled } from '@mui/styles'
import {
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    InputBase,
} from '@mui/material'
import React from 'react'
import { ORANGE_TWO } from '../../theme'

const useStyles = makeStyles(() => ({
    formControl: {
        height: 36.5,
        margin: 1,

        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 10,
            borderColor: '#006e7d',
        },

        '& .MuiOutlinedInput-root': {
            marginRight: 1,
        },
    },
}))

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: '',
    },
    '& .MuiInputBase-input': {
        borderRadius: 20,
        position: 'relative',
        backgroundColor: '#636365',
        color: 'white',
        fontSize: 10,
        padding: '4px 15px 4px 15px',
        transition: '',
    },

    '& .MuiSelect-icon': {
        fill: 'white',
    },
}))

export const UserStatusSelect = ({
    value,
    onChange,
    required = false,
    disabled = false,
    list,
    label,
    error,
    name,
    errorMessage = false,
}: any) => {
    const classes = useStyles()
    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation()
    }

    return (
        <FormControl
            variant="outlined"
            error={error}
            fullWidth
            sx={{ width: 125 }}
            className={classes.formControl}
        >
            <InputLabel
                style={{ marginTop: value === '' ? -8 : 0 }}
                id="demo-simple-select-outlined-label"
                component={'div'}
            >
                {label}
            </InputLabel>

            <Select
                disabled={disabled}
                onClick={(e: React.MouseEvent) => handleClick(e)}
                required={required}
                style={{ height: 36.5, width: 125 }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value}
                onChange={onChange}
                label={label}
                input={
                    <BootstrapInput
                        sx={{
                            '& .MuiInputBase-input': {
                                backgroundColor:
                                    value === 'Pending'
                                        ? '#74ba6b !important'
                                        : value === 'Deleted'
                                        ? '#da5e60 !important'
                                        : '#636365 !important',
                                fontSize: '12px !important',
                            },
                        }}
                    />
                }
            >
                {list?.map(({ value, label }: any) => (
                    <MenuItem key="itemValue" value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            <span style={{ fontSize: '0.65vw', paddingLeft: 10, color: 'red' }}>
                {errorMessage}
            </span>
        </FormControl>
    )
}
