export const arabic = {
    text_dashboard: 'لوحة القياد',
    text_students: 'الطلاب',
    text_student_management: 'إدارة الطلاب',
    text_balance_management: 'إدارة التوازن',
    text_user_management: 'إدارةالمستخدم',
    text_school_management: 'إدارة المدرسة',
    text_recent_transactions: 'التحويلات الاخيرة',
    text_settings: 'إعدادات',
    text_logout: 'تسجيل خروج',
    text_english: 'إنجليزي',
    text_arabic: 'عربي',
    text_school_fee_management_dashboard: 'لوحة إدارة الرسوم المدرسية',
    text_welcome_back: 'مرحبًا بعودتك!',
    text_fetching_data: 'جلب البيانات',
    text_notifications: 'إشعارات',
    text_quick_stats: 'احصائيات سريعة',
    text_total_school_fees_received_vs_pending:
        'إجمالي الرسوم المدرسية المستلمة مقابل المعلقة',
    text_select_period: 'حدد الفترة',
    text_today: 'اليوم',
    text_last_week: 'الأسبوع الماضي',
    text_last_month: 'الشهر الماضي',
    text_last_6_months: 'آخر 6 أشهر',
    text_no_pending_receiving_fee_currently: 'لا يوجد رسوم استلام معلقة حاليا',
    text_received: 'تلقى',
    text_AED: 'درهم إماراتي',
    text_pending: 'قيد الانتظار',
    text_school_fees_received_vs_pending:
        'الرسوم المدرسية المستلمة مقابل المعلقة',
    text_fetching_details: 'إحضار التفاصيل',
    text_fetching_transactions: 'إحضار المعاملات',
    text_get_help: 'احصل على مساعدة',
    text_online_users: 'مستخدمين على الهواء',
    text_fetching_user: 'إحضار المستخدم',
    text_no_user_is_online: 'لا يوجد مستخدم متصل',
    text_fetching_student_data: 'إحضار بيانات الطالب',
    text_add_student: 'أضف طالب',
    text_add_single_student: 'اضافة طالب واحد',
    text_add_multiple_students: 'أضف عدة طلاب',
    text_student_create: 'طالب خلق',
    text_student: 'طالب علم',
    text_adding_multiple_students: 'إضافة عدة طلاب',
    text_delete_school: 'حذف المدرسة',
    text_delete_students: 'احذف الطلاب',
    text_school: 'المدرسة',
    text_user: 'المستعمل',
    text_delete_user: 'مسح المستخدم',
    text_this_will_delete_the_school_do_you_still_want_to_proceed:
        'سيؤدي هذا إلى حذف المدرسة. هل مازلت تريد المتابعة؟',
    text_this_will_delete_the_use_do_you_want_to_proceed:
        'سيؤدي هذا إلى حذف المستخدم. هل تريد المتابعة؟',
    "text_this_will_delete_all_student's_data_including_student's_invoices_details_and_payment_history_do_you_still_want_to_proceed":
        'سيؤدي هذا إلى حذف جميع بيانات الطالب بما في ذلك تفاصيل فواتير الطالب وسجل الدفع. هل ما زلت تريد المتابعة؟',
    text_cancel: 'يلغي',
    text_confirm: 'يتأكد',
    text_fetching_and_copying_data_to_database:
        'إحضار ونسخ البيانات إلى قاعدة البيانات',
    text_step_one: 'الخطوةالاولى',
    text_step_two: 'الخطوة الثانية',
    text_successfully_updated: 'تم التحديث بنجاح!',
    text_successfully_created: 'تم إنشاؤه بنجاح!',
    text_successfully: 'بنجاح!',
    text_changes_saved_successfully: 'التغييرات التي تم حفظها بنجاح',
    text_ok: 'موافق',
    text_continue: 'يكمل',
    text_first_name: 'الاسم الاول',
    text_last_name: 'الكنية',
    text_date_of_birth: 'تاريخ الولادة',
    text_gender: 'جنس',
    text_emirates_id_number: 'رقم الهوية الإماراتية',
    text_nationality: 'جنسية',
    text_religion: 'دِين',
    text_parent_passport_number: 'رقم جواز سفر ولي الأمر',
    text_address_and_contact_detail: 'العنوان وتفاصيل الاتصال',
    text_area: 'منطقة',
    text_address: 'تبوك',
    text_phone_number: 'رقم الهاتف',
    text_other_number: 'رقم آخر',
    text_email_address: 'عنوان البريد الالكترونى',
    "text_add_scanned_image_of_student's_uae_id_card":
        'أضف صورة ممسوحة ضوئيًا لبطاقة الهوية الإماراتية للطالب',
    'text_drag_&_drop': 'السحب والإفلات',
    text_your_files_or: 'ملفاتك أو',
    text_browse: 'تصفح',
    text_grade_details: 'تفاصيل التقدير',
    text_student_Id: 'هوية الطالب',
    text_date_of_admission: 'تاريخ القبول',
    text_grade: 'صف دراسي',
    text_section: 'الجزء',
    text_personal_detail: 'التفاصيل الشخصية',
    "text_parent's_detail": 'تفاصيل الوالد',
    text_update_balance: 'تحديث الرصيد',
    text_add_balance: 'إضافة رصيد',
    text_upload: 'تحميل',
    text_choose_file: 'اختر ملف',
    text_download_our_predefined_template: 'قم بتنزيل نموذجنا المحدد مسبقًا',
    "text_If_you_don't_have_an_excel_file_to_store_student_data_you_can":
        'إذا لم يكن لديك ملف Excel لتخزين بيانات الطلاب ، فيمكنك ذلك',
    text_delete: 'حذف',
    text_fetching_balances: 'جلب الأرصدة',
    text_balance: 'الرصيد',
    text_update_single_student_balance: 'تحديث رصيد الطالب الواحد',
    text_add_single_invoice: 'أضف فاتورة واحدة',
    text_update_bulk_student_balance: 'تحديث رصيد الطلاب المجمع',
    text_add_multiple_invoices: 'إضافة فواتير متعددة',
    text_add_invoice: 'أضف الفاتورة',
    text_notifyParent: 'إخطار الوالدين',
    text_notify_parents: 'إخطار الوالدين',
    text_checking_students_data_this_may_take_some_time:
        'التحقق من بيانات الطلاب ، قد يستغرق هذا بعض الوقت!',
    text_name: 'اسم',
    text_send_notification: 'إرسال الإخطار',
    text_view: 'رأي',
    text_edit_student: 'تحرير الطالب',
    text_edit_balance: 'تحرير الرصيد',
    text_edit_user: 'تحرير العضو',
    text_search_by_agent_name: 'البحث حسب اسم الوكيل',
    text_update_bulk: 'تحديث مجمع',
    text_contact: 'اتصال',
    text_title: 'عنوان',
    text_description: 'وصف',
    text_created_at: 'أنشئت في',
    text_nofifications: 'إشعارات',
    text_fetching_notifications: 'إحضار الإخطارات',
    text_total_balance_amount: 'المبلغ الإجمالي للرصيد (درهم)',
    text_due_date: 'تاريخ الاستحقاق',
    text_status: 'حالة',
    text_access: 'وصول',
    text_transaction_id: 'رقم المعاملة',
    text_ref_no: 'مصدر رقم',
    text_amount: 'مقدار',
    text_date: 'تاريخ',
    text_search_students_or_transactions_id:
        'البحث عن الطلاب أو معرف المعاملات',
    text_fetching_users: 'جلب المستخدمين',
    text_add_user: 'إضافة مستخدم',
    text_male: 'ذكر',
    text_female: 'أنثى',
    text_other: 'آخر',
    text_emirates_id: 'هويه الإمارات',
    text_passport: 'جواز سفر',
    text_region: 'منطقة',
    text_email: 'البريد الإلكتروني',
    text_password: 'كلمة المرور',
    text_official_details: 'التفاصيل الرسمية',
    text_school_id: 'معرف المدرسة',
    text_role: 'دور',
    text_employee_id: 'هوية الموظف',
    text_designation: 'تعيين',
    text_add_scanned_image_of_User_uae_id_card:
        'أضف صورة ممسوحة ضوئيًا لبطاقة هوية الإمارات العربية المتحدة الخاصة بالمستخدم',
    text_search_by_user_name: 'البحث عن طريق اسم المستخدم',
    text_search_transaction_id: 'البحث عن معرف المعاملة',
    text_fetching_user_info: 'إحضار معلومات المستخدم',
    text_account_setting: 'إعدادات الحساب',
    text_change_password: 'غير كلمة السر',
    text_edit_profile: 'تعديل الملف الشخصي',
    text_save_changes: 'حفظ التغييرات',
    text_reset_changes: 'إعادة تعيين التغييرات',
    text_current_password: 'كلمة المرور الحالية',
    text_new_password: 'كلمة السر الجديدة',
    text_confirm_new_password: 'تأكيد كلمة المرور الجديدة',
    text_records_not_found: 'السجلات غير موجودة',
    text_error_in_searching_transactions: 'خطأ في البحث عن المعاملات',
    text_something_went_wrong_try_again_later:
        'حدث خطأ ما ، حاول مرة أخرى في وقت لاحق',
    text_error_in_deleting_student_please_try_again:
        'خطأ في حذف الطالب. حاول مرة اخرى',
    text_error_in_filter_by_student: 'خطأ في التصفية حسب الطالب',
    text_no_unique_student_found: 'لم يتم العثور على طالب فريد',
    text_students_added_successfully: 'تمت إضافة الطلاب بنجاح',
    text_error_in_multiple_file_uploading: 'خطأ في تحميل ملفات متعددة',
    text_error_in_searching_students: 'خطأ في البحث عن الطلاب',
    text_student_deleted_successfully: 'تم حذف الطالب بنجاح',
    text_your_csv_must_be_10MB_or_greater_than_10MB_try_again:
        'يجب أن يكون ملف CSV الخاص بك 10 ميغا بايت أو أكبر من 10 ميغا بايت ، حاول مرة أخرى',
    text_balance_updated_successfully: 'تم تحديث الرصيد بنجاح',
    text_error_in_updating_student_please_try_again:
        'خطأ في تحديث الطالب. حاول مرة اخرى',
    text_error_in_adding_student_please_try_again:
        'خطأ في إضافة الطالب. حاول مرة اخرى',
    text_student_updated_successfully: 'تم تحديث الطالب بنجاح',
    text_error_in_fetching_student_detail: 'خطأ في جلب تفاصيل الطالب',
    text_image_size_is_greater_than_2MB_kindly_use_2MB_for_profile_image:
        'حجم الصورة أكبر من 2 ميغا بايت ، يرجى استخدام 2 ميغا بايت لصورة الملف الشخصي',
    text_password_updated_successfully: 'تم تحديث كلمة السر بنجاح',
    text_reset_changes_successfully: 'إعادة تعيين التغييرات بنجاح!',
    text_transaction_deleted_successfully: 'تم حذف المعاملة بنجاح',
    text_error_in_deleting_transactions_please_try_again:
        'خطأ في حذف المعاملات. حاول مرة اخرى',
    text_class: 'فصل',
    text_total_payable_fee: 'إجمالي الرسوم المستحقة الدفع',
    text_invoice_id: 'هوية صوتية',
    text_Fee_month: 'شهر الرسوم',
    text_student_name: 'أسم الطالب',
    text_recent_transaction_detail: 'تفاصيل المعاملة الأخيرة',
    text_error_in_searching_users: 'خطأ في البحث عن المستخدمين',
    text_error_in_deleting_user_please_try_again:
        'خطأ في حذف المستخدم. حاول مرة اخرى',
    text_user_deleted_successfully: 'تم حذف المستخدم بنجاح',
    text_error_in_adding_user_please_try_again:
        'خطأ في إضافة المستخدم. حاول مرة اخرى',
    text_error_in_searching_schools: 'خطأ في البحث عن المدارس',
    text_error_in_deleting_school_please_try_again:
        'خطأ في حذف المدرسة. حاول مرة اخرى',
    text_error_in_fetching_user_detail: 'خطأ في جلب تفاصيل المستخدم',
    text_sign_in: 'تسجيل الدخول',
    text_please_enter_your_registered_email_id_and_password:
        'الرجاء إدخال معرف البريد الإلكتروني المسجل وكلمة المرور',
    text_forgot_password: 'هل نسيت كلمة السر ؟',
    text_i_accept_terms_and_conditions: 'أنا أوافق على الأحكام و الشروط',
    text_forgot_password_simple: 'هل نسيت كلمة السر ',
    text_please_enter_your_registered_email_id_To_reset_password:
        'الرجاء إدخال معرف البريد الإلكتروني المسجل لإعادة تعيين كلمة المرور',
    text_please_enter_verification_code_from_your_phone_or_email:
        'الرجاء إدخال رمز التحقق من هاتفك أو بريدك الإلكتروني',
    text_resend_otp: 'إعادة إرسال Otp',
    text_please_enter_new_password: 'الرجاء إدخال كلمة مرور جديدة',
    text_search_by_school_id_or_school_name:
        'البحث عن طريق معرف المدرسة أو اسم المدرسة',
    text_add_school: 'أضف مدرسة',
    text_edit: 'يحرر',
    text_school_deleted_successfully: 'تم حذف المدرسة بنجاح ',
    text_error_in_fetching_school_detail: 'خطأ في جلب تفاصيل المدرسة',
    text_school_details: 'تفاصيل المدرسة',
    text_edit_school: 'تحرير المدرسة',
    text_merchant_id_number: 'رقم معرف التاجر',
    text_school_added_successfully: 'تمت إضافة المدرسة بنجاح',
    text_error_in_adding_school_please_try_again:
        'خطأ في إضافة المدرسة. حاول مرة اخرى',
    text_school_updated_successfully: 'تم تحديث المدرسة بنجاح',
    text_error_in_updating_school_please_try_again:
        'خطأ في تحديث المدرسة. حاول مرة اخرى',
    text_school_name: 'اسم المدرسة',
    text_school_address: 'عنوان المدرسة',
    text_merchant_id: 'معرف التاجر',
    text_vat: 'ضريبة القيمة المضافة',
    text_paynest_fee: 'رسوم Paynest',
    text_fetching_schools_data: 'إحضار بيانات المدارس',
    text_parent: 'الأبوين',
    text_payee_type: 'نوع المدفوع لأمره',
    text_payee_id: 'معرف المدفوع لأمره',
    text_is_required: 'مطلوب',
    text_please_wait_for_a_moment: 'يرجى الانتظار لحظة',
    text_bank_management: 'ادارة الحساب',
    text_bank_added_successfully: 'تمت إضافة البنك بنجاح',
    text_error_in_adding_bank_please_try_again:
        'خطأ في إضافة بنك. حاول مرة اخرى',
    text_bank_updated_successfully: 'تم تحديث البنك بنجاح',
    text_error_in_updating_bank_please_try_again:
        'خطأ في تحديث البنك. حاول مرة اخرى',
    text_bank_details: 'التفاصيل المصرفية',
    text_bank_deleted_successfully: 'تم حذف البنك بنجاح',
    text_error_in_deleting_bank_please_try_again:
        'خطأ في حذف البنك. حاول مرة اخرى',
    text_error_in_fetching_bank_detail: 'خطأ في جلب التفاصيل المصرفية',
    text_edit_bank: 'تحرير البنك',
    text_delete_bank: 'حذف البنك',
    text_search_by_bank_id_or_bank_name:
        'البحث عن طريق معرف البنك أو اسم البنك',
    text_add_bank: 'أضف البنك',
    text_error_in_searching_banks: 'خطأ في البحث عن البنك',
    text_fetching_banks_data: 'إحضار بيانات البنوك',
    text_bank: 'بنك',
    text_bank_name: 'اسم البنك',
    text_bank_swift_code: 'رمز Swift للمصرف',
    text_bank_address: 'عنوان البنك',
    text_account_number: 'رقم حساب',
    text_account_title: 'عنوان الحساب',
    text_account_iban: 'حساب IBAN',
    text_this_will_delete_the_account_do_you_want_to_proceed:
        'سيؤدي هذا إلى حذف الحساب. هل تريد المتابعة؟',
    text_balances: 'أرصدة',
    text_users: 'المستخدمون',
    text_accounts: 'حسابات',
    text_transactions: 'المعاملات',
    text_schools: 'المدارس',
    text_agent_name: 'اسم العميل',
    text_customer_name: 'اسم الزبون',
    text_payment_via: 'الدفع عن طريق',
    text_transaction_reference_no: 'الرقم المرجعي للمعاملة',
}
