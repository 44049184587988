import React from 'react'
import ReactPaginate from 'react-paginate'
import './footer.css'
import { Typography } from '@mui/material'
export const Footer = ({
    total,
    handlePage,
    page,
    startEntry,
    endEntry,
    customLimit = 10,
    tableFor,
}: any) => {
    const handlePageClick = (data: { selected: any }) => {
        handlePage(data.selected)
    }

    return (
        <>
            {tableFor === 'activeAgencyUsersTable' && total === 1 ? (
                <></>
            ) : (
                <div className="table-footer">
                    <Typography>
                        Showing{' '}
                        {tableFor === 'activeAgencyUsersTable'
                            ? startEntry - 1
                            : startEntry}{' '}
                        to{' '}
                        {tableFor === 'activeAgencyUsersTable'
                            ? endEntry - 1
                            : endEntry}{' '}
                        of{' '}
                        {tableFor === 'activeAgencyUsersTable'
                            ? total - 1
                            : total}{' '}
                        entries
                    </Typography>
                    <ReactPaginate
                        forcePage={page}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(total / customLimit)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                        previousClassName="previous-button"
                        nextLinkClassName="next-button"
                        pageLinkClassName={'page-link'}
                        activeLinkClassName="active-page-link"
                    />
                </div>
            )}
        </>
    )
}
