import { makeStyles } from '@mui/styles'
import { BLACK_EIGHT, WHITE } from '../../theme'

export const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    tableContainer: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '20px',
        width: '99%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 10,
        marginTop: 10,
    },
    paper: {
        width: '100%',
        marginBottom: 2,
    },
    table: {
        minWidth: 750,
        width: '100%',
        height: '100%',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    actionButton: {
        height: 15,
        width: 35,
        border: '1px solid #252422',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',

        '& .MuiSvgIcon-root': {
            color: '#252422',
        },
    },

    thead: {
        '& .MuiTableCell-root': {
            borderBottom: '0px solid black',
        },
        '& .MuiTableSortLabel-root': {
            color: WHITE,
            fontWeight: 'bold',
        },

        '& .MuiSvgIcon-root': {
            color: WHITE,
        },
    },

    tbody: {
        '& .MuiTableRow-root': {
            borderBottom: '1px solid black',
        },

        '& .MuiTableRow-root:last-child': {
            borderBottom: '0px solid black',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'inherit',
        },

        //     '& .MuiTableCell-root:last-child': {
        //         borderBottom: "0px solid black",
        // }
    },
}))

export const useToolbarStyles = makeStyles(() => ({
    root: {
        border: '1px solid black',
        paddingLeft: 2,
        paddingRight: 1,
    },
    highlight: {},
    title: {
        flex: '1 1 100%',
    },
}))
