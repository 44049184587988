import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, CircularProgress, TableContainer } from '@mui/material'
import { useSelector } from 'react-redux'

// Redux
import { RootState } from '../../redux/store'

// Theme
import { BLACK } from '../../theme'

// Services/APIs
import AgencyTransactions from '../../apis/agency/transactions'
import AgentTransactions from '../../apis/agent/transactions'

// Components
import { ManagementTable } from '../managementTable'

// Styles/CSS
import '../../App.css'

const debug = Boolean(process.env.REACT_APP_DEBUG)

interface TransactionsTableContainerProps {
    isAgentTransactions?: boolean
    id?: number | string
}
export const TransactionsTableContainer: React.FC<
    TransactionsTableContainerProps
> = ({ isAgentTransactions = false, id }: any) => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const { t } = useTranslation()
    const [loading] = useState(false)
    const [page, setPage] = useState(0)
    const [, setSelectedIds] = useState([])
    const [totalCount, setTotalCount] = useState(null)
    const [startEntry, setStartEntry] = useState(1)
    const [endEntry, setEndEntry] = useState(10)
    const [transactions, setTransactions] = useState([])
    const [isAgent, setIsAgent] = useState(false)

    const [, setIsEditedItem] = useState(null)
    const [, setIsAddStudentDialogVisible] = useState(false)

    // eslint-disable-next-line
    let limit = 10

    const handleSelection = (ids: React.SetStateAction<never[]>) => {
        setSelectedIds(ids)
    }

    const handlePage = (pageNumber: React.SetStateAction<number>) =>
        setPage(pageNumber)

    const AGENCY_TRANSACTIONS_COLUMNS = [
        {
            id: 'agentName',
            numeric: false,
            disablePadding: true,
            label: 'Agent Name',
        },
        {
            id: 'customerName',
            numeric: false,
            disablePadding: true,
            label: 'Customer Name',
        },
        {
            id: 'payInfoType',
            numeric: false,
            disablePadding: true,
            label: 'Payment Info',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: true,
            label: 'Amount',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'paymentVia',
            numeric: false,
            disablePadding: true,
            label: 'Payment Via',
        },
        {
            id: 'transactionRefNo',
            numeric: false,
            disablePadding: true,
            label: 'Transaction Reference No.',
        },
    ]
    const AGENT_TRANSACTIONS_COLUMN = [
        {
            id: 'customerName',
            numeric: false,
            disablePadding: true,
            label: 'Customer Name',
        },
        {
            id: 'paymentVia',
            numeric: false,
            disablePadding: true,
            label: 'Payment Via',
        },
        {
            id: 'transactionRefNo',
            numeric: false,
            disablePadding: true,
            label: 'Transaction Reference No.',
        },
        {
            id: 'paymentType',
            numeric: false,
            disablePadding: true,
            label: 'Payment Info',
        },
        {
            id: 'amount',
            numeric: false,
            disablePadding: true,
            label: 'Amount',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
    ]
    useEffect(() => {
        if (auth.user && pathname.startsWith('/agent')) {
            setIsAgent(true)
            AgentTransactions.getTransactionsByAgent(
                auth.user.id,
                page,
                limit,
                auth?.token
            )
                .then((response: any) => {
                    if (response.status) {
                        setTransactions(response.data?.rows)
                        setTotalCount(response?.data.count)
                        setStartEntry(page === 0 ? 1 : page * limit)
                        setEndEntry(
                            (page + 1) * limit > response?.data.count
                                ? response.data.count
                                : (page + 1) * limit
                        )
                    }
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not get list of transactions. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                })
        } else if (auth.user && pathname.startsWith('/agency/users')) {
            if (isAgentTransactions) {
                setIsAgent(true)
                AgencyTransactions.agentTransactionsAgainstAgency(
                    auth?.user?.agencyId,
                    id,
                    auth?.token
                )
                    .then((response: any) => {
                        if (response.status) {
                            setTransactions(response.data)
                        }
                    })
                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
            } else {
                setIsAgent(true)
                AgencyTransactions.getTransactionsByAgencyId(
                    auth.user.agencyId,
                    auth?.token
                )
                    .then((response: any) => {
                        if (response.status) {
                            setTransactions(response.data?.rows)
                        }
                    })
                    .catch((error) => {
                        const err = {
                            status: false,
                            statusMsg:
                                'Could not get list of transactions. Something went wrong.',
                            error: error,
                        }
                        if (debug) console.log(err)
                    })
            }
        } else if (auth.user && pathname.startsWith('/agency')) {
            setIsAgent(false)
            AgencyTransactions.dashboardTransactionsForAgency(
                auth.user.agencyId,
                auth?.token
            )
                .then((response: any) => {
                    if (response.status) setTransactions(response.data?.rows)
                })
                .catch((error) => {
                    const err = {
                        status: false,
                        statusMsg:
                            'Could not get list of transactions. Something went wrong.',
                        error: error,
                    }
                    if (debug) console.log(err)
                })
        }
        // eslint-disable-next-line
    }, [page])
    const viewItem = async (id: any) => {
        return
    }

    const editTransaction = (item: React.SetStateAction<null>) => {
        setIsEditedItem(item)
        setIsAddStudentDialogVisible(true)
    }

    const deleteItem = async (id: any) => {
        return
    }
    return (
        <div className="home-table-card">
            <div className="home-table-header">
                <Typography variant="h4" style={{ color: BLACK }}>
                    {isAgentTransactions
                        ? 'Transactions'
                        : 'Completed Transactions'}{' '}
                </Typography>
                {/* <div className="recent-transaction-searchbox">
                    <SearchInput
                        placeholder="Search transactions"
                        disabled={false}
                        hideLabel={true}
                        error={searchError}
                        value={search}
                        onChange={handleSearching}
                        label={'Search Transaction ID'}
                        name="search"
                        height={35}
                    />
                </div> */}
            </div>
            <div>
                {loading ? (
                    <div
                        style={{
                            height: '400px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularProgress
                            size={20}
                            style={{ color: 'black' }}
                        />
                        {t('text_fetching_transactions')}
                    </div>
                ) : (
                    <TableContainer
                        style={{
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        <ManagementTable
                            handleSelection={handleSelection}
                            pageNumber={page}
                            handlePage={handlePage}
                            totalCount={totalCount}
                            tableFor={
                                isAgent ? 'transactions' : 'agencyTransactions'
                            }
                            data={transactions}
                            editItem={editTransaction}
                            deleteItem={deleteItem}
                            columns={
                                isAgent
                                    ? AGENT_TRANSACTIONS_COLUMN
                                    : AGENCY_TRANSACTIONS_COLUMNS
                            }
                            viewItem={viewItem}
                            startEntry={startEntry}
                            endEntry={endEntry}
                            hidePagination={true}
                        />
                    </TableContainer>
                )}
            </div>
        </div>
    )
}
