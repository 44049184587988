// packages block
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
// common block
import { FormSelect } from '../../common/select'
import { TextFieldComponent } from '../../common/textFeild'
import '../style.css'
import { ROLES } from '../../../constants'
import { preaddStyles } from '../../../styles/preAddStyles'
import { useEffect, useState } from 'react'
import axios from 'axios'
// constants, theme, utils and api block

export const AddUserForm = ({
    reraNo,
    firstName,
    lastName,
    password,
    role,
    email,
    nationality,
    phoneNumber,
    handlePersonalData,
    errors,
    handlePhoneNumber,
    isEditUser,
    countryCode,
    user,
}: any) => {
    const [countriesData, setCountriesData] = useState([])

    const classes = preaddStyles()
    const { t } = useTranslation()

    useEffect(() => {
        axios
            ?.get(`${process.env.REACT_APP_API_URL}/api/countries`)
            .then((r) => {
                setCountriesData(r.data.countries)
            })
    }, [])

    return (
        <div className={classes.addSchoolForm}>
            <div>
                <Grid container className={classes.gridRoot} spacing={3}>
                    <Grid item sm={6} xs={12}>
                        {isEditUser ? (
                            <TextFieldComponent
                                disabled={true}
                                // eslint-disable-next-line
                                value={reraNo}
                                onChange={handlePersonalData}
                                label={'RERA No.'}
                                name={'reraNo'}
                            />
                        ) : (
                            <TextFieldComponent
                                required={true}
                                // eslint-disable-next-line
                                errorMessage={
                                    errors.indexOf('reraNo') > -1 === true &&
                                    'RERA No.' + ' ' + t('text_is_required')
                                }
                                error={errors.indexOf('reraNo') > -1}
                                value={reraNo}
                                onChange={handlePersonalData}
                                label={'RERA No.'}
                                name={'reraNo'}
                            />
                        )}
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <ReactPhoneInput
                            disabled={isEditUser ? true : false}
                            containerClass="custom-phone-input"
                            inputStyle={{
                                width: 'inherit',
                                height: '35px',
                                borderRadius: 10,
                                fontFamily: 'Montserrat',
                            }}
                            value={
                                isEditUser
                                    ? `${countryCode}${phoneNumber}`
                                    : phoneNumber
                            }
                            onChange={handlePhoneNumber}
                            country={'ae'}
                            specialLabel={'Phone Number'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('firstName') > -1 === true &&
                                'First Name' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('firstName') > -1}
                            value={firstName}
                            onChange={handlePersonalData}
                            label={'First Name'}
                            name={'firstName'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <TextFieldComponent
                            required={true}
                            // eslint-disable-next-line
                            errorMessage={
                                errors.indexOf('lastName') > -1 === true &&
                                'Last Name' + ' ' + t('text_is_required')
                            }
                            error={errors.indexOf('lastName') > -1}
                            value={lastName}
                            onChange={handlePersonalData}
                            label={'Last Name'}
                            name={'lastName'}
                        />
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        {isEditUser ? (
                            <TextFieldComponent
                                required={true}
                                disabled={true}
                                value={email}
                                label={t('text_email_address')}
                                name={'email'}
                            />
                        ) : (
                            <TextFieldComponent
                                required={true}
                                // eslint-disable-next-line
                                errorMessage={
                                    errors.indexOf('email') > -1 === true &&
                                    t('text_email_address') +
                                        ' ' +
                                        t('text_is_required')
                                }
                                error={errors.indexOf('email') > -1}
                                value={email}
                                onChange={handlePersonalData}
                                label={t('text_email_address')}
                                name={'email'}
                                placeholder="paynest@gmail.com"
                            />
                        )}
                    </Grid>

                    {!isEditUser && (
                        <Grid item sm={6} xs={12}>
                            <TextFieldComponent
                                required={true}
                                // eslint-disable-next-line
                                errorMessage={
                                    errors.indexOf('password') > -1 === true &&
                                    'Create Password' +
                                        ' ' +
                                        t('text_is_required')
                                }
                                error={errors.indexOf('password') > -1}
                                value={password}
                                onChange={handlePersonalData}
                                label={'Create Password'}
                                name={'password'}
                                placeholder="***********"
                                type="password"
                            />
                        </Grid>
                    )}

                    <Grid item sm={6} xs={12}>
                        <FormSelect
                            value={nationality}
                            onChange={handlePersonalData}
                            label={t('text_nationality')}
                            name={'nationality'}
                            countryList={countriesData}
                        />
                    </Grid>

                    {isEditUser ? (
                        <Grid item sm={6} xs={12}>
                            <TextFieldComponent
                                value={role}
                                disabled={true}
                                onChange={handlePersonalData}
                                label={t('text_role')}
                                name={'role'}
                                type="text"
                            />
                        </Grid>
                    ) : (
                        <Grid item sm={6} xs={12}>
                            <FormSelect
                                value={role}
                                onChange={handlePersonalData}
                                label={t('text_role')}
                                name={'role'}
                                list={ROLES}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
}
