import axios from 'axios'
import {
    AGENT_TRANSACTIONS_AGAINST_AGENCY_URL,
    DASHBOARD_PENDING_TRANSACTIONS_BY_AGENCY_URL,
    DASHBOARD_TRANSACTIONS_BY_AGENCY_URL,
    FETCH_TRANSACTION_DETAIL_URL,
    GET_AGENCY_TRANSACTIONS_STATS_URL,
    GET_INVOICE_TRANSACTIONS_WITH_AGENCY_ID_URL,
    GET_PAYMENT_LINKS_GRAPH_FOR_AGENCY,
    GET_TRANSACTIONS_BY_AGENT_ID_URL,
    GET_TRANSACTIONS_GRAPH_FOR_AGENCY,
    GET_TRANSACTIONS_URL,
    GET_TRANSACTION_DETAIL_URL,
    INVOICE_TRANSACTIONS_BY_AGENCY_URL,
    PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL,
    PENDING_INVOICE_TRANSACTIONS_BY_AGENCY_ID_URL,
    PENDING_PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL,
} from '../../constants'

const debug = process.env.REACT_APP_DEBUG

const AgencyTransactions = {
    stats: async (
        startDate: string,
        endDate: string,
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_AGENCY_TRANSACTIONS_STATS_URL}?startDate=${startDate}&endDate=${endDate}&agencyId=${agencyId}`,
                config
            )
            return response
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get transaction stats. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactions: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_URL}?agencyId=${agencyId}&status=completed`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getInvoiceTransactionsByAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${INVOICE_TRANSACTIONS_BY_AGENCY_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get invoices. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPendingInvoiceTransactionsByAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${PENDING_INVOICE_TRANSACTIONS_BY_AGENCY_ID_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get invoices. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPaymentLinkTransactionsByAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPendingPaymentLinkTransactionsByAgency: async (
        agencyId: string | number,
        page: number,
        limit: number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${PENDING_PAYMENT_LINK_TRANSACTIONS_BY_AGENCY_URL}?agencyId=${agencyId}&page=${
                    page + 1
                }&limit=${limit}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactionsByAgencyId: async (
        agentId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_BY_AGENT_ID_URL}?agencyId=${agentId}&status=completed`,
                config
            )
            return response
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPaymentLinksGraphForAgency: async (
        startDate: string,
        endDate: string,
        id: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_PAYMENT_LINKS_GRAPH_FOR_AGENCY}?startDate=${startDate}&endDate=${endDate}&id=${id}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get graph transaction. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactionsGraphForAgency: async (
        startDate: string,
        endDate: string,
        id: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_GRAPH_FOR_AGENCY}?startDate=${startDate}&endDate=${endDate}&id=${id}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg:
                    'Could not get graph transaction. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getTransactionDetailByTransactionId: async (
        transactionId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTION_DETAIL_URL}?transactionId=${transactionId}`,
                config
            )
            return response
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    fetchTransactionDetailByTransactionId: async (
        id: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${FETCH_TRANSACTION_DETAIL_URL}?id=${id}`,
                config
            )
            return response
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    getPendingTransactionsByAgencyId: async (
        agentId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${GET_TRANSACTIONS_BY_AGENT_ID_URL}?agencyId=${agentId}&status=pending&page=1&limit=7`,
                config
            )
            return response
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    agentTransactionsAgainstAgency: async (
        agencyId: string | number,
        agentId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${AGENT_TRANSACTIONS_AGAINST_AGENCY_URL}?agencyId=${agencyId}&agentId=${agentId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    dashboardTransactionsForAgency: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${DASHBOARD_TRANSACTIONS_BY_AGENCY_URL}?agencyId=${agencyId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
    dashboardPendingTransactionsForAgency: async (
        agencyId: string | number,
        token: string | null
    ) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
            const response = await axios.get(
                `${DASHBOARD_PENDING_TRANSACTIONS_BY_AGENCY_URL}?agencyId=${agencyId}`,
                config
            )
            return response.data
        } catch (error) {
            const err = {
                status: false,
                statusMsg: 'Could not get transactions. Something went wrong',
                error: error,
            }
            if (debug) console.error(err)
            return err
        }
    },
}
export default AgencyTransactions
