// External packages
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Download, Share } from '@mui/icons-material'
import QRCode from 'qrcode'

// Internal components
import AgentPaymentLinks from '../../../apis/agent/paymentLinks'
import { RootState } from '../../../redux/store'
import { FormButton } from '../../common/button'
import { RadioButton } from '../../common/radioButton'
import { TextFieldComponent } from '../../common/textFeild'
import { SwitchesGroup } from '../../common/switchButton'
import Alert from '../../common/Alert'

// Assets and constants
import backButtonIcon from '../../../assets/images/back-button-icon.svg'
import customerAmountIcon from '../../../assets/images/customer-amount-icon.svg'
import selfAmountIcon from '../../../assets/images/self-amount-icon.svg'
import { BLACK_EIGHT, BLUE_EIGHT, GREEN_THREE, WHITE } from '../../../theme'
import { paymentRadioBtnData } from '../../../constants'
import './style.css'

export const CreatePaymentLinkComponent = (): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    const [initiatePaymentLink, setInitiatePaymentLink] = useState(true)
    const [isSelfAmountCheck, setIsSelfAmountCheck] = useState(false)
    const [isCommissionContainer, setIsCommissionContainer] = useState(false)
    const [isGenerateLinkContainer, setIsGenerateLinkContainer] =
        useState(false)
    const [isFinalLink, setIsFinalLink] = useState(false)
    const [radioBtnValue, setRadioBtnValue] = useState(
        paymentRadioBtnData[0].value
    )
    const [amount, setAmount] = useState<string>('')
    const [amountRequired, setAmountRequired] = useState<boolean>(false)
    const [customerDetailsRequired, setCustomerDetailsRequired] =
        useState<boolean>(false)
    const [receiptRequired, setReceiptRequired] = useState<boolean>(false)
    const [messageRequired, setMessageRequired] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [paymentLink, setPaymentLink] = useState<string>('')

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRadioBtnValue((event.target as HTMLInputElement).value)
    }
    const handleBackInitialPaymentContainer = () => {
        setAmount('')
        setInitiatePaymentLink(true)
        setIsCommissionContainer(false)
        setIsGenerateLinkContainer(false)
        setIsFinalLink(false)
    }

    const handleBackCommissionContainer = () => {
        setIsCommissionContainer(true)
        setInitiatePaymentLink(false)
        setIsGenerateLinkContainer(false)
        setIsFinalLink(false)
    }

    const handleBackGenerateLink = () => {
        setIsGenerateLinkContainer(true)
        setIsFinalLink(false)
        setIsCommissionContainer(false)
        setInitiatePaymentLink(false)
    }

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(paymentLink)
            .then((response) => Alert.toast('Copied to clipboard'))
            .catch((err) => Alert.toast('Failed to copy to clipboard'))
    }
    const handleSelfAmount = () => {
        setIsSelfAmountCheck(true)
    }

    const handleCustomerAmount = () => {
        if (!amount.length && isSelfAmountCheck) {
            Alert.error('Amount cannot be empty')
            return
        }
        setIsCommissionContainer(true)
        setInitiatePaymentLink(false)
        setAmountRequired(isSelfAmountCheck ? false : true)
    }

    const handleCommissionContainer = () => {
        setIsGenerateLinkContainer(true)
        setIsCommissionContainer(false)
        setInitiatePaymentLink(false)
        setIsFinalLink(false)
    }

    const handleGenerateLink = () => {
        if (auth.user && pathname.startsWith('/agent')) {
            AgentPaymentLinks.createStandalonePaymentLink(
                auth.user.id,
                receiptRequired,
                messageRequired,
                customerDetailsRequired,
                amountRequired,
                amount,
                description.length ? description : radioBtnValue,
                auth?.token
            ).then((response: any) => {
                setPaymentLink(
                    `${process.env.REACT_APP_FE_URL}/agent/payment-link/${response.data.url}`
                )
            })
        }
        setIsFinalLink(true)
        setIsGenerateLinkContainer(false)
        setIsCommissionContainer(false)
        setInitiatePaymentLink(false)
    }

    const handleHomeRoute = () => {
        window.location.href = '/agent/dashboard'
    }

    const handlePaymentLinkRoute = () => {
        window.location.href = '/agent/paymentLinks'
    }

    const handleExternalLink = () => {
        if (!paymentLink.length) return
        window.location.href = paymentLink
    }
    const downloadQR = async () => {
        const qrDataURL = await QRCode.toDataURL(paymentLink, {
            errorCorrectionLevel: 'H',
        })

        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const img = new Image()
        img.src = qrDataURL
        img.onload = () => {
            canvas.width = img.width
            canvas.height = img.height
            if (!context) {
                console.error('2D context is not available')
                return
            }
            context.drawImage(img, 0, 0)

            const image = canvas
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream')
            const link = document.createElement('a')
            link.href = image
            link.download = `QRCode-${paymentLink}.png`
            link.click()
        }
    }

    return (
        <div className="">
            {initiatePaymentLink && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={handlePaymentLinkRoute}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Payment Links
                        </Typography>
                    </Box>

                    <Box my="30px">
                        <Typography variant="h6" color="#252422">
                            Set payment amount
                        </Typography>

                        <Typography variant="body1" color="#70707087">
                            Do you want to set the payment amount?
                        </Typography>
                    </Box>

                    <Box
                        className="create-payment-link-container"
                        maxWidth="350px"
                        mx="auto"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        px={isSelfAmountCheck ? '100px' : '50px'}
                    >
                        <Box
                            onClick={handleSelfAmount}
                            bgcolor="#252422"
                            p="30px"
                            borderRadius="15px"
                            maxWidth="350px"
                            className="cursor-css"
                            my="30px"
                        >
                            <Typography variant="h6" color="white">
                                Yes I will set the Amount
                            </Typography>

                            <Box display="flex">
                                <Typography
                                    variant="body2"
                                    color="#c3b7b7"
                                    maxWidth="250px"
                                    minWidth="250px"
                                >
                                    The amount will be present in the link and
                                    Can't be Edited
                                </Typography>

                                <img src={selfAmountIcon} alt="" />
                            </Box>
                        </Box>

                        {isSelfAmountCheck && (
                            <Box
                                my="30px"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Typography
                                    variant="body2"
                                    color="#70707087"
                                    fontWeight={800}
                                >
                                    Enter amount
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            marginRight: '5px',
                                        }}
                                    >
                                        AED
                                    </Typography>

                                    <Grid md={4} container>
                                        <TextField
                                            inputProps={{
                                                step: 0.01,
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            value={amount}
                                            onChange={(e) =>
                                                setAmount(e.target.value)
                                            }
                                        />
                                    </Grid>
                                </div>
                            </Box>
                        )}

                        <Box
                            onClick={
                                isSelfAmountCheck
                                    ? () => {
                                          setIsSelfAmountCheck(false)
                                      }
                                    : handleCustomerAmount
                            }
                            bgcolor="#252422"
                            p="30px"
                            borderRadius="15px"
                            maxWidth="350px"
                            className={`cursor-css ${
                                isSelfAmountCheck
                                    ? 'light-opacity'
                                    : 'dark-opacity'
                            }`}
                            my="30px"
                        >
                            <Typography variant="h6" color="white">
                                NO, MY CUSTOMER WILL
                            </Typography>

                            <Box display="flex">
                                <Typography
                                    variant="body2"
                                    color="#c3b7b7"
                                    maxWidth="350px"
                                    minWidth="250px"
                                >
                                    The Amount will be Editable in the Payment
                                    link
                                </Typography>

                                <img src={customerAmountIcon} alt="" />
                            </Box>
                        </Box>

                        {isSelfAmountCheck && (
                            <div>
                                <FormButton
                                    noMargin={true}
                                    width={'24vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLACK_EIGHT}
                                    hoverBorderColor={BLACK_EIGHT}
                                    backgroundColor={BLACK_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Continue'}
                                    onClickHandler={handleCustomerAmount}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                />
                            </div>
                        )}
                    </Box>
                </>
            )}

            {isCommissionContainer && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={handleBackInitialPaymentContainer}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Payment Links
                        </Typography>
                    </Box>

                    <Box>
                        <Box my="30px">
                            <Typography variant="h6" color="#252422">
                                What’s the payment for?
                            </Typography>

                            <Typography variant="body1" color="#70707087">
                                Add a short description to help your customers
                                understand what this payment link is for
                            </Typography>
                        </Box>

                        <Box
                            className="commission-container"
                            px="70px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            maxWidth="740px"
                            mx="auto"
                            mt="30px"
                        >
                            <Box mb={2}>
                                <RadioButton
                                    handleRadioBtnChange={handleChange}
                                    radioBtnValue={radioBtnValue}
                                    radioBtnData={paymentRadioBtnData}
                                />
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Divider
                                    sx={{
                                        width: '100%',
                                        maxWidth: '300px',
                                        minWidth: '300px',
                                        height: '2px',
                                        mr: '10px',
                                    }}
                                />
                                <Typography variant="h6">OR</Typography>
                                <Divider
                                    sx={{
                                        width: '100%',
                                        maxWidth: '300px',
                                        minWidth: '300px',
                                        height: '2px',
                                        ml: '10px',
                                    }}
                                />
                            </Box>

                            <Grid container sm={10} xs={12} my="20px">
                                <TextFieldComponent
                                    required={true}
                                    value={description}
                                    onChange={(e: any) => {
                                        setDescription(e.target.value)
                                    }}
                                    label={'Description'}
                                    name={'description'}
                                    type="text"
                                />
                            </Grid>

                            <div>
                                <FormButton
                                    width={'20vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLACK_EIGHT}
                                    hoverBorderColor={BLACK_EIGHT}
                                    backgroundColor={BLACK_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Continue'}
                                    onClickHandler={handleCommissionContainer}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                />
                            </div>
                        </Box>
                    </Box>
                </>
            )}

            {isGenerateLinkContainer && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={handleBackCommissionContainer}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Payment Links
                        </Typography>
                    </Box>

                    <Box>
                        <Box my="30px">
                            <Typography variant="h6" color="#252422">
                                Customize payment details
                            </Typography>

                            <Typography variant="body1" color="#70707087">
                                Manage how your customers experience payment
                                links
                            </Typography>
                        </Box>

                        <Box
                            className="generate-link-container"
                            px="70px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            maxWidth="740px"
                            mx="auto"
                            mt="30px"
                        >
                            <Box mb="30px">
                                <SwitchesGroup
                                    label={'Customer details'}
                                    value={'customerDetails'}
                                    description={
                                        'If enabled, your customer would be required to enter their personal details.'
                                    }
                                    checked={customerDetailsRequired}
                                    setChecked={setCustomerDetailsRequired}
                                />

                                <SwitchesGroup
                                    label={'Message'}
                                    value={'message'}
                                    description={
                                        'If enabled, your customer would be required to add a message or comment.'
                                    }
                                    checked={messageRequired}
                                    setChecked={setMessageRequired}
                                />
                                <SwitchesGroup
                                    label={'Share Receipt'}
                                    value={'shareReceipt'}
                                    description={`If enabled, we'll send your customer and automated receipt.`}
                                    checked={receiptRequired}
                                    setChecked={setReceiptRequired}
                                />
                            </Box>

                            <div>
                                <FormButton
                                    width={'20vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLACK_EIGHT}
                                    hoverBorderColor={BLACK_EIGHT}
                                    backgroundColor={BLACK_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Create Link'}
                                    onClickHandler={handleGenerateLink}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                />
                            </div>
                        </Box>
                    </Box>
                </>
            )}

            {isFinalLink && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="contained"
                            onClick={handleBackGenerateLink}
                            style={{
                                backgroundColor: 'transparent',
                                maxWidth: '30px',
                                maxHeight: '30px',
                                minWidth: '30px',
                                minHeight: '30px',
                                borderRadius: '3px',
                                boxShadow: 'none',
                            }}
                        >
                            <img
                                src={backButtonIcon}
                                className="backbut"
                                style={{ width: 48, cursor: 'pointer' }}
                                alt="back-button-icon"
                            />
                        </Button>

                        <Typography variant="h2" fontSize={'1.15vw'} ml={2}>
                            Payment Links
                        </Typography>
                    </Box>

                    <Box>
                        <Box my="30px">
                            <Typography variant="h6" color="#252422">
                                Customize payment details
                            </Typography>

                            <Typography variant="body1" color="#70707087">
                                Manage how your customers experience payment
                                links
                            </Typography>
                        </Box>

                        <Box
                            className="generate-link-container"
                            px="70px"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            maxWidth="740px"
                            mx="auto"
                            mt="30px"
                        >
                            <Grid
                                container
                                item
                                sm={8}
                                xs={12}
                                my="20px"
                                onClick={handleExternalLink}
                            >
                                <TextFieldComponent
                                    required={true}
                                    value={paymentLink}
                                    label={'Share your Link'}
                                    name={'paymentLink'}
                                    type="text"
                                />
                            </Grid>

                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 4,
                                    flexDirection: 'column',
                                    marginTop: '10px',
                                    rowGap: '10px',
                                }}
                            >
                                <FormButton
                                    startIcon={
                                        <Share
                                            style={{ color: 'white' }}
                                            color={'secondary'}
                                        />
                                    }
                                    width={'20vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLUE_EIGHT}
                                    hoverBorderColor={BLUE_EIGHT}
                                    backgroundColor={BLUE_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Copy Link'}
                                    onClickHandler={() => {
                                        copyToClipboard()
                                    }}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />

                                <FormButton
                                    startIcon={
                                        <Download
                                            style={{ color: 'white' }}
                                            color={'secondary'}
                                        />
                                    }
                                    width={'20vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={GREEN_THREE}
                                    hoverBorderColor={GREEN_THREE}
                                    backgroundColor={GREEN_THREE}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Download QR'}
                                    onClickHandler={() => downloadQR()}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                />

                                <FormButton
                                    width={'20vw'}
                                    height={45}
                                    borderColor={WHITE}
                                    titleColor={WHITE}
                                    hoverTitleColor={BLACK_EIGHT}
                                    hoverBorderColor={BLACK_EIGHT}
                                    backgroundColor={BLACK_EIGHT}
                                    hoverBackgroundColor={WHITE}
                                    borderTopRightRadius={15}
                                    borderTopLeftRadius={15}
                                    borderBottomLeftRadius={15}
                                    borderBottomRightRadius={15}
                                    title={'Home'}
                                    onClickHandler={handleHomeRoute}
                                    disabled={false}
                                    overRideButtonCheck={true}
                                />
                            </div>
                        </Box>
                    </Box>
                </>
            )}
        </div>
    )
}
