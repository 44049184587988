import { makeStyles } from '@mui/styles'

export const preaddStyles = makeStyles(() => ({
    root: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',

        '& .MuiDialog-paperScrollPaper': {
            boxShadow: ' 0px 3px 6px #00000029',
            borderRadius: '38px',
        },
    },
    mainContainer: {
        width: 580,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
    },
    dropContainer: {
        flex: 1,
        padding: 30,
        display: 'flex',
        overflow: 'auto',
        justifyContent: 'space-around',
    },
    dropContainer2: {
        flex: 1,
        padding: '20px 30px',
        display: 'flex',
        overflow: 'auto',
        boxShadow: 'none !important',
    },
    singleDropZoneContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: 20,
    },
    userIcon: {
        marginLeft: 15,
        fontSize: 100,
        color: 'rgb(223,223,232)',
        transform: 'scaleX(-1)',
    },
    csvDropZoneContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: 10,
    },
    backupIcon: {
        marginLeft: 15,
        fontSize: 60,
        color: 'rgb(223,223,232)',
        transform: 'scaleX(-1)',
    },
    csvDetail: {
        padding: '5px',
        fontSize: 10,
        textAlign: 'center',
        display: 'flex',
    },
    reportIcon: {
        color: 'rgb(30,173,219)',
        paddingRight: 5,
    },
    predefindTemplateLink: {
        color: 'rgb(30,173,219)',
        paddingLeft: 5,
    },
    predialogTitle: {
        color: 'black',
        textAlign: 'center',
    },
    predialogChildern: {
        width: '100%',
        borderRadius: 5,
        margin: '10px 0px',
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    radioIcon: {
        color: 'rgb(30,173,219)',
        fontSize: 10,
        marginRight: 10,
    },
    listText: {
        fontSize: 10,
    },
    addSchooldialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        bottom: 0,
        background: 'white',
        zIndex: 5,
        padding: 10,
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        columnGap: 20,
    },
    footerButton: {
        padding: '10px',
    },
    addSchoolForm: {
        flex: 1,
        padding: 30,
        width: '100%',
        maxWidth: '90%',
        margin: '0 auto',
    },
    gridRoot: {
        flexGrow: 1,
    },
    fileUploaderMainContainer: {
        width: 600,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
        maxHeight: 875,
    },

    radioButtonLabel: {
        position: 'relative',

        '& .MuiFormGroup-root': {
            position: 'absolute',
            top: -10,
            width: 190,
        },

        '& .MuiFormControlLabel-root': {
            marginBottom: 0,
        },

        '& span': {
            marginBottom: 0,
            fontSize: '10px',
            padding: '0px 0px 0px 8px',

            '& input': {
                position: 'relative',
                width: '10px',
                height: '10px',
                opacity: '1',
            },

            '& div': {
                display: 'none',
            },
        },
    },

    addBankdialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'sticky',
        bottom: 0,
        background: 'white',
        zIndex: 5,
        padding: 10,
        width: '100%',
        maxWidth: '70%',
        margin: '15px auto',
    },
    addBankForm: {
        flex: 1,
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: 58,
        padding: 30,
        width: '100%',
        maxWidth: '70%',
        margin: '0 auto',
    },
}))
