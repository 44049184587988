// packages block
import { Box } from '@mui/material'
import { ActionList } from '../actions'
import { AgentsTableContainer } from '../agentTableContainer'
import BarChartContainer from '../barCharts'
import { WidgetList } from '../Stats'
import { TransactionsTableContainer } from '../transactionTableContainer'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { PendingTransactionsTableContainer } from './pendingTransactionTableContainer'
// components block

const HomeComponent = (): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const pathname = document.location.pathname

    let userId = null
    let type: 'agent' | 'agency' | 'userManagement' = 'agent'
    if (pathname.startsWith('/agency')) {
        userId = auth.user?.agencyId ?? null
        type = 'agency'
    } else if (pathname.startsWith('/agent')) {
        userId = auth.user?.id ?? null
        type = 'agent'
    }

    return (
        <Box sx={{ width: '100%' }}>
            <WidgetList id={userId} loggedIn={auth.authenticated} type={type} />
            {/* If agent portal then comment underline */}
            {pathname.includes('/agent') && <ActionList />}
            <BarChartContainer
                id={userId}
                loggedIn={auth.authenticated}
                type={type}
            />
            {pathname.includes('/agency') && <AgentsTableContainer />}
            <TransactionsTableContainer />
            {pathname.includes('/agency') && (
                <PendingTransactionsTableContainer />
            )}
        </Box>
    )
}

export default HomeComponent
