//packages block
import { FC } from 'react'
import { IconButton } from '@mui/material'
import { useSnackbar, VariantType } from 'notistack'
import { Close as CloseIcon } from '@mui/icons-material'
import '../../App.css'
//interface block

type Key = string | number | undefined
export interface CloseSnackbarProps {
    id: Key
}
let useSnackbarRef: any

export const SnackbarUtilsConfigrator: FC = () => {
    useSnackbarRef = useSnackbar()
    return null
}

export const CloseButton: FC<CloseSnackbarProps> = ({ id }) => {
    const { closeSnackbar } = useSnackbar()
    return (
        <IconButton
            style={{ fontFamily: 'Montserrat' }}
            aria-label="Close notification"
            color="inherit"
            size="small"
            onClick={() => closeSnackbar(id)}
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    )
}

const Alert = {
    success(message: string) {
        this.toast(message, 'success')
    },
    warning(message: string) {
        this.toast(message, 'warning')
    },
    info(message: string) {
        this.toast(message, 'info')
    },
    error(message: string) {
        this.toast(message, 'error')
    },
    toast(message: string, variant: VariantType = 'default') {
        useSnackbarRef.enqueueSnackbar(message, { variant })
    },
}

export default Alert
