// packages block
import clsx from 'clsx'
import Lottie from 'lottie-react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Delete, FilterList, ArrowDropDown } from '@mui/icons-material'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    IconButton,
    Tooltip,
    Box,
} from '@mui/material'
// common block
import { Footer } from './components/footer'
import { UserRow } from './components/userRow'
import { AccountRow } from './components/accountRow'
import { InvoiceRow } from './components/invoiceRow'
import { PaymentLinkRow } from './components/paymentLinksRow'
import { TransactionRow } from './components/transactionRow'
// constants, theme, utils and api block
import { useStyles, useToolbarStyles } from './styles'
import searchingAnimation from '../../assets/searching-animation.json'
import noDataFoundAnimation from '../../assets/no-data-found-animation.json'
import { UserManagementRow } from './components/userManagementRow'
import { PaymentLinkTransactionRow } from './components/paymentLinkTransactionRow'
import { InvoiceTransactionsRow } from './components/invoiceTransactionsRow'
import { DashboardTransactionRow } from './components/dashboardTransactionRow'
import { DashboardPendingTransactionRow } from './components/dashboardPendingTransactionRow'
import { ActiveAgentsTableRow } from './components/activeAgentsTableRow'
import { ActiveAgencyUsersTableRow } from './components/activeAgencyUsersTableRow'
import { InActiveAgentsTableRow } from './components/inActiveAgentsTableRow'
import { InActiveAgencyUsersTableRow } from './components/inActiveAgencyUsersTableRow'
import { PendingPaymentLinkTransactionRow } from './components/pendingPaymentLinkTransactionRow'
import { PendingInvoiceTransactionsRow } from './components/pendingInvoiceTransactionsRow'

const EnhancedTableHead = (props: {
    columns?: any
    classes?: any
    onSelectAllClick?: any
    order?: any
    orderBy?: any
    numSelected?: any
    rowCount?: any
    onRequestSort?: any
    tableFor?: any
}) => {
    const { classes, order, orderBy, onRequestSort, tableFor } = props
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead className={classes.thead}>
            <TableRow>
                {props.columns.map(
                    (headCell: {
                        id: React.Key | null | undefined
                        disablePadding: any
                        label:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined
                    }) => (
                        <TableCell
                            style={{
                                padding: '10px',
                            }}
                            key={headCell.id}
                            align="center"
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                            sortDirection={
                                orderBy === headCell.id ? order : false
                            }
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                IconComponent={ArrowDropDown}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props: { numSelected: any }) => {
    const classes = useToolbarStyles()
    const { numSelected } = props

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterList />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
}

export const ManagementTable = ({
    columns,
    data,
    tableFor = 'school',
    handlePage = null,
    totalCount = null,
    pageNumber = null,
    selectionKey = 'id',
    handleSelection = () => null,
    fetchUsers = () => null,
    startEntry,
    endEntry,
    defaultAccountsData,
    hidePagination = false,
    customLimit,
}: any) => {
    const classes = useStyles()
    const [order] = React.useState('asc')
    const [orderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage] = React.useState(10)

    const [rows, setRows] = useState([])

    useEffect(() => {
        if (pageNumber && pageNumber !== page) setPage(pageNumber)
    }, [pageNumber, page])

    useEffect(() => {
        setPage(0)
    }, [tableFor])

    const handleSelectAllClick = (event: { target: { checked: any } }) => {
        if (event.target.checked) {
            const newSelecteds = data.map(
                (n: { [x: string]: any }) => n[selectionKey]
            )
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    useEffect(() => {
        handleSelection(selected)
    }, [selected, handleSelection])

    const handleClick = (event: any, name: never) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected: never[] | ((prevState: never[]) => never[]) = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (newPage: React.SetStateAction<number>) => {
        if (handlePage) handlePage(newPage)
        setPage(newPage)
    }
    const isSelected = (name: never) => selected.indexOf(name) !== -1

    useEffect(() => {
        if (handlePage) {
            setRows(data)
        }
    }, [data, handlePage, page, rowsPerPage])

    // const colors = ['#EB5E28', '#00D739', '#169DEC', '#FCBE0F']
    // const colorsLength = colors.length

    return (
        <div className={classes.root}>
            <TableContainer
                style={{ flex: 1 }}
                className={classes.tableContainer}
            >
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        tableFor={tableFor}
                        columns={columns}
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={data?.length}
                    />

                    <TableBody className={classes.tbody}>
                        {rows?.map((row, index) => {
                            const isItemSelected = isSelected(row[selectionKey])

                            const labelId = `enhanced-table-checkbox-${index}`
                            // const colorIndex = index % colorsLength
                            // const color = colors[colorIndex]

                            if (tableFor === 'invoices')
                                return (
                                    <InvoiceRow
                                        isItemSelected={isItemSelected}
                                        selectionKey={selectionKey}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'invoiceTransactions')
                                return (
                                    <InvoiceTransactionsRow
                                        isItemSelected={isItemSelected}
                                        selectionKey={selectionKey}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'pendingInvoiceTransactions')
                                return (
                                    <PendingInvoiceTransactionsRow
                                        isItemSelected={isItemSelected}
                                        selectionKey={selectionKey}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                    />
                                )
                            else if (tableFor === 'users')
                                return (
                                    <UserRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                    />
                                )
                            else if (tableFor === 'agents')
                                return (
                                    <UserRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'userManagement')
                                return (
                                    <UserManagementRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'transactions')
                                return (
                                    <TransactionRow
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'agencyTransactions')
                                return (
                                    <DashboardTransactionRow
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'pendingAgencyTransactions')
                                return (
                                    <DashboardPendingTransactionRow
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                    />
                                )
                            else if (tableFor === 'accounts')
                                return (
                                    <AccountRow
                                        defaultAccountsData={
                                            defaultAccountsData
                                        }
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                    />
                                )
                            else if (tableFor === 'paymentLinks')
                                return (
                                    <PaymentLinkRow
                                        defaultAccountsData={
                                            defaultAccountsData
                                        }
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (tableFor === 'paymentLinkTransactions')
                                return (
                                    <PaymentLinkTransactionRow
                                        defaultAccountsData={
                                            defaultAccountsData
                                        }
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        // avatarColor={color}
                                    />
                                )
                            else if (
                                tableFor === 'pendingPaymentLinkTransactions'
                            )
                                return (
                                    <PendingPaymentLinkTransactionRow
                                        defaultAccountsData={
                                            defaultAccountsData
                                        }
                                        selectionKey={selectionKey}
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                    />
                                )
                            else if (tableFor === 'activeAgentsTable')
                                return (
                                    <ActiveAgentsTableRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                    />
                                )
                            else if (tableFor === 'activeAgencyUsersTable')
                                return (
                                    <ActiveAgencyUsersTableRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                    />
                                )
                            else if (tableFor === 'inActiveAgentsTable')
                                return (
                                    <InActiveAgentsTableRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                    />
                                )
                            else if (tableFor === 'inActiveAgencyUsersTable')
                                return (
                                    <InActiveAgencyUsersTableRow
                                        isItemSelected={isItemSelected}
                                        handleClick={handleClick}
                                        row={row}
                                        fetchUsers={fetchUsers}
                                        columns={columns}
                                        actionButtonClass={classes.actionButton}
                                        labelId={labelId}
                                        selectionKey={selectionKey}
                                    />
                                )
                            else return null
                        })}
                    </TableBody>
                </Table>

                {data?.length === 0 && (
                    <Box display="flex" justifyContent="center" pb={6} pt={2}>
                        <Lottie
                            style={{ maxWidth: 300 }}
                            animationData={noDataFoundAnimation}
                            loop={true}
                        />
                    </Box>
                )}
            </TableContainer>
            {data?.length !== 0 && !hidePagination && (
                <Footer
                    tableFor={tableFor}
                    handlePage={handleChangePage}
                    total={totalCount ? totalCount : data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    startEntry={startEntry}
                    endEntry={endEntry}
                    customLimit={customLimit}
                />
            )}
        </div>
    )
}
