// packages block
// common block
import { FormButton } from '../../../common/button'
// constants, theme, utils and api block
import '../../style.css'
import { BLACK_EIGHT, ORANGE_TWO, WHITE } from '../../../../theme'
import { Typography } from '@mui/material'

export const TableHeader = ({ handleAdd, handleDelete }: any) => {
    return (
        <div className="table-header student-popup">
            <div className="left-column">
                <Typography
                    variant="h2"
                    fontSize={'1.15vw'}
                    style={{
                        fontFamily: 'Montserrat',
                        marginBottom: 20,
                    }}
                >
                    {'Account Management'}
                </Typography>
            </div>

            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 4,
                }}
            >
                {/* <FormButton
                    width={'10vw'}
                    height={45}
                    borderColor={WHITE}
                    titleColor={WHITE}
                    backgroundColor={BLACK_EIGHT}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={'Add Bank'}
                    onClickHandler={()=> {return null}}
                    disabled={true}
                /> */}

                <FormButton
                    width={'10vw'}
                    height={53}
                    borderColor={WHITE}
                    titleColor={WHITE}
                    hoverTitleColor={BLACK_EIGHT}
                    hoverBorderColor={BLACK_EIGHT}
                    backgroundColor={BLACK_EIGHT}
                    hoverBackgroundColor={WHITE}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={'Share Bank Details'}
                    onClickHandler={handleAdd}
                    disabled={false}
                    overRideButtonCheck={true}
                />

                {/* <FormButton
                    width={'10vw'}
                    height={45}
                    borderColor={WHITE}
                    titleColor={WHITE}
                    // hoverTitleColor={ORANGE_TWO}
                    // hoverBorderColor={ORANGE_TWO}
                    backgroundColor={ORANGE_TWO}
                    // hoverBackgroundColor={WHITE}
                    borderTopRightRadius={15}
                    borderTopLeftRadius={15}
                    borderBottomLeftRadius={15}
                    borderBottomRightRadius={15}
                    title={'Delete Bank'}
                    // onClickHandler={handleDelete}
                    onClickHandler={()=> {return null}}
                    disabled={true}
                    overRideButtonCheck={true}
                /> */}
                <div></div>
            </div>
        </div>
    )
}
