// packages block
import { TableRow, TableCell, Typography, Avatar } from '@mui/material'
import { BLACK_EIGHT, LIGHT_GREEN } from '../../../theme'
import { useHistory } from 'react-router'

export const PaymentLinkTransactionRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const history = useHistory()
    const pathname = document.location.pathname

    const payeeFirstName = row?.firstName ?? ''
    const payeeLastName = row?.lastName ?? ''
    const agentFirstName = row?.agentFirstName ?? ''
    const agentLastName = row?.agentLastName ?? ''
    const payInfoType = row?.description ?? ''
    const amount = row?.amount ?? ''

    const handleOnRowClick = () => {
        history.push(`${pathname}/${row?.id}`)
    }

    return (
        <TableRow
            hover
            style={{ padding: '3px 0px', cursor: 'pointer' }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row?.name}
            selected={isItemSelected}
            onClick={() => handleOnRowClick()}
        >
            <TableCell
                align="center"
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Avatar className="table-avatar">
                    {agentFirstName[0]?.toUpperCase() ||
                        '' + ' ' + agentLastName[0]?.toUpperCase() ||
                        ''}
                </Avatar>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                    >
                        {agentFirstName || agentLastName
                            ? `${agentFirstName}` + ' ' + `${agentLastName}`
                            : 'N/A'}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    padding: 10,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className="table-avatar">
                            {row?.firstName || row?.lastName
                                ? `${row?.firstName[0]?.toUpperCase()}` +
                                  ' ' +
                                  `${row?.lastName[0]?.toUpperCase()}`
                                : 'N/A'}
                        </Avatar>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.8vw',
                                }}
                            >
                                {payeeFirstName || payeeLastName
                                    ? `${payeeFirstName}` +
                                      ' ' +
                                      `${payeeLastName}`
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </div>
                </Typography>
            </TableCell>

            <TableCell align="center" style={{ fontWeight: 500, padding: 10 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {payInfoType ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    align="center"
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {amount ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell
                align="center"
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 500,
                }}
            >
                <Typography
                    variant="h5"
                    style={{ color: LIGHT_GREEN, fontSize: '0.8vw' }}
                >
                    {row?.paymentStatus ?? ''}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.type === 'STANDALONE'
                        ? 'Payment Link'
                        : row?.type === 'AGENT'
                        ? 'QR code'
                        : '---' ?? 'N/A'}
                </Typography>
            </TableCell>
            <TableCell align="center" style={{ fontWeight: 500 }}>
                <Typography
                    variant="h5"
                    style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                >
                    {row?.orderId ?? 'N/A'}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
