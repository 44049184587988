import { Typography } from '@mui/material'
import { ACTIONS_DATA } from '../../constants'
import { useEffect, useState } from 'react'
import { Action } from './Action'

export const ActionList = () => {
    const [actions, setActions] = useState(ACTIONS_DATA)

    return (
        <div className="widget-list-container">
            <Typography variant="h4" style={{ marginBottom: 20 }}>
                Quick Actions
            </Typography>
            <div>
                <div className="widget-list">
                    {actions.map(
                        ({
                            name,
                            id,
                            icon,
                            borderColor,
                            textColor,
                            link,
                            hoverIcon,
                            hoverTextColor,
                        }) => {
                            return (
                                <Action
                                    name={name}
                                    borderColor={borderColor}
                                    textColor={textColor}
                                    key={id}
                                    icon={icon}
                                    link={link}
                                    hoverIcon={hoverIcon}
                                    hoverTextColor={hoverTextColor}
                                />
                            )
                        }
                    )}
                </div>
            </div>
        </div>
    )
}
