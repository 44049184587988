import { Typography } from '@mui/material'

export const AccountDetail = ({ accountData }: any) => {
    return (
        <div className="account-detail-container">
            <Typography
                variant="h4"
                style={{
                    fontFamily: 'Montserrat',
                    marginBottom: 50,
                    textAlign: 'center',
                }}
            >
                {'Bank Details'}
            </Typography>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    marginBottom: 50,
                }}
            >
                <>
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '2px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Bank Name
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.bank?.name ?? '---'}
                        </Typography>
                    </div>
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '2px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Bank Swift Code
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.swiftCode ?? '---'}
                        </Typography>
                    </div>
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '0px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Bank Address
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.bank?.address ?? '---'}
                        </Typography>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: 'darkgray',
                        }}
                    />
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '2px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Account Number
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.accountNumber ?? '---'}
                        </Typography>
                    </div>
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '2px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Account Title
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.accountTitle?.toUpperCase() ?? '---'}
                        </Typography>
                    </div>
                    <div
                        className=""
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRight: '0px solid darkgray',
                            width: 'calc(90%/3)',
                            textAlign: 'center',
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Account IBAN
                        </Typography>

                        <Typography variant="body2">
                            {accountData?.iban ?? '---'}
                        </Typography>
                    </div>
                </>
            </div>
        </div>
    )
}
