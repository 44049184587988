// packages block
import { FC } from 'react'
import { IconButton, InputAdornment } from '@mui/material'
// constants and interfaces block
import { useLoginStyles } from '../../styles/loginStyles'
import { VisibilityOnIcon, VisibilityOffIcon } from '../../svgs'
export const PASSWORD = 'Password'
export interface IShowPasswordProps {
    passwordType: string
    isPassword: boolean | undefined
    handleShowPassword: () => void
}
const ShowPassword: FC<IShowPasswordProps> = ({
    isPassword,
    passwordType,
    handleShowPassword,
}) => {
    const classes = useLoginStyles()

    return (
        <InputAdornment position="end">
            {isPassword && (
                <IconButton
                    onClick={handleShowPassword}
                    className={classes.passwordIcon}
                >
                    {passwordType === PASSWORD ? (
                        <VisibilityOnIcon />
                    ) : (
                        <VisibilityOffIcon />
                    )}
                </IconButton>
            )}
        </InputAdornment>
    )
}

export default ShowPassword
