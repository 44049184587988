//packages blocks
import moment from 'moment'
import Lottie from 'lottie-react'
import {
    Button,
    Grid,
    Avatar,
    Paper,
    Badge,
    Box,
    CircularProgress,
    styled,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
//styles, utils, assets and api blocks
import '../sideBar/sideBar.css'
import { BLACK_EIGHT } from '../../theme'
import bellIcon from '../../assets/navbar/bell-icon.png'
import bellIconAnimation from '../../assets/bell-icon-animation.json'

export const TopBar = ({ user, onCl }: any) => {
    const [notifyPop, setNotifyPop] = useState(false)
    const [loading, setLoading] = useState(false)
    const [IsVisibleGlobalSearchDialog, setIsVisibleGlobalSearchDialog] =
        useState(false)
    const [responseDataText] = useState('')
    const [students] = useState([])
    const [transactions] = useState([])
    const [base64] = useState([])
    const { t } = useTranslation()

    let notifyStorage = JSON.parse(localStorage.getItem('notify') as string)
    let newNotify

    if (notifyStorage !== null) {
        newNotify = notifyStorage[notifyStorage.length - 1]
    } else {
        newNotify = {
            newNotification: false,
        }
    }

    const [userData] = useState({
        firstName: '',
        lastName: '',
        designation: '',
        file: '',
    })

    const notify = () => {
        if (notifyPop) {
            setNotifyPop(false)
        } else {
            setNotifyPop(true)
            if (notifyStorage !== null) {
                notifyStorage[notifyStorage.length - 1].newNotification = false
                notifyStorage = localStorage.setItem(
                    'notify',
                    JSON.stringify(notifyStorage)
                )
            }
        }
    }

    if (notifyStorage !== null) {
        notifyStorage = notifyStorage.reverse()
    }

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        background: theme.palette.background.paper,
        textTransform: 'capitalize',
    }))

    const Notify_Details = {
        fontWeight: 500,
        fontSize: '16px',
        color: 'rgb(13, 110, 124)',
        paddingTop: 20,
        marginLeft: 15,
        marginBottom: 15,
    }

    return (
        <div className="topbar-main-container">
            <div className="headerUpper">
                <div>{/* <img src={whiteLogo} /> */}</div>

                <div className="sidebar-trigger">
                    <Button onClick={onCl}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Button>
                </div>
            </div>

            <div className="headerInner">
                <Box display="flex" width="80%" justifyContent="center">
                    <Box textAlign="center">
                        <Typography
                            fontWeight={900}
                            style={{
                                marginBottom: 5,
                                marginTop: 10,
                                fontSize: '1.15vw',
                            }}
                            variant="h2"
                        >
                            {user.agencyName}
                        </Typography>
                        <Typography variant="h6" color="#707071">
                            {t('text_welcome_back')}
                        </Typography>
                    </Box>
                </Box>

                {IsVisibleGlobalSearchDialog ? (
                    <>
                        {loading ? (
                            <div className="col-md-6 search_grid_container">
                                <div
                                    style={{
                                        height: '200px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CircularProgress
                                        size={20}
                                        style={{ color: 'black' }}
                                    />
                                    {t('text_fetching_data')}
                                </div>
                            </div>
                        ) : (
                            <div className="col-md-6 search_grid_container">
                                <Grid
                                    container
                                    spacing={0.5}
                                    direction="column"
                                    className="serach_grid"
                                >
                                    <>
                                        {responseDataText === 'student' ? (
                                            <>
                                                {students.map((e) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="m-1"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => null}
                                                    >
                                                        <Item>
                                                            Hi Property Test
                                                        </Item>
                                                    </Grid>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {transactions.map((e) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="m-1"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => null}
                                                    >
                                                        <Item></Item>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </>
                                </Grid>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )}
                <div className="notificationUserDialog">
                    <div className="inner-wrapper">
                        <div className="bell-icon">
                            {newNotify.newNotification ? (
                                <Badge color="error" variant="dot">
                                    <Typography
                                        component="button"
                                        className="bell-button"
                                        onClick={notify}
                                    >
                                        <img
                                            src={bellIcon}
                                            style={{ width: '30px' }}
                                            alt="bell-icon"
                                        />
                                    </Typography>
                                </Badge>
                            ) : (
                                <Typography
                                    component="button"
                                    className="bell-button"
                                    onClick={notify}
                                >
                                    <Lottie
                                        animationData={bellIconAnimation}
                                        style={{ width: 30 }}
                                        loop={true}
                                    />
                                </Typography>
                            )}

                            {notifyPop && (
                                <div className="col-md-4 notify_pop">
                                    <h3 style={Notify_Details}>
                                        <strong
                                            style={{ fontFamily: 'Montserrat' }}
                                        >
                                            {t('text_notifications')}
                                        </strong>
                                    </h3>
                                    <Grid
                                        container
                                        spacing={2}
                                        className="notify_grid"
                                    >
                                        {notifyStorage ? (
                                            <>
                                                {notifyStorage.map((e: any) => (
                                                    <Grid
                                                        item
                                                        xs={10}
                                                        className="my-1"
                                                        style={{
                                                            borderTop:
                                                                '1px solid #F1F1F3',
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-start align-items-center m-0">
                                                            <div
                                                                style={{
                                                                    height: 30,
                                                                    width: 30,
                                                                    background:
                                                                        '#ffe3b2',
                                                                    borderRadius: 10,
                                                                }}
                                                            ></div>
                                                            <div
                                                                className=""
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <strong
                                                                    className="m-0"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {e.message}
                                                                </strong>
                                                                <p
                                                                    className="m-0"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        e.date
                                                                    ).fromNow()}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    <div
                                        className="close_PopUp"
                                        onClick={notify}
                                    ></div>
                                </div>
                            )}
                        </div>
                        <div className="seperator"></div>
                        <div className="top_bar_user_info_cont">
                            <div>
                                <Button href="#" className="user-profile-btn">
                                    <i className="fas fa-chevron-down cyan-text" />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            color="252422"
                                            style={{
                                                color: '#252422',
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {user?.name ?? 'Ahmad Mubashar'}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="#636365"
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {user?.email ?? 'abc@xyz.com'}
                                        </Typography>
                                    </div>

                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            height: '47px',
                                            width: '47px',
                                            borderRadius: 50,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            color: 'white',
                                            marginRight: 10,
                                            marginTop: 3,
                                        }}
                                    >
                                        {user?.profilePicture ? (
                                            <img
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}${user.profilePicture}`}
                                                alt="Profile pic"
                                            />
                                        ) : (
                                            <Avatar />
                                        )}
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
