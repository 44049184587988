import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'
import { UserToken } from '../../../interfacesTypes/user'
import AuthState from '../../../interfacesTypes/auth'

const state: AuthState = {
    user: null,
    authenticated: false,
    token: null,
}

const authSlice = createSlice({
    name: 'auth',
    extraReducers: undefined,
    initialState: state,
    reducers: {
        signIn(state, action) {
            const { token } = action.payload
            const decoded = jwt_decode<UserToken>(token)
            state.user = decoded
            state.authenticated = decoded ? true : false
            state.token = token
        },
        signOut(state) {
            state.user = null
            state.authenticated = false
            state.token = null
        },
        updateOtpEmail(state, action) {
            const { email } = action.payload
            state.otpEmail = email
        },
        updateUser(state, action) {
            const { email, firstName, lastName, phoneNumber, countryCode } =
                action.payload
            if (!state.user) return
            state.user.phoneNumber = phoneNumber
            state.user.countryCode = countryCode
            state.user.name = firstName + ' ' + lastName
            state.user.email = email
        },
        updateAgency(state, action) {
            const {
                agencyEmail,
                agencyName,
                agencyAddress,
                agencyReraNo,
                agencyTradeLicenceNumber,
                agencyPhoneNumber,
                agencyCountryCode,
            } = action.payload
            if (!state.user) return
            state.user.agencyPhoneNumber = agencyPhoneNumber
            state.user.agencyCountryCode = agencyCountryCode
            state.user.agencyName = agencyName
            state.user.agencyEmail = agencyEmail
            state.user.agencyAddress = agencyAddress
            state.user.agencyReraNo = agencyReraNo
            state.user.agencyTradeLicenceNumber = agencyTradeLicenceNumber
        },
        updateProfilePicture(state, action) {
            const { profilePicture } = action.payload
            if (!state.user) return
            state.user.profilePicture = profilePicture
        },
        updateProfilePictureForAgency(state, action) {
            const { agencyProfilePicture } = action.payload
            if (!state.user) return
            state.user.agencyProfilePicture = agencyProfilePicture
        },
    },
})

export const authActions = authSlice.actions

export default authSlice
