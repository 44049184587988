import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { WHITE } from '../../theme'
import './style.css'

export const Action = ({
    name,
    borderColor,
    textColor,
    icon,
    link,
    hoverIcon,
    hoverTextColor,
}: any) => {
    return (
        <Box
            className="widget-container"
            sx={{
                borderColor: borderColor,
                backgroundColor: WHITE,
                '&:hover': {
                    backgroundColor: hoverTextColor,
                },
            }}
            component={Link}
            to={link}
        >
            <div className="widget-inner-container">
                <div className="widget-value-container">
                    <Typography
                        style={{ color: textColor, fontWeight: 600 }}
                        className="actions-text"
                        variant="h5"
                    >
                        {name}
                    </Typography>
                </div>
                <img src={icon} alt="" />
            </div>
        </Box>
    )
}
