// packages block
import { Dialog, Typography, Box } from '@mui/material'
// common block
import '../users/style.css'
import { FormButton } from './button'
// constants, theme, utils and api block
import { BLACK_EIGHT, GREY_EIGHTEEN, WHITE } from '../../theme'
import { preaddStyles } from '../../styles/preAddStyles'

export const NotificationDialog = ({
    open,
    handleClicker,
    notificationTitle,
    handleClose,
    notificationDescription,
}: any) => {
    const classes = preaddStyles()

    return (
        <Dialog
            style={{ maxWidth: '700px', margin: 'auto' }}
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div className="notification_card">
                <Typography variant="h2">{notificationTitle}</Typography>
                <Typography variant="body1">
                    {notificationDescription}
                </Typography>
                <Box marginTop={2} display="flex" justifyContent="space-around">
                    <FormButton
                        title={'Cancel'}
                        onClickHandler={handleClose}
                        height={45}
                        width={'10vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={GREY_EIGHTEEN}
                        titleColor={WHITE}
                        hoverTitleColor={GREY_EIGHTEEN}
                        borderColor={GREY_EIGHTEEN}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={GREY_EIGHTEEN}
                    />

                    <FormButton
                        title={notificationTitle}
                        onClickHandler={handleClicker}
                        height={45}
                        width={'10vw'}
                        borderTopRightRadius={15}
                        borderTopLeftRadius={15}
                        borderBottomLeftRadius={15}
                        borderBottomRightRadius={15}
                        backgroundColor={BLACK_EIGHT}
                        titleColor={WHITE}
                        hoverTitleColor={BLACK_EIGHT}
                        borderColor={BLACK_EIGHT}
                        hoverBackgroundColor={WHITE}
                        hoverBorderColor={BLACK_EIGHT}
                    />
                </Box>
            </div>
        </Dialog>
    )
}
