import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Widget } from './Widget'
import AgentTransactions from '../../apis/agent/transactions'
import AgencyTransactions from '../../apis/agency/transactions'
import paymentReceivedIcon from '../../assets/home/payments-home-icon.png'
import pendingTransactionsIcon from '../../assets/home/pending-transactions-home-icon.png'
import completeTransactionsIcon from '../../assets/home/complete-transactions-home-icon.png'
import moment from 'moment'
import { getGMT4Date } from '../barCharts/barChartsComponentForPayments'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import userStatsIcon from '../../assets/images/user-stats-icon.png'

type IStats = {
    approvedSum: string | number
    approvedCount: string
    pendingCount: string
}

interface WidgetListProps {
    id: number | string | null
    loggedIn: boolean
    type: 'agent' | 'agency' | 'userManagement'
    isAgencyUserManagement?: boolean
}
export const WidgetList: React.FC<WidgetListProps> = ({
    id,
    loggedIn,
    type,
    isAgencyUserManagement = false,
}) => {
    const { t } = useTranslation()
    const [stats, setStats] = useState<IStats | null>(null)
    const [, setName] = useState('')
    const [firstRun, setFirstRun] = useState(true)
    const { auth } = useSelector((state: RootState) => state)

    // eslint-disable-next-line
    let startDate = moment()
        .subtract(2, 'month')
        .startOf('month')
        .format('YYYY-MM-DD')
    // eslint-disable-next-line
    let endDate = getGMT4Date(new Date().toISOString())

    const onChangeSelect = async (e: any) => {
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        const d = new Date()
        setName(month[d.getMonth()])

        try {
            const value = e.target.value

            if (value === 'lastMonth') {
                setName(month[d.getMonth() - 1])
                startDate = moment()
                    .subtract(0, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }

            if (value === 'lastThree') {
                setName(month[d.getMonth() - 1])
                startDate = moment()
                    .subtract(2, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }
            if (value === 'lastSix') {
                setName('')
                startDate = moment()
                    .subtract(5, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }
            if (value === 'lastTwelve') {
                setName('')
                startDate = moment()
                    .subtract(11, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD')
                endDate = getGMT4Date(new Date().toISOString())
            }

            if (id && loggedIn && type === 'agent') {
                AgentTransactions.stats(
                    startDate,
                    endDate,
                    id,
                    auth?.token
                ).then((response: any) => {
                    if (response.status) setStats(response.data)
                })
            } else if (id && loggedIn && type === 'userManagement') {
                if (isAgencyUserManagement) {
                    AgencyTransactions.stats(
                        startDate,
                        endDate,
                        id,
                        auth?.token
                    ).then((response: any) => {
                        if (response.status) setStats(response.data)
                    })
                } else {
                    AgentTransactions.stats(
                        startDate,
                        endDate,
                        id,
                        auth?.token
                    ).then((response: any) => {
                        if (response.status) setStats(response.data)
                    })
                }
            } else if (id && loggedIn && type === 'agency') {
                AgencyTransactions.stats(
                    startDate,
                    endDate,
                    id,
                    auth?.token
                ).then((response: any) => {
                    if (response.status) setStats(response.data)
                })
            }
        } catch (error) {
            console.error('erorr', error)
        }
    }

    const onGetTodayData = () => {
        if (firstRun) {
            // eslint-disable-next-line
            let event = {
                target: {
                    value: 'lastMonth',
                },
            }
            setFirstRun(false)
            onChangeSelect(event)
        }
    }

    useEffect(() => {
        onGetTodayData()
    }, [])

    return (
        <div>
            <div className="widget-list-container">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Typography variant="h4" style={{ marginBottom: 20 }}>
                        {t('text_quick_stats')}
                    </Typography>

                    <select
                        className="form-select shadow-sm my-select"
                        onChange={onChangeSelect}
                    >
                        <option value="" disabled>
                            {t('text_select_period')}
                        </option>
                        <option value="lastMonth" selected>
                            Current Month
                        </option>
                        <option value="lastThree">3 Months</option>
                        <option value="lastSix">6 Months</option>
                        <option value="lastTwelve">12 Months</option>
                    </select>
                </Box>
                <div>
                    <div className="widget-list">
                        <Widget
                            name={'Payments Received'}
                            icon={
                                type === 'userManagement'
                                    ? userStatsIcon
                                    : paymentReceivedIcon
                            }
                            value={
                                stats?.approvedSum
                                    ? 'AED ' +
                                      new Intl.NumberFormat().format(
                                          stats?.approvedSum as number
                                      )
                                    : 'AED ' + 0
                            }
                        />
                        <Widget
                            name={'Completed Transactions'}
                            value={
                                stats?.approvedCount ? stats.approvedCount : 0
                            }
                            icon={
                                type === 'userManagement'
                                    ? userStatsIcon
                                    : completeTransactionsIcon
                            }
                        />
                        <Widget
                            name={'Pending Transactions'}
                            value={stats?.pendingCount ? stats.pendingCount : 0}
                            icon={
                                type === 'userManagement'
                                    ? userStatsIcon
                                    : pendingTransactionsIcon
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
