// packages block
import {
    TableRow,
    TableCell,
    Avatar,
    Typography,
    SelectChangeEvent,
    IconButton,
    Box,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
// common block
// constants, theme, utils and api block
import { USER_ACTIVE_INACTIVE_DUMMY_DATA } from '../../../constants'
import { BLACK_EIGHT, BLUE_FOUTEEN, LIGHT_GREEN, RED } from '../../../theme'
import { useState } from 'react'
import { UserStatusSelect } from '../../common/userStatusSelector'
import AgentService from '../../../apis/agent/users'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { AddUserDialog } from '../../users/userForm'
import { OnSuccessDialog } from '../../common/onSuccessDialog'
// import BasicSelect from "../../common/select";
import UserManagement from '../../../apis/agency/user-management'
import Alert from '../../common/Alert'
import { Agent } from '../../../interfacesTypes/agent'

export const ActiveAgencyUsersTableRow = ({
    isItemSelected,
    row,
}: // avatarColor,
any) => {
    const [isEditUser, setIsEditUser] = useState(false)
    const [isDeleteUser, setIsDeleteUser] = useState(false)
    const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false)
    const [agencyData, setAgencyData] = useState<Agent | null>(null)

    const { auth } = useSelector((state: RootState) => state)

    const [userStatus, setUserStatus] = useState<string>(
        row?.userStatus?.toLowerCase()
    )
    const [rowStatus, setRowStatus] = useState(row?.userStatus ?? 'N/A')

    const handleChange = (event: SelectChangeEvent) => {
        AgentService.updateAgencyUserStatus(
            row?.id,
            event.target.value.toUpperCase() as 'ACTIVE' | 'INACTIVE',
            auth?.token
        ).then((res) => {
            if (res.status) {
                setUserStatus(res.data.toLowerCase() as string)
                setRowStatus(res.data)
            }
        })
    }

    const handleDeleteUser = async (rowId: number) => {
        if (rowId) {
            await UserManagement.deleteAgency(rowId, auth?.token).then(
                (res) => {
                    if (res.status) {
                        setIsDeleteUser(true)
                    } else {
                        Alert.error(res?.statusMsg)
                    }
                }
            )
        }
    }

    const closeAddUserDialog = () => {
        setIsEditUser(false)
    }

    const successCallback = async () => {
        closeAddUserDialog()
        setIsSuccessDialogVisible(true)
        setIsDeleteUser(false)
    }

    const goHome = () => {
        setIsEditUser(false)
        setIsDeleteUser(false)
        setIsSuccessDialogVisible(false)
        window.location.href = '/agency/users'
    }
    const getAgencyInfo = async (rowId: number) => {
        setIsEditUser(false)
        await AgentService.getAgencyByAgencyUserId(rowId, auth?.token).then(
            (res) => {
                if (res.status) {
                    console.log(res.data)
                    setAgencyData(res?.data ?? null)
                    setIsEditUser(true)
                } else {
                    Alert.error('User cannot be found')
                    setIsEditUser(false)
                }
            }
        )
    }

    const firstName = row?.firstName ?? ''
    const lastName = row?.lastName ?? ''
    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return (
        <>
            {row?.id !== auth?.user?.id ? (
                <TableRow
                    hover
                    style={{
                        padding: '3px 0px',
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.name}
                    selected={isItemSelected}
                >
                    <TableCell align="left" style={{ width: 80 }}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.reraNo}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="center"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar className="table-avatar">
                            {firstName || lastName
                                ? `${firstName[0]?.toUpperCase() || ''}` +
                                  ' ' +
                                  `${lastName[0]?.toUpperCase() || ''}`
                                : 'N/A'}
                        </Avatar>

                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: BLACK_EIGHT,
                                    fontSize: '0.8vw',
                                }}
                            >
                                {firstName || lastName
                                    ? `${firstName}` + ' ' + `${lastName}`
                                    : 'N/A'}
                            </Typography>
                        </div>
                    </TableCell>

                    <TableCell align="center" style={{ width: 300 }}>
                        {/* <BasicSelect /> */}
                        <Typography
                            variant="h5"
                            style={{ color: BLUE_FOUTEEN, fontSize: '0.8vw' }}
                        >
                            {row?.email ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: 300 }}>
                        {/* <FormSelect /> */}
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.countryCode ?? ''} {row?.phoneNumber ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" hidden={false}>
                        <Typography
                            variant="h5"
                            style={{ color: BLACK_EIGHT, fontSize: '0.8vw' }}
                        >
                            {row?.type
                                ? capitalizeFirstLetter(row?.type)
                                : 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="center" hidden={false}>
                        <Typography
                            variant="h5"
                            style={{
                                color:
                                    rowStatus === 'ACTIVE' ? LIGHT_GREEN : RED,
                                fontSize: '0.8vw',
                            }}
                        >
                            {rowStatus ?? 'N/A'}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Box display="flex" alignItems="center">
                            <div role="presentation">
                                <UserStatusSelect
                                    value={userStatus ?? 'active'}
                                    onChange={handleChange}
                                    label={''}
                                    name={''}
                                    list={USER_ACTIVE_INACTIVE_DUMMY_DATA}
                                />
                            </div>

                            <IconButton
                                aria-label="delete"
                                onClick={() => handleDeleteUser(row?.id)}
                            >
                                <DeleteIcon />
                            </IconButton>

                            <IconButton
                                aria-label="edit"
                                onClick={() => getAgencyInfo(row?.id)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </TableCell>
                </TableRow>
            ) : (
                <></>
            )}

            {isDeleteUser && (
                <OnSuccessDialog
                    handleClose={closeAddUserDialog}
                    open={isDeleteUser}
                    goHome={goHome}
                    successMessage={'Delete Agency Successfully'}
                />
            )}

            {isSuccessDialogVisible && (
                <OnSuccessDialog
                    handleClose={closeAddUserDialog}
                    open={isSuccessDialogVisible}
                    goHome={goHome}
                    successMessage={'Update Agency Successfully'}
                />
            )}

            {isEditUser && (
                <>
                    <AddUserDialog
                        title={'Edit User Details'}
                        subHeading="Official Details"
                        handleClose={closeAddUserDialog}
                        open={isEditUser}
                        successCallback={successCallback}
                        showCircularProgressBar={true}
                        isEditUser={true}
                        agentId={row?.id}
                        agentData={agencyData}
                    />
                </>
            )}
        </>
    )
}
