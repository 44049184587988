import { makeStyles } from '@mui/styles'
import { InputLabel, MenuItem, FormControl, Select, Box } from '@mui/material'

const useStyles = makeStyles(() => ({
    formControl: {
        height: 36.5,
        margin: 1,

        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 10,
            borderColor: '#006e7d',
        },

        '& .MuiOutlinedInput-root': {
            marginRight: 1,
        },

        '& .MuiInputLabel-root': {
            color: 'black',
        },
    },
}))

export const FormSelect = ({
    value,
    onChange,
    required = false,
    disabled = false,
    list,
    label,
    error,
    name,
    errorMessage = false,
    countryList = undefined,
}: any) => {
    const classes = useStyles()

    return (
        <FormControl
            variant="outlined"
            error={error}
            fullWidth
            className={classes.formControl}
        >
            <InputLabel
                style={{ marginTop: value === '' ? -8 : 0 }}
                id="demo-simple-select-outlined-label"
                component={'div'}
            >
                {label}
            </InputLabel>

            <Select
                disabled={disabled}
                required={required}
                style={{ height: 40.5 }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value}
                onChange={(e) => onChange(e)}
                label={label}
                name={name}
            >
                {countryList
                    ? countryList?.map(
                          ({
                              id: itemValue,
                              nicename: menuLabel,
                              flag,
                          }: any) => (
                              <MenuItem key={itemValue} value={menuLabel}>
                                  <Box display="flex" alignItems="center">
                                      <img
                                          src={flag}
                                          style={{
                                              padding: 2,
                                              marginRight: 5,
                                              maxWidth: 25,
                                          }}
                                      />{' '}
                                      <span>{menuLabel}</span>
                                  </Box>
                              </MenuItem>
                          )
                      )
                    : list.map(
                          ({ value: itemValue, label: menuLabel }: any) => (
                              <MenuItem key={itemValue} value={itemValue}>
                                  {menuLabel}
                              </MenuItem>
                          )
                      )}
            </Select>
            <span style={{ fontSize: '0.65vw', paddingLeft: 10, color: 'red' }}>
                {errorMessage}
            </span>
        </FormControl>
    )
}
