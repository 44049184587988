// packages block
import { FC, useState } from 'react'
import { Box } from '@mui/material'
// components block
// interfaces/types and main layout styles block
// import { useHistory } from "react-router";
import { MainLayoutProps } from '../../interfacesTypes'
import { Sidebar } from '../sideBar'
import '../sideBar/sideBar.css'
import { TopBar } from '../topBar'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

const MainLayout: FC<MainLayoutProps> = ({ children }): JSX.Element => {
    const { auth } = useSelector((state: RootState) => state)
    const [switchToggled, setSwitchToggled] = useState(false)

    const ToggleSwitch = () => {
        switchToggled ? setSwitchToggled(false) : setSwitchToggled(true)
    }
    // const history = useHistory();
    return (
        <>
            <div className="dashboard-page">
                <Box style={{ minHeight: '100vh' }}>
                    <div
                        className={`sidebar-container ${
                            switchToggled ? 'show' : 'hide'
                        }`}
                    >
                        <Sidebar />
                    </div>{' '}
                </Box>

                <div className="dashboard-main-container">
                    <TopBar user={auth.user} onCl={ToggleSwitch} />{' '}
                    {/* <button onClick={() => onClickConnect()}>connect</button>
        <button onClick={() => initiate()}>initiate</button> */}
                    <div className="main-content">
                        <div className="centered-data">{children}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainLayout
