import { styled } from '@mui/system'
// import backgroundImage from '../../assets/images/bg-image.png'

// export const BackgroundImageContainer = styled('div')({
//     backgroundImage: `url(${backgroundImage})`, /* Replace with your image URL */
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     width: '100%',
//     height: '100%',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     opacity: 1,
// })

export const AuthContainer = styled('div')({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
})

export const FormContainer = styled('div')({
    borderRadius: '25px',
    backgroundColor: 'white',
    minHeight: '580px',
    minWidth: '500px',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    margin: '2rem 4rem 0rem 4rem',
    zIndex: 1,
})

export const CompanyAuthLogo = styled('div')({
    margin: '2rem 4rem 0rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
})

export const LoginFormGroup = styled('div')({
    margin: '2rem 4rem 0rem 4rem',
})

export const LoginFormInput = styled('div')({
    paddingTop: '10px',
    marginBottom: '10px',
})
