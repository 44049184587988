// packages block
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/material'
// components block
import AppContainer from './AppContainer'
import {
    SnackbarUtilsConfigrator,
    CloseButton,
} from './components/common/Alert'
// graphql, constants, context, apollo, and theme block
import customTheme from './theme/theme'
import { StylesProvider } from '@mui/styles'
import './App.css'

const App = () => {
    return (
        <SnackbarProvider
            maxSnack={5}
            autoHideDuration={3000}
            action={(key) => <CloseButton id={key} />}
            preventDuplicate={true}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <SnackbarUtilsConfigrator />

            <ThemeProvider theme={customTheme}>
                <StylesProvider>
                    <AppContainer />
                </StylesProvider>
            </ThemeProvider>
        </SnackbarProvider>
    )
}

export default App
