import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CirclesWithBar } from 'react-loader-spinner'
import { preaddStyles } from '../../styles/preAddStyles'

export const FileUploader = ({ open, loading }: any) => {
    const classes = preaddStyles()
    const { t } = useTranslation()

    return (
        <Dialog
            className={classes.root}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <div
                style={{
                    paddingTop: 20,
                    boxShadow:
                        ' 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    borderRadius: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {loading && (
                    <div
                        style={{
                            height: '200px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CirclesWithBar />
                        {t('text_please_wait_for_a_moment')}
                    </div>
                )}
            </div>
        </Dialog>
    )
}
