// packages block
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
} from '@mui/material'
// components block
// import Alert from "../common/Alert";
import LoginController from '../login/LoginController'
// history, context, constants, and utils block
// import history from "../common/history";
import {
    CONFIRM_PASSWORD_LABEL,
    AGENT_DASHBOARD_ROUTE,
    PASSWORD_LABEL,
    RESET_PASSWORD_AGENCY_ROUTE,
    RESET_PASSWORD_AGENT_ROUTE,
    AGENCY_DASHBOARD_ROUTE,
} from '../../constants'
import { LoginUserInput } from '../../interfacesTypes'
import { resetPasswordValidationSchema } from '../../validationSchemas'
import {
    AuthContainer,
    CompanyAuthLogo,
    FormContainer,
    LoginFormGroup,
    LoginFormInput,
} from '../login/login.style'
import { BLACK, BLACK_EIGHT, GREY_SEVENTEEN, WHITE } from '../../theme'
import { useHistory } from 'react-router'
import { FormButton } from '../common/button'
import Alert from '../common/Alert'
import AgencyAuth from '../../apis/agency/auth'
import AgentAuth from '../../apis/agent/auth'
import backButtonIcon from '../../assets/images/back-button-icon.svg'
import paynestCollectLogo from '../../assets/images/paynest-collect-login-logo.png'
import backgroundImage from '../../assets/images/bg-image.png'

const debug = Boolean(process.env.REACT_APP_DEBUG)

const ResetPasswordComponent = (): JSX.Element => {
    const [checked, setChecked] = useState(true)
    const [isArabic, setIsArabic] = useState(false)
    const [isEnglish, setIsEnglish] = useState(true)
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginUserInput>({
        mode: 'all',
        resolver: yupResolver(resetPasswordValidationSchema),
        defaultValues: {
            password: '',
            confirmPassword: '',
        },
    })

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
    }

    const validateInput: SubmitHandler<LoginUserInput> = (data) => {
        if (data.password === '') {
            const err = {
                status: false,
                statusMsg: 'Password cannot be empty',
            }
            if (debug) console.error(err)
            return err
        }
        if (data.confirmPassword === '') {
            const err = {
                status: false,
                statusMsg: 'Confirm password cannot be empty',
            }
            if (debug) console.error(err)
            return err
        }
        if (data.password !== data.confirmPassword) {
            const err = {
                status: false,
                statusMsg: 'Confirm password and password need to be the same',
            }
            Alert.error(err.statusMsg)
            if (debug) console.error(err)
            return err
        }
        return {
            status: true,
            statusMsg: 'Validated fields',
        }
    }
    const onSubmit: SubmitHandler<LoginUserInput> = async (data) => {
        const validate = validateInput(data) as {
            status: boolean
            statusMsg: string
        }
        if (!validate.status) {
            Alert.error(validate.statusMsg)
            return validate
        }
        const pathname = window.location.pathname

        let verifyOtpResponse

        if (pathname === RESET_PASSWORD_AGENCY_ROUTE) {
            const agencyUserId = 1
            verifyOtpResponse = await AgencyAuth.resetPassword(
                data,
                agencyUserId
            )
            if (verifyOtpResponse.status) {
                Alert.success(`Password reset successfully`)
                return history.push(AGENCY_DASHBOARD_ROUTE)
            }
        }
        if (pathname === RESET_PASSWORD_AGENT_ROUTE) {
            const agentId = 1
            verifyOtpResponse = await AgentAuth.resetPassword(data, agentId)
            if (verifyOtpResponse.status) {
                Alert.success(`Password reset successfully`)
                return history.push(AGENT_DASHBOARD_ROUTE)
            }
        }
    }
    useEffect(() => {
        console.debug('useEffect called')
    }, [])

    const {
        confirmPassword: { message: confirmPasswordError } = {},
        password: { message: passwordError } = {},
    } = errors

    const changeLanguage = (lng: string) => {
        if (lng === 'en') {
            setIsEnglish(true)
            setIsArabic(false)
        } else {
            setIsArabic(true)
            setIsEnglish(false)
        }

        i18n.changeLanguage(lng)
    }

    const handleBack = () => {
        window.location.href = '/agency/verify-otp'
    }

    return (
        <AuthContainer>
            <video
                autoPlay
                loop
                muted
                poster={backgroundImage}
                className="background-video"
                src="../../assets/video.mp4"
            />
            <FormContainer>
                <CompanyAuthLogo>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        style={{
                            backgroundColor: 'transparent',
                            maxWidth: '30px',
                            maxHeight: '30px',
                            minWidth: '30px',
                            minHeight: '30px',
                            borderRadius: '3px',
                            boxShadow: 'none',
                            position: 'absolute',
                            left: '0px',
                            bottom: '0px',
                        }}
                    >
                        <img
                            src={backButtonIcon}
                            className="backbut"
                            style={{ width: 48, cursor: 'pointer' }}
                            alt="back-button-icon"
                        />
                    </Button>
                    <img src={paynestCollectLogo} alt="paynest-collect-logo" />
                </CompanyAuthLogo>

                <LoginFormGroup>
                    <Box>
                        <Typography
                            variant="h3"
                            style={{
                                marginBottom: 10,
                                marginLeft: 8,
                                color: BLACK,
                                fontWeight: 700,
                                fontSize: '1.18vw',
                                textTransform: 'capitalize',
                            }}
                        >
                            {t('text_change_password')}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{
                                marginLeft: 8,
                                fontSize: '0.65vw',
                                marginBottom: 10,
                                fontWeight: 500,
                            }}
                        >
                            {t('text_please_enter_new_password')}
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <LoginFormInput>
                            <LoginController
                                control={control}
                                controllerName="password"
                                controllerLabel={PASSWORD_LABEL}
                                fieldType="password"
                                error={passwordError}
                            />
                        </LoginFormInput>

                        <LoginFormInput>
                            <LoginController
                                control={control}
                                controllerName="confirmPassword"
                                controllerLabel={CONFIRM_PASSWORD_LABEL}
                                fieldType="password"
                                error={confirmPasswordError}
                            />
                        </LoginFormInput>

                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <FormControlLabel
                                sx={{
                                    '& span': {
                                        fontSize: '0.65vw',
                                        color: '#74ba6b',
                                    },
                                }}
                                control={
                                    <Checkbox
                                        color="success"
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                    />
                                }
                                label={t('text_i_accept_terms_and_conditions')}
                            />
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                            }}
                        >
                            <FormButton
                                noMargin={true}
                                type="submit"
                                height={50}
                                width={'100%'}
                                borderColor={BLACK_EIGHT}
                                title={t('text_continue')}
                                style={{ fontSize: '0.9vw' }}
                                backgroundColor={BLACK_EIGHT}
                                titleColor={WHITE}
                                hoverTitleColor={WHITE}
                                hoverBackgroundColor={BLACK_EIGHT}
                                loading={false}
                                borderTopLeftRadius={15}
                                borderTopRightRadius={15}
                                borderBottomRightRadius={15}
                                borderBottomLeftRadius={15}
                                disabled={!checked}
                            />
                        </div>

                        <Box
                            display="flex"
                            justifyContent="center"
                            mt={4}
                            mb={2}
                        >
                            <Typography
                                variant="h3"
                                style={{
                                    color: isEnglish ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    borderRight: `2px solid ${BLACK}`,
                                    paddingRight: 5,
                                }}
                                onClick={() => changeLanguage('en')}
                            >
                                {t('text_english')}
                            </Typography>

                            <Typography
                                variant="h3"
                                style={{
                                    color: isArabic ? BLACK : GREY_SEVENTEEN,
                                    cursor: 'pointer',
                                    marginLeft: 5,
                                }}
                                onClick={() => changeLanguage('ar')}
                            >
                                {t('text_arabic')}
                            </Typography>
                        </Box>
                    </form>
                </LoginFormGroup>
            </FormContainer>
        </AuthContainer>
    )
}

export default ResetPasswordComponent
