// packages block
import { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
// common block
import { SearchInput } from '../../../common/search'
// constants, theme, utils and api block
import '../../style.css'

export const AgencyUserTableHeader = ({ handleSearching }: any) => {
    const { t } = useTranslation()

    const [{ search, searchError }, setData] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        {
            search: '',
            searchError: false,
        }
    )

    const handleChange = (prop: any) => (event: { target: { value: any } }) => {
        handleSearching(event.target.value)
        setData({
            [prop]: event.target.value,
            searchError: false,
        })
    }

    return (
        <div className="table-header student-popup">
            <div className="left-column">
                <div className="student_SearchBar">
                    <SearchInput
                        searchIcon={true}
                        error={searchError}
                        value={search}
                        onChange={handleChange}
                        label={'search by employee name'}
                        name="search"
                        height={45}
                    />
                </div>
            </div>
        </div>
    )
}
